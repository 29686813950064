<template>
  <div class="alignCenter">
    <v-select
      style="width: 170px;"
      variant="outlined"
      :ref="ref_name"
      :hide-details="true"
      single-line
      :items="items"
      :model-value="value"
      :rules="rules"
      density="compact"
      @update:modelValue="(val) => $emit('updated', val)"
      :style="'width: ' + width + 'px'"
    />
  </div>
</template>

<script>

export default {
  name: "TableEditField",
  props: {
    rules: { type: Array, default: null },
    ref_name: { type: String, default: null },
    value: { type: String, default: null },
    width: { type: String, default: '200' },
    items: { type: Array, default: true }
  },
};
</script>

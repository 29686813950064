<template>
  <div style="height: 100%; text-align: center; border-left: 1px solid grey; border-right: 1px solid grey;">
    <v-list-subheader v-if="!claim.DSSClaimsID" class="justify-center">Choose a claim to get started</v-list-subheader>
    <Messenger v-else :convo="claim.convo" :system_object="claim" />
  </div>
</template>

<script>
import Messenger from "@/components/Messenger/Messenger.vue";
import { mapGetters } from "vuex";

export default {
  name: "CenterPanel",
  components: {
    Messenger,
  },
  computed: {
    ...mapGetters("osclaims", ["claim"]),
  },
};
</script>

<template>
  <div style="height: 100%; text-align: center;">
    <v-list-subheader v-if="!recall.DSSRecallID" class="justify-center">Choose a patient to get started</v-list-subheader>
    <Messenger v-else :convo="recall.convo" :system_object="recall" />
  </div>
</template>

<script>
import Messenger from "@/components/Messenger/Messenger.vue";
import { mapGetters } from "vuex";

export default {
  name: "CenterPanel",
  components: {
    Messenger,
  },
  computed: {
    ...mapGetters("recall", ["recall"]),
  },
};
</script>

<template>
<v-container >
  <v-card style="right:10px;top:-60px;width:100%" class="column">
  <div style="margin: 30px; height: 80vh; overflow-y: scroll; overflow-x: hidden;width:100%">
    <v-expansion-panels  style="margin-bottom: 150px" v-model="panel">
      <v-row class="d-flex flex-row flex-wrap">
        <v-col cols="12" md="3">
      <v-expansion-panel @click="changeWidthPatientInfo" style="width:20px" class="tabs" value= "Patient_Info">
        <v-expansion-panel-title style="position:fixed;width:8%" class="tab-head">
          <v-icon style="left: -15px;" size="30px">mdi-account-details</v-icon>
        </v-expansion-panel-title>
        <v-expansion-panel-text :style={width:widthPatientInfo} class="tabs">
          <v-form  style="margin-top: 20%;" ref="entryForm" @submit.prevent="saveContact()" class="apt-slot spacing">
            <h3>{{ (checkIfCreate() ? 'Contact Info' : 'Patient Info  -  Patient #' + contact.OD_PatNum) }}</h3>
                        <div style="display: flex; margin-top: 25px;">
              <div style="flex-direction: column;width:50%">
                <v-text-field
                  ref="fname_field"
                    style="width: 100%"
                    label="First Name (Required)"
                    v-model="first_name"
                    :rules="firstNameRules"
                    variant="outlined"
                    :disabled="isDisabled"
                    dense
                ></v-text-field>
                <v-text-field
                    style="width: 200px"
                    label="Phone Number"
                    v-model="ph1"
                    :rules="ph1Rules"
                    variant="outlined"
                    dense
                    disabled="true"
                    ref="ph2_field"
                ></v-text-field>
                 
                <v-text-field
                    style="width: 200px"
                    label="Wireless Number"
                    v-model="ph3"
                    :rules="ph3Rules"
                    variant="outlined"
                    dense
                    :disabled="isDisabled"
                    ref="ph1_field"
                ></v-text-field>
                
                <v-text-field
                    style="width: 200px"
                    label="Home Number"
                    v-model="ph1"
                    :rules="ph1Rules"
                    variant="outlined"
                    dense
                    :disabled="isDisabled"
                    ref="ph2_field"
                ></v-text-field>
                <v-text-field
                    style="width: 200px"
                    label="Work Number"
                    v-model="ph2"
                    :rules="ph2Rules"
                    variant="outlined"
                    dense
                    :disabled="isDisabled"
                    ref="ph3_field"
                ></v-text-field>
               
                <v-text-field
                    style="width: 200px"
                    label="Address Line 1"
                    v-model="address_line_1"
                    variant="outlined"
                    dense
                    :disabled="isDisabled"
                ></v-text-field>
                <v-text-field
                    style="width: 200px"
                    label="City"
                    v-model="city"
                    variant="outlined"
                    dense
                    :disabled="isDisabled"
                    ref="city_field"
                ></v-text-field>
                <v-text-field
                    style="width: 200px"
                    label="Zip Code"
                    v-model="zip"
                    :rules="zipRules"
                    variant="outlined"
                    dense
                    :disabled="isDisabled"
                ></v-text-field>
                <v-checkbox
                    v-model="primary_contact"
                    label="Primary Contact"
                    style="margin-top: -5px"
                    :disabled="isDisabled"
                ></v-checkbox>
               
              </div>
              <div style="flex-direction: column; margin-left: 50px;width:50%" >
                <v-text-field
                    style="width: 100%"
                    label="Last Name (Required)"
                    v-model="last_name"
                    :rules="lastNameRules"
                    variant="outlined"
                    dense
                    :disabled="isDisabled"
                    ref="lname_field"
                ></v-text-field>
                <v-checkbox
                    v-model="textable1"
                    label="Is Textable"
                    :disabled="isDisabled"
                ></v-checkbox>
                
                <v-checkbox
                    v-model="textable2"
                    label="Is Textable"
                    :disabled="isDisabled"
                ></v-checkbox>
                <v-checkbox
                    v-model="textable3"
                    label="Is Textable"
                    :disabled="isDisabled"
                ></v-checkbox> 
                
                <v-text-field
                    style="width: 200px"
                    label="Address Line 2"
                    v-model="address_line_2"
                    variant="outlined"
                    :disabled="isDisabled"
                    dense
                ></v-text-field>
             
                <v-select
                    style="width: 200px"
                    label="State"
                    :items="states"
                    v-model="state"
                    variant="outlined"
                    :disabled="isDisabled"
                    dense
                ></v-select>
                <v-text-field
                    style="width: 200px"
                    label="Email Address"
                    v-model="email"
                    :rules="emailRules"
                    variant="outlined"
                    dense
                    ref="email_field"
                    :disabled="isDisabled"
                ></v-text-field>
               
              </div>
            </div>
            
            <h3 style="margin-top: 20px">Personal Info</h3>
            <div style="display: flex; margin-top: 25px;">
              <div style="flex-direction: column">
                <label>
                  Date of Birth
                  <div class="justify-center" style="text-align: center; margin-bottom: 30px">
                    <input style="width: 200px" type="date" v-model="birth_date"  :disabled="isDisabled">
                  </div>
                </label>
                <v-select
                    style="width: 200px"
                    label="Language"
                    :items="language_list"
                    v-model="language"
                    variant="outlined"
                    :disabled="isDisabled"
                    dense
                ></v-select>
              </div>
              <div style="flex-direction: column; margin-left: 40px;">
                <v-select
                    style="width: 200px"
                    label="Marital Status"
                    :items="['Single', 'Married', 'Child', 'Widowed', 'Divorced']"
                    v-model="martial_status"
                    variant="outlined"
                    :disabled="isDisabled"
                    dense
                ></v-select>
                <v-select
                    style="width: 200px"
                    label="Gender"
                    :items="['Female', 'Male', 'Unknown']"
                    v-model="gender"
                    variant="outlined"
                    :disabled="isDisabled"
                     dense
                ></v-select>
                <v-select
                    style="width: 200px; margin-top: 20px"
                    label="Preferred Contact Method"
                    :items="['None', 'Do Not Call', 'Home Phone', 'Work Phone', 'Wireless Phone', 'Email', 'Mail', 'Text Message', 'See Notes']"
                    v-model="preffered_contact"
                    variant="outlined"
                    :disabled="isDisabled"
                    dense
                ></v-select>
              </div>
            </div>
           
            <div style="display: flex; margin-top: 35px; justify-content: space-around" v-if="!checkContactID()">
              <v-btn style="width: 150px;" color="primary" @click="contact = null">Clear Contact</v-btn>
              <v-btn style="width: 80px" color="primary" @click="setPatientFields()">Clear</v-btn>
              <v-btn style="width: 80px" color="primary" type="submit">{{ (!this.contact || this.contact.phone_num ? "Create" : "Save") }}</v-btn>
            </div>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
        </v-col>
      <!-- v-col cols='12' md="3">
        <v-expansion-panel @click="changeWidthUpload" style="width: 20px;" class="tabs" value="Upload_Images_to_OpenDental">
        <v-expansion-panel-title style="position:fixed;width:8%" class="tab-head">
          <v-icon style="left: -15px;" size="30px">mdi-cloud-upload</v-icon>
        </v-expansion-panel-title>
        <v-expansion-panel-text :style={width:widthUpload} style="translate:-25%" class="tabs">
          <div style="margin-top:20%">
            <div v-if="!checkContactID()">
            Contact is not an Open Dental patient.
          </div>
          <div v-else-if="mediaList.length == 0">
            No Media to Upload for contact.
          </div>
          <div v-else>
            <v-data-table
              :headers="upload_headers"
              :items="mediaList"
              :footer-props="{
              'items-per-page-options': [5, 10, 15, 20]
              }"
              style="background-color: '#ccc"
            >
              <template v-slot:[`item.text_message_id`]="{ item }">
                <v-img
                  max-height="150"
                  max-width="250"
                  :src="getFileURL(item.text_message_id, item.media_id)"
                />
              </template>
              <template v-slot:[`item.uploaded`]="{ item }">
                <v-progress-circular
                  v-if="item.loading"
                  indeterminate
                  style="margin-left: 5px"
                  color="#2196f3"
                ></v-progress-circular>
                <v-btn
                  v-else-if="item.uploaded == 0"
                  rounded
                  class="tab-head"
                  depressed
                  @click="uploadFile(item)"
                  color="#2196f3"
                >
                  Upload
                </v-btn>
                <v-btn
                  v-else
                  disabled
                  rounded
                  depressed
                >
                  Uploaded!
                </v-btn>
              </template>
            </v-data-table>
          </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      </v-col -->
      <v-col cols="12" md="3">
        <v-expansion-panel style="width: 20px;" class="tabs" value="Internal_Conversation">
        <v-expansion-panel-title style="position:fixed;width:8%" @click="changeWidthInternal" class="tab-head">
          <v-icon style="left: -15px;" size="30px">mdi-chat</v-icon>
        </v-expansion-panel-title>
        <v-expansion-panel-text :style={width:widthInternalConvo} style="translate:-40%" class="tabs">
          <div style="margin-top:20%">
            <div v-if="contact && contact.contact_id">
            <div v-if="internalConvos.length === 0 && convoListType === 'all' && selectedConvo == null">
              No Internal Convos Found.
            </div>
            <div v-else-if="internalConvos.length === 0 && selectedConvo == null">
              <p>No Internal Convos Found for that.</p>
              <div style="margin-top: 20px" class="displayCenter">
                <v-btn outlined @click="goBackToAll">
                  Go back to all convos
                </v-btn>
              </div>
            </div>
            <div v-else-if="selectedConvo == null">
              <v-hover v-slot="{ hover }" v-for="convo in internalConvos" :key="convo.convo_id">
                <div @mouseover="highlightConvoComm(convo)" @mouseout="endHighlight(convo)" @click="openConvo(convo)" style="display:flex;margin-bottom: 10px; border-radius: 5px" :style="hover ? 'background-color: rgba(74, 144, 226, 0.3)' : ''">
                  <v-tooltip v-if="convo.userMentioned > 0 && !convo.userReminder" location="top" text="You have an uncompleted task in this conversation with no reminder">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" dense color="primary">
                        mdi-rhombus-medium
                      </v-icon>
                    </template>
                  </v-tooltip>
                  <div style="padding: 5px" v-if="convo.unread">
                    <span class="text-h6" ><strong>{{ convo.convo_name }}</strong></span>
                  </div>
                  <div style="padding: 5px;" v-else>
                    <span class="text-h6" >{{ convo.convo_name }}</span>
                  </div>
                  <v-tooltip v-if="convo.userReminder" location="right" :text="moment(convo.userReminder.reminder_date).format('h:mmA M/D/YYYY')">
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" style="font-size: .7rem;">
                        {{ fromNow(convo.userReminder.reminder_date) }}
                        <v-icon dense>mdi-alarm</v-icon>
                      </div>
                    </template>
                  </v-tooltip>
                </div>
              </v-hover>
              <div v-if="convoListType !== 'all'" style="margin-top: 20px" class="displayCenter">
                <v-btn outlined @click="goBackToAll()">Go back to all convos</v-btn>
              </div>
            </div>
            <div v-else>
              <div style="display: flex">
                <div>
                  <v-icon @click="closeConvo()">mdi-arrow-left</v-icon>
                </div>
                <div style="width: 90%" class="displayCenter">
                  <div v-if="selectedConvo.comm_participant" class="chat-bubble outbound">
                    <div v-if="selectedConvo.comm_participant.call">
                      <div v-if="selectedConvo.comm_participant.call.call_missed === 1" style="color: red;">
                        <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph1">
                          mdi-cellphone
                        </v-icon>
                        <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph2">
                          mdi-home
                        </v-icon>
                        <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph3">
                          mdi-briefcase 
                        </v-icon>
                        <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.phone_num">
                          mdi-phone 
                        </v-icon>
                        {{ "missed call" }}
                      </div>
                      <div v-if="selectedConvo.comm_participant.call.call_missed === 2" style="color: green;">
                        <v-icon size="20">
                          mdi-phone
                        </v-icon>
                        {{ "Call is ringing...." }}
                      </div>
                      <div v-if="selectedConvo.comm_participant.call.call_missed === 3" style="color: green;">
                        <v-icon size="20">
                          mdi-phone 
                        </v-icon>
                        {{ "On going call" }}
                      </div>
                      <div v-if="selectedConvo.comm_participant.call.comm_ref">
                        <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph1">
                          mdi-cellphone
                        </v-icon>
                        <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph2">
                          mdi-home
                        </v-icon>
                        <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph3">
                          mdi-briefcase 
                        </v-icon>
                        <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.phone_num">
                          mdi-phone 
                        </v-icon>
                        <audio :src="recording(selectedConvo.comm_participant.call.call_uuid)" controls/>
                      </div>
                    </div>
                    <div v-if="selectedConvo.comm_participant.text_message" @click="convoFocus" >                
                      <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph1">
                        mdi-cellphone
                      </v-icon>
                      <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph2">
                        mdi-home
                      </v-icon>
                      <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.ph3">
                        mdi-briefcase 
                      </v-icon>
                      <v-icon size="20" v-if="selectedConvo.comm_participant.phone_num == contact.phone_num">
                        mdi-phone 
                      </v-icon>
                      <v-icon small v-if="selectedConvo.comm_participant.text_message.direction != 'failed' && selectedConvo.comm_participant.text_message.direction != 'undelivered' && selectedConvo.comm_participant.text_message.direction != 'rejected'" color="green"> mdi-android-messages</v-icon>
                      {{ selectedConvo.comm_participant.text_message.message }}
                    </div>
                    <div class="time">
                      {{ simplifyDate(selectedConvo.comm_participant.call ? selectedConvo.comm_participant.call.date : selectedConvo.comm_participant.text_message.date , "LT") }}
                    </div>
                  </div>
                  <ContactPopup v-else :contact_id="contact.contact_id" :tooltip="false" />
                </div>
                <div class="displayCenter">
                  <div
                    v-if="selectedConvo.convo_participants && selectedConvo.convo_participants.length"
                    style="grid-area: 1/1/1/1; justify-self: end;"
                  >
                    <div
                      style="display: flex; flex-direction: row-reverse; flex-wrap: nowrap; justify-content: end; align-items: center;"
                    >
                      <div
                        v-for="user in selectedConvo.convo_participants"
                        :key="user.user_id"
                        style="flex: 1 1 1; margin-left: -8px;"
                      >
                        <UserPopup :user_id="user.user_id" :badge="getReminder(user.user_id) ? true : false">
                          <template v-slot:badge>
                            <v-tooltip location="top" :text="moment(getReminder(user.user_id).reminder_date).format('h:mmA M/D/YYYY')">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props">mdi-alarm</v-icon>
                              </template>
                            </v-tooltip>
                          </template>
                        </UserPopup>
                      </div>
                    </div>
                    <div class="text-caption" style="color: #757575; display: flex; align-items:center; justify-content: center;">
                    </div>
                  </div>
                  <!-- <v-tooltip location="top" text="Delete this conversation">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" @click="deleteInternalConvo" class="ml-2">mdi-delete-forever</v-icon>
                    </template>
                  </v-tooltip> -->
                </div>
              </div>
              <v-divider style="margin-top: 10px"/>
              <DynamicScroller
                :items="internalMessages"
                :minItemSize="internalMessages.length"
                key-field="convo_message_id"
                style="height: 100%; margin-top: 20px"
                :buffer="20000"
                ref="scroller"
              >
                <template v-slot="{ item, index, active}">
                  <DynamicScrollerItem :item="item" :active="active">
                    <Message :index="index" :prev="index === 0 ? null : internalMessages[index-1]" :active="active" :message="item" />
                  </DynamicScrollerItem>
                </template>
              </DynamicScroller>
              <v-divider style="margin-top: 30px; margin-bottom: 5px"/>
              <ConvoBox 
                :convo="selectedConvo"
                style="margin-bottom: 25px"
                @internalMessageSent="internalMessageSent($event)"
                @closeConvo="closeConvo()"
                @update="$forceUpdate()"
              />
            </div>
          </div>
          <div v-else>
            Select a Contact to view internal conversations!
          </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel> 
      </v-col>
      <v-col cols="12" md="3">
        <v-expansion-panel @click="changeWidthOD" style="width: 20px;" v-if="checkContactID()" class="tabs" value="Appointment_Info">
        <v-expansion-panel-title style="position:fixed;width:8%" class="tab-head">
          <v-icon style="left: -15px;" size="30px">mdi-calendar-today</v-icon>
        </v-expansion-panel-title>
        <v-expansion-panel-text :style={width:widthOD} style="translate:-60%" class="tabs">
          <div style="margin-top:20%">
            <div v-if="!checkContactID()">
            Contact is not an Open Dental patient.
          </div>
          <div v-else>
            <h3 style="margin: 10px">Appointments</h3>
            <v-data-table
              v-if="appointmentsList.length > 0"
              show-expand
              :headers="appt_headers"
              :items="appointmentsList"
              :internalItems="appointmentsList"
             @update:expanded="expanded_apts = $event"
              item-key="AptNum"
              :footer-props="{
              'items-per-page-options': [5, 10, 15, 20]
              }"
              style="background-color: '#ccc"
            >
              <template v-slot:[`item.date`]="{ item }">
                {{ item.AptDateTime.split(' ')[0] }}
              </template>
              <template v-slot:[`item.day`]="{ item }">
                {{ days[dateObj.getDay(item.AptDateTime.split(' ')[0])] }}
              </template>
              <template v-slot:[`item.time`]="{ item }">
                {{ displayTime(item.AptDateTime) }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length - 2">
                  Notes: {{ item.Note }}
                </td>
                <td colspan="2">
                  Procedures: {{ item.ProcDescript }}
                </td>
              </template>
              <template v-slot:expanded-row="{  item }">
               <tr>
                  <td :colspan="appt_headers.length - 2">Notes: {{ item.Note }}</td>
                  <td colspan="2">Procedures: {{ item.ProcDescript }}</td>
                </tr>
              </template>
            </v-data-table>
            <p v-else>No Appointments to Show!</p>
            <!--
            <h3 style="margin: 10px">Make Appointment</h3>
            <v-select
              style="width: 200px; margin-top: 15px"
              label="Clinics"
              :items="clinics"
              v-model="clinic"
              outlined
              dense
            />
            <v-row class="fill-height">
              <v-col>
                <v-sheet height="64">
                  <v-toolbar
                    flat
                  >
                    <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="prev"
                    >
                      <v-icon small>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="next"
                    >
                      <v-icon small>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                      {{ today.toLocaleDateString('en-US', {year: 'numeric', month: 'long'}) }}
                    </v-toolbar-title>
                  </v-toolbar>
                </v-sheet>
                <v-sheet>
                -->
                  <!--v-date-picker
                    ref="calendar"
                    v-model="cal_focus"
                    color="primary"
                    type="month"
                    @change="cal_changed"
                    style="margin-bottom: 10px"
                  >
                    <template v-slot:day-label="{ date, day }">
                      <p @click="viewDay(date, day)" :style="free_dates.has(day) ? 'color: #2196f3' : 'color: #cccccc'">{{day}}</p>
                    </template >
                  </v-date-picker>
                  <div v-if="freeSlotsDay.length > 0 && view_slots" class="displayCenter spacing" style="align-items: center">
                    <div class="column" style="text-align: center">
                      <h3>{{ selectedDate.toLocaleDateString('en-US',  {year: 'numeric', month: 'long', weekday: 'long', day: 'numeric'}) }}</h3>
                      <div class="spacing" style="display: flex;" v-for="(chunk, index) in daySlotsChunked" :key="index">
                        <v-btn outlined large :style="selectedSlot == chunk[0] ? 'background-color: rgba(74, 144, 226, 0.3)' : ''" @click="selectedSlot = chunk[0]" color="primary">
                          {{chunk[0].DateTimeStart.split(' ')[1].substring(0, 5)}}
                        </v-btn>
                        <v-btn v-if="chunk.length > 1" class="apt-slot" :style="selectedSlot == chunk[1] ? 'background-color: rgba(74, 144, 226, 0.3)' : ''" outlined large @click="selectedSlot = chunk[1]" color="primary">
                          {{chunk[1].DateTimeStart.split(' ')[1].substring(0, 5)}}
                        </v-btn>
                        <v-btn v-if="chunk.length > 2" class="apt-slot" :style="selectedSlot == chunk[2] ? 'background-color: rgba(74, 144, 226, 0.3)' : ''" outlined large @click="selectedSlot = chunk[2]" color="primary">
                          {{chunk[2].DateTimeStart.split(' ')[1].substring(0, 5)}}
                        </v-btn>
                      </div>
                      <v-btn
                        rounded
                        class="tab-head"
                        depressed
                        color="#2196f3"
                        style="margin-top: 20px"
                        @click="bookApt"
                      >
                        Book
                      </v-btn>              
                    </div>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
         -->
          </div>   
          </div>     
        </v-expansion-panel-text>
      </v-expansion-panel>
      </v-col>
      </v-row>
    </v-expansion-panels>

  </div>
</v-card>
</v-container>

</template>

<script>
import ContactPopup from "@/components/Avatar/ContactPopup";
import UserPopup from "@/components/Avatar/UserPopup";
import Message from "@/components/Messenger/Message";
import ConvoBox from "@/components/FrontDesk/RightPanel/ConvoBox";
import EventBus from "@/eventBus";
import OdService from "@/services/odService";
import ConvoService from "@/services/convoService";
import MiscService from "@/services/miscService";
import momentMixin from "@/mixins/momentMixin";
import { phoneFormat, states, displayTime } from "@/util/util";
import { languages } from "@/util/languages";
import { mapGetters, mapActions,mapState,mapMutations } from "vuex";
import lodash from 'lodash';
import { differenceInHours, parseISO } from 'date-fns';


export default {
  name: "RightPanel",
  mixins: [ momentMixin ],
  components: {
    Message,
    ConvoBox,
    ContactPopup,
    UserPopup,
  },
  data() {
    return {
      widthPatientInfo:"20px",
      widthUpload:"20px",
      widthInternalConvo:'20px',
      widthOD:'20px',
      convoOpened:false,
      chatRef:[],
      panel: [],
      today: new Date(),
      selectedDate: new Date(),
      selectedSlot: null,
      contact: {
        contact_id: null,
        OD_PatNum: null,
      },
      internalConvos: [],
      selectedConvo: null,
      convoListType: 'all', // values 'all', 'contact', 'comm'
      internalMessages: [],
      convoHighlightTimeout: null,
      mediaList: [],
      appointmentsList: [],
      freeSlotsList: [],
      freeSlotsMonth: [],
      freeSlotsDay: [],
      free_dates: new Set(),
      cal_focus: null,
      cal_has_day: false,
      expanded_apts: [],
      clinic: "Dr. Deepa's",
      clinics: ["Dr. Deepa's"],
      view_slots: false,
      highlighted_slot: null,
      od_contact: null,
      first_name: "",
      last_name: "",
      gender: "",
      ph1: "",
      ph2: "",
      ph3: "",
      textable1: false,
      textable2: false,
      textable3: false,
      address_line_1: "",
      address_line_2: "",
      city: "",
      zip: "",
      state: "",
      states,
      email: "",
      primary_contact: false,
      date_menu: false,
      birth_date: "",
      martial_status: "",
      preffered_contact: "",
      language: "",
      languages,
      language_list: [],
      contact_json: {'None': 'None', 'Do Not Call': 'DoNotCall', 'Home Phone': 'HmPhone', 'Work Phone': 'WkPhone', 'Wireless Phone': 'WirelessPh', 'Email': 'Email', 'Mail': 'Mail', 'Text Message': 'TextMessage', 'See Notes': 'SeeNotes'},
      algorithm: 'aes-256-ctr',
      secretKey: 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3',
      upload_headers: [
        { text: "Image", value: "text_message_id" },
        { text: "Upload", value: "uploaded" },
      ],
      appt_headers: [
        { text: "Date", value: "date" },
        { text: "Day", value: "day"},
        { text: "Time", value: "time" },
        { text: "Provider", value: "provAbbr" },
      ],
      days: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
      firstNameRules: [],
      lastNameRules: [],
      cityRules: [],
      zipRules: [],
      ph1Rules: [],
      ph2Rules: [],
      ph3Rules: [],
      emailRules: [],
      rules: {
        required: (value) => value != "" || "Required.",
        phone: (value) => {
            const pattern = /^\d{10}$/;
            return pattern.test(value) || "Invalid phone number.";
        },
        city: (value) => {
            const pattern = /^[a-zA-Z ]+$/;
            return pattern.test(value) || "Invalid city name.";
        },
        zipCode: (value) => {
            const pattern = /^\d{5}$/;
            return pattern.test(value) || "Must be a 5 digit zip code";
        },
        email: (value) => {
          const pattern = /^.+@\w+\.\w{2,4}$/;
          return pattern.test(value) || "Invalid email.";
        },
      },
      dateObj: new Date(),
      isHovered: false,
    };
  },

  computed: {
    ...mapState("frontdesk", ["allCalls"]),
    ...mapGetters("frontdesk", ["getAllCalls","all_calls"]),
    ...mapState(['isPanelVisible','contactState']),
    ...mapGetters("convos", ["contact_internal_convo", "internal_convos_on_contact", "internal_convos_on_comm"]),
    daySlotsChunked() {
      return lodash.chunk(Object.values(this.freeSlotsDay), 3);
    },
    isDisabled(){
      return (this.contact && this.contact.OD_PatNum);
    },
    widthInternalConvo() {
      return this.isPanelVisible ? "400px" : "20px";
    }
  },

  created() {
    this.contact=this.contactState;
    EventBus.$on("convoRef",(reference)=>{
      this.chatRef=reference[1]   
    }),
    EventBus.$on("createConvo", (message) => {
      this.panel = ['Internal_Conversation']
      if(message) {
        this.panel = ['Internal_Conversation']
        if(message.text_message_id) {
          message.text_message = { date: message.date, direction: message.comm_direction, message: message.message, text_message_id: message.text_message_id };
        } else {
          message.call = { date: message.date, call_uuid: message.call_uuid, call_missed: message.call_missed, comm_ref: message.comm_ref };
        }
        this.selectedConvo = { comm_participant: message };
      } else {
        this.selectedConvo = { contact_id: this.contact.contact_id }
      }
      this.selectedConvo.temporary = true;
      this.internalMessages = [];
      this.endHighlight();
    });
    EventBus.$on("showContactConvos", () => {
      this.panel = ['Internal_Conversation']
      this.selectedConvo = null;
      this.internalConvos = this.internal_convos_on_contact(this.contact.contact_id);
      this.convoListType = 'contact';
    });
    EventBus.$on("showCommConvos", (item) => {
      //console.log(item);
      this.panel = ['Internal_Conversation']
      this.selectedConvo = null;
      this.internalConvos = this.internal_convos_on_comm(item.comm_participant_id);
      this.convoListType = 'comm';
    });
    EventBus.$on("updateInternalConvos", () => {
      this.internalConvos = this.contact_internal_convo(this.contact.contact_id);
      this.convoListType = 'all';
    });
    EventBus.$on("populateRightPanel", async (_contact,convos) => {
      this.panel = ['']
      this.contact = _contact;
      this.internalConvos=convos;
      this.od_contact = null;
      if(!this.checkIfCreate())
        this.od_contact = await OdService.getContact(_contact.contact_id);
    });
    EventBus.$on("populateRightPanel1", () => {
      this.panel = ['Internal_Conversation']
    });
    EventBus.$on("new_comm", async () => {
      if(this.checkContactID()) {
        let media_list = await MiscService.getMessageMediaForContact(this.contact.contact_id);
        this.mediaList = media_list.data;

        let apt_list = await OdService.getContactAppointments(this.contact.contact_id);
        this.appointmentsList = apt_list.data;
      }

      this.internalConvos = this.contact_internal_convo(this.contact.contact_id);
    });

    this.language_list = this.languages.map(a => a.name);
  },

  async mounted () {
      if(this.$refs.calendar)
        this.$refs.calendar.checkChange();
      let free_slots = await OdService.getFreeSlots();
      this.freeSlotsList = free_slots.data;
  },

  beforeUnmount() {
    EventBus.$off("createConvo");
    EventBus.$off("showContactConvosr");
    EventBus.$off("showCommConvos");
    EventBus.$off("updateInternalConvos");
    EventBus.$off("populateRightPanel");
    EventBus.$off("new_comm");
  },

  watch: {
    async contact() {
      console.log(this.contact)
      this.convoListType = 'all';
      this.setPatientFields();
      this.internalConvos = this.contact_internal_convo(this.contact.contact_id);
      if(this.checkContactID()) {
        let media_list = await MiscService.getMessageMediaForContact(this.contact.contact_id);
        this.mediaList = media_list.data;

        let apt_list = await OdService.getContactAppointments(this.contact.contact_id);
        this.appointmentsList = apt_list.data;
      }
    },
  },

  methods: {
    ...mapMutations("convos", ["setInternalConvos"]),
    changeWidthPatientInfo(){
      this.widthPatientInfo="400px"
    },
    changeWidthUpload(){
      this.widthUpload="400px"
    },
    changeWidthInternal(){
      this.widthInternalConvo="400px"
      this.convoOpened=!this.convoOpened
      if(this.convoOpened){
        this.selectedConvo = null;
        this.internalMessages = [];
      }
    },
    changeWidthOD(){
      this.widthOD="400px"
    },
    convoFocus(){
      if(this.chatRef.length!=0){
        this.chatRef.scrollIntoView({ behavior: 'smooth' , block: 'center' });
        this.chatRef.style.backgroundColor = "yellow"
        setTimeout(()=>{
          this.chatRef.style.backgroundColor = "#e8e4e4"
        },1000)
      }
    },
    ...mapActions("convos", ["getInternalMessages", "markInternalAsRead"]), // maybe change how we get convos, like make it an action/retrieve
    async openConvo(convo) {
      this.endHighlight();
      this.selectedConvo = convo;
      this.selectedConvo.comm_participant = convo.comm_participant;
      this.internalMessages = await this.getInternalMessages(convo.convo_id);
      console.log("internalMessages",this.internalMessages)
      this.markInternalAsRead(convo.convo_id);
    },
    closeConvo() {
      this.selectedConvo = null;
      this.internalMessages = [];
    },
    deleteInternalConvo() {
      if(this.selectedConvo.temporary) {
        this.closeConvo();
      } else {
        if(confirm("Are you sure you want to delete this conversation? This will effect all other users.")) {
          ConvoService.deleteInternalConvo(this.selectedConvo.convo_id)
          .then(() => {
            this.closeConvo();
          });
        }
      }
    },
    getReminder(participant_id) {
      if (!this.selectedConvo || !this.selectedConvo.convo_reminders.length) return false;
      let r = this.selectedConvo.convo_reminders.find((r) => r.reminder_for_who === participant_id);
      return r ? r : false;
    },
    goBackToAll() {
      this.internalConvos = this.contact_internal_convo(this.contact.contact_id);
      this.convoListType = 'all';
    },
    async internalMessageSent(message) {
      let comm = this.selectedConvo.comm_participant ? this.selectedConvo.comm_participant.comm_participant_id : null;
      EventBus.$emit("submitConvo", message, comm);
      setTimeout(async () => {
        console.log("called123")
        let convos = await ConvoService.getClientInternalConvos(this.contact.contact_id);
        convos = convos.data;
        const conversationHash = this.allCalls
      convos.forEach((convo) => {
        let remainingHrs;
        if (convo.userReminder) {
          const givenDate = parseISO(convo.userReminder.reminder_date);
          const now = new Date();
          remainingHrs = differenceInHours(givenDate, now);
          convo.remaining_hr = remainingHrs;
        }
        else {
          convo.remaining_hr = null;
        }
        let conversation_id = convo.convo_references[0].conversation_id;
        conversationHash.forEach((convoHash) => {
          if (convoHash.comm_participant_id == conversation_id) {
            let jsonObject = Object.assign({}, convo.convo_references[0].conversation, convoHash);
            convo.comm_participant = jsonObject;
            if (jsonObject.call_id) {
              convo.comm_participant.call = {}
              convo.comm_participant.call.call_missed = jsonObject.call_missed
              convo.comm_participant.call.call_uuid = jsonObject.call_uuid
              convo.comm_participant.call.comm_ref = jsonObject.comm_ref
              convo.comm_participant.call.date = convo.date_created
            }
            else {
              convo.comm_participant.text_message = {}
              convo.comm_participant.text_message.direction = jsonObject.comm_direction
              convo.comm_participant.text_message.message = jsonObject.message
              convo.comm_participant.text_message.date = convo.date_created
            }

          }
        })
      })
        this.internalConvos = convos
        let convoId = convos[convos.length-1].convo_id;
        this.internalMessages = await this.getInternalMessages(convoId);
        this.selectedConvo.temporary = false;
        this.selectedConvo.convo_id = convos[convos.length-1].convo_id
      }, 500);
      ;
      // this.closeConvo();
    },
    highlightConvoComm(convo) {
      if(this.convoHighlightTimeout)
        clearTimeout(this.convoHighlightTimeout);

      this.convoHighlightTimeout = setTimeout(() => {
        if(convo.convo_references[0].comm_participant_id)
          EventBus.$emit("highlightConvoComm", convo);
        else
          EventBus.$emit("highlightContact");
      }, 1500);
    },
    endHighlight() {
      if(this.convoHighlightTimeout)
        clearTimeout(this.convoHighlightTimeout);

      EventBus.$emit("clearHighlight");
    },
    recording: function(callUUID) {
      return MiscService.getCallRecordingURL(callUUID);
    },
    simplifyDate(date, format) {
      return this.moment(date).format(format);
    },
    getFileURL(text_id, media_id) {
      return MiscService.getMultimediaUrl(text_id, media_id);
    },
    checkContactID() {
      return (this.contact && this.contact.OD_PatNum);
    },
    async uploadFile(item) {
      item.loading = true;
      this.$forceUpdate();
      await OdService.addDocument(item.text_message_id, item.media_id).then(() => {
        setTimeout(() => {
          item.loading = false;
          item.uploaded = 1; 
          this.$forceUpdate();
        }, 500); 
      }).catch(() => {
        console.log("in error");
        setTimeout(() => {
          item.loading = false;
          item.uploaded = 0; 
          this.$forceUpdate();
          EventBus.$emit("alert", "File Upload Has Failed");
        }, 500); 
      });
    },
    async saveContact() {
      this.ph1 = this.checkPhoneInput(this.ph1);
      this.ph2 = this.checkPhoneInput(this.ph2);
      this.ph3 = this.checkPhoneInput(this.ph3);

      const phonePattern = /^\d{0,10}$/
      const zipPattern = /^\d{5}$/;
      const cityPattern = /^[a-zA-Z ]+$/;
      const emailPattern = /@\w+\.\w{2,4}$/;

      if(!!this.first_name && !!this.last_name && (this.ph1 == undefined  || phonePattern.test(this.ph1))  && (this.ph2 == undefined  || phonePattern.test(this.ph2))  && (this.ph3 == undefined  || phonePattern.test(this.ph3))) 
      {
        const l = this.languages.find(a => a.name == this.language);
          let lang = (l ? l.iso6392B : null);
          const data = {first_name: this.first_name, last_name: this.last_name, ph2: this.ph2, ph3: this.ph3, ph1: this.ph1, 
              email: this.email, textable1: this.textable1, textable2: this.textable2, textable3: this.textable3,Gender: this.gender, Position: this.martial_status, Birthdate: this.birth_date, SSN: '',
              Address: this.address_line_1, Address2: this.address_line_2, City: this.city, State: this.state, Zip: this.zip,  
              Email: this.email, PreferContactMethod: this.contact_json[this.preffered_contact], Language: lang
          };
          console.log("Create"+this.contact);
        if(this.checkIfCreate()) {
          await OdService.addContact(data);
        } else {
         let result = await OdService.updateContact(this.contact.contact_id, data);
         if(result.data.status == "failure"){
          EventBus.$emit("alert", "we have contacts with duplicate phone numbers");
           
         }else if(result.data.status == "success"){
          EventBus.$emit("alert", "Contacts successfully updated");
         }
        }
      } else {
            console.log("failed validation");
              this.firstNameRules = [this.rules.required];
              this.$refs.fname_field.validate();
              this.lastNameRules = [this.rules.required];
              this.$refs.lname_field.validate();
              if(this.city) {
                this.cityRules = [this.rules.city];
                this.$refs.city_field.validate();
              }
              if(this.zip) {
                this.zipRules = [this.rules.zipCode];
                this.$refs.zip_field.validate();
              }
              if(this.ph1) {
                this.ph1Rules = [this.rules.phone];
                this.$refs.ph1_field.validate();
              }
              if(this.ph2) {
                this.ph2Rules = [this.rules.phone];
                this.$refs.ph2_field.validate();
              }
              if(this.ph3) {
                this.ph3Rules = [this.rules.phone];
                this.$refs.ph3_field.validate();
              }
              if(this.email) {
                this.emailRules = [this.rules.email];
                this.$refs.email_field.validate();
              }
          }
    },
    setPatientFields() {
      console.log('top')
      this.clearFields();
      if(!this.contact)
        return;

      if(this.contact.phone_num) {
        this.ph1 = phoneFormat(this.contact.phone_num);
      } else {
        this.first_name = this.contact.first_name;
        this.last_name = this.contact.last_name;
        this.ph1 = (this.contact.ph1 == "" ? "" : phoneFormat(this.contact.ph1));
        this.ph2 = (this.contact.ph2 == "" ? "" :phoneFormat(this.contact.ph2));
        this.ph3 = (this.contact.ph3 == "" ? "" : phoneFormat(this.contact.ph3));
        this.textable1 = this.contact.textable1;
        this.textable2 = this.contact.textable2;
        this.textable3 = this.contact.textable3;
        this.email = this.contact.email;

        //if(this.od_contact)
         {
          this.address_line_1 = this.contact.Address;
          this.address_line_2 = this.contact.Address2;
          this.city = this.contact.city;
          this.state = this.contact.state;
          this.zip = this.contact.Zip;
          this.gender = this.contact.gender;
          this.birth_date = this.contact.Birthdate;
          this.martial_status = this.contact.Position;
          if(this.contact.Language!==""){
            this.language = this.languages.find(a => a.iso6392B == this.contact.Language)?.name || 'English';
          }
          for (const key of Object.keys(this.contact_json)) {
            if(this.contact_json[key] == this.contact.PreferContactMethod) {
              this.preffered_contact = key;
              break;
            }
          }
        }
      }
    },
    checkIfCreate() {
      return !this.contact.contact_id;
    },
    checkPhoneInput(phone) {
      if(!phone) return "";
      phone = phone.replace(/\D/g, '');
     // if(phone.length == 10) phone = "1" + phone;

      return phone;
    },
    showAppointment(item) {
      console.log(item);
    },
    clearFields() {
      this.first_name = "";
      this.last_name = "";
      this.gender = "";
      this.ph1 = "";
      this.ph2 = "";
      this.ph3 = "";
      this.textable1 = false;
      this.textable2 = false;
      this.textable3 = false;
      this.address_line_1 = "";
      this.address_line_2 = "";
      this.city = "";
      this.zip = "";
      this.state = "";
      this.email = "";
      this.primary_contact = false;
      this.date_menu = false;
      this.birth_date = "";
      this.martial_status = "";
      this.preffered_contact = "";
      this.language = "";
    },
    phoneFormat,
    displayTime,
    viewDay (date, day) {
      this.view_slots = true;
      this.cal_focus = date;
      this.selectedDate = new Date(date + 'T00:00:00');

      if(!this.free_dates.has(day)) {
        this.cal_has_day = false;
        this.freeSlotsDay = [];
        return;
      }

      this.freeSlotsDay = this.freeSlotsMonth.filter((slot) => {
        return slot.DateTimeStart.split(' ')[0] === date;
      });
      this.cal_has_day = true;
      console.log(this.freeSlotsDay);
    },
    prev () {
      this.$refs.calendar.prev();
      console.log(this.$refs.calendar);
    },
    next () {
      this.$refs.calendar.next();
    },
    cal_changed({start}) {
      this.free_dates = new Set();
      this.freeSlotsMonth = [];
      this.freeSlotsList.forEach((slot) => {
        let date = slot.DateTimeStart.split(' ')[0];
        let date_split = date.split('-');
        if(start.month === parseInt(date_split[1]) && start.year === parseInt(date_split[0])) {
          if(!this.free_dates.has(date_split[2]))
            this.free_dates.add(parseInt(date_split[2]));
          this.freeSlotsMonth.push(slot);
        }
      });
    },
    async bookApt() {
      if(this.selectedSlot) {
        const body = {
          Op: this.selectedSlot.OpNum,
          aptDateTime: this.selectedSlot.DateTimeStart
        };

        await OdService.addContactAppointment(this.contact.contact_id, body);

        let index = this.freeSlotsList.indexOf(this.selectedSlot);
        this.freeSlotsList.splice(index, 1);

        index = this.freeSlotsMonth.indexOf(this.selectedSlot);
        this.freeSlotsMonth.splice(index, 1);

        index = this.freeSlotsDay.indexOf(this.selectedSlot);
        this.freeSlotsDay.splice(index, 1);

        if(this.freeSlotsDay.length == 0) {
          // this.free_dates.delete(parseInt(this.selectedDate.getDate()));
          this.cal_focus = null;
        }

        this.selectedSlot = null;

        let apt_list = await OdService.getContactAppointments(this.contact.contact_id);
        this.appointmentsList = apt_list.data;
      }
    },
  },
};
</script>

<style scoped>

.tabs {
  margin-top: 15px;
  height:10px;
}

.tab-head {
  background-color: #2196f3;
  color: #ffffff;
  font-size: 1.2em;
  height:15px;
  z-index: 1;
}

.spacing {
  margin-top: 20px;
}

.apt-slot {
  margin-left: 10px;
}

.outbound {
  background-color: #e3e3e3;
  align-items: flex-end;
  align-content: flex-end;
  align-self: flex-end;
}

.chat-bubble {
  text-align: left;
  font-size: 0.9rem;
  width: auto;
  max-width: 80%;
  padding: 10px;
  border-radius: 0.5rem;
}

</style>
import MiscService from "@/services/miscService";
import moment from "moment";
import Vue from "vue";

// this is the vuex module for the Recall Workflow
export const moduleRecall = {
  namespaced: true,
  state: {
    // an array containing all the recalls
    recalls: [],
    // the current recall selected
    recall: {},
    // true if the recalls/convos are loading (they are being received from the server)
    loading: true,
  },
  getters: {
    recalls(state) {
      return state.recalls;
    },
    recall(state) {
      return state.recall;
    },
    loading(state) {
      return state.loading;
    },
    convoID(state, getters) {
      return getters.recall.convo_id || null;
    },
    convo(state, getters) {
      return getters.recall.convoTopic;
    },
    recallsWithOpenConvos(state, getters) {
      let convoPriority = (r) => (r.convo.userReminder ? 2 : r.convo.userMentioned ? 1 : 3);

      return getters.recalls
        .filter((e) => e.convo && e.convo.convo_status)
        .sort((a, b) => {
          let pA = convoPriority(a);
          let pB = convoPriority(b);
          // if p! and pB are equal and they both have reminders
          if (pA === pB && pA === 2)
            return moment(a.convo.userReminder.reminder_date).isBefore(moment(b.convo.userReminder.reminder_date))
              ? -1
              : 1;
          return pA <= pB ? -1 : 1;
        });
    },
  },
  mutations: {
    changeRecalls(state, value) {
      state.recalls = value;
    },
    changeRecall(state, value) {
      state.recall = value;
    },
    changeLoading(state, value) {
      state.loading = value;
    },
    // assigns a newly created convo to a recall in the state
    // payload.convo specifies the convo
    // payload.DSSRecallID specifies the DSSRecallID of the recall that the convo belongs to
    addConvo(state, payload) {
      // first, check if the convo belongs to the selected recall
      if (state.recall.DSSRecallID === payload.DSSRecallID) {
        state.recall["convo"] = payload.convo;
        state.recall["convo_id"] = payload.convo.convo_id;
      } else {
        // otherwise, go through all the recalls and find the one to add the convo to
        let recall = state.recalls.find((r) => r.DSSRecallID === payload.DSSRecallID);
        if (recall) {
          recall["convo"] = payload.convo;
          recall["convo_id"] = payload.convo.convo_id;
        }
      }
    },
  },
  actions: {
    async retrieveRecalls({ dispatch, commit }) {
      try {
        let res = await MiscService.getRecalls();
        let recalls = res.data;
        let convos = [];

        // populate the convos array
        recalls.forEach((r) => {
          if (r.convo) convos.push(r.convo);
        });

        // add convos to convos module
        await dispatch("convos/addNewConvos", convos, { root: true });

        // add recalls to this module
        commit("changeRecalls", recalls);
      } catch (err) {
        throw new Error(err);
      }
    },
  },
};

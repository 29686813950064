import axios from "axios";
import AuthService from "@/services/authService";
import store from "@/store";
import eventBus from "@/eventBus";

let http = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api",
  headers: {
    //"Access-Control-Allow-Origin": "*", //Temporary to avoid CORS issue when developing
    "Content-type": "application/json",
  },
  withCredentials: true,
  credentials: "include",
});

// used to track if the client has already requested for a new token so that only one request has to be made
let isAlreadyRefreshingToken = false;

// contains the queue of failed requests that will be executed once the token is refreshed
let failedQueue = [];

// executes each request in 'failedQueue'
const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) prom.reject(error);
    else prom.resolve();
  });

  failedQueue = [];
};

// intercept each failed request and try again after refreshing token
const resErrorInterceptor = (error) => {
  // save the original request
  const originalRequest = error.config;

  if (!error.response) return;

  // if the request was for a new token and it failed
  if (error.response.status === 403 && originalRequest.url.includes("/token")) {
    // log the user out
    store.dispatch("logOut");
    failedQueue = [];
    return Promise.reject(error);
  }

  // if the request has already been retried
  if (originalRequest._retry || originalRequest._queued) return Promise.reject(error);

  // if the client is in the process of refreshing the token
  if (isAlreadyRefreshingToken) {
    return new Promise(function(resolve, reject) {
      failedQueue.push({ resolve, reject });
    })
      .then(() => {
        originalRequest._queued = true;
        return http.request(originalRequest);
      })
      .catch(() => {
        // Ignore refresh token request's "err" and return actual "error" for the original request
        return Promise.reject(error);
      });
  }

  // if the error code is due to an invalid token, we must refresh it
  if (error.response.status === 403) {
    originalRequest._retry = true;
    isAlreadyRefreshingToken = true;

    return new Promise((resolve, reject) => {
      AuthService.refreshToken()
        .then(() => {
          processQueue(null);
          resolve(http.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err);
          reject(err);
        })
        .finally(() => {
          isAlreadyRefreshingToken = false;
        });
    });
  }

  if (error.response.status === 400 && error.response.data) eventBus.$emit("alert", error.response.data);

  // otherwise, reject the error normally
  return Promise.reject(error);
};

http.interceptors.response.use((res) => res, resErrorInterceptor);

export default http;

<template>
  <div>
    <b-spinner v-if="chartDataObject == null"></b-spinner>
    <div v-else class="move-down">
      <b-row>
        <b-col cols="12" align-self="end">
          <ChartTitle v-bind:title="chartParams.title" />
        </b-col>
      </b-row>
      <LineChart
        v-bind:chart-data="chartDataObject"
        v-bind:options="chartOptionsObject"
      />
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/VPI/LineChart";
import ChartTitle from "@/components/VPI/ChartTitle";
import chartInfoMixin from "@/mixins/chartInfoMixin";
import chartToolsMixin from "@/mixins/chartToolsMixin";
import VPIDataService from "@/services/VPIDataService";

export default {
  name: "AppointmentOutlookComparison",
  components: {
    LineChart,
    ChartTitle,
  },
  mixins: [chartInfoMixin, chartToolsMixin],
  data() {
    return {
      // holds the data object for the chart
      chartDataObject: null,
      // holds the options object for the chart
      chartOptionsObject: null,
      // holds the raw data that was recieved from the last request to the server
      rawData: null,
      // information used to construct the chart
      chartParams: {
        title: "Appointment Outlook Comparison",
        yLabel: "# of Appointments",
        // this is actually a data param, not an options param, but it is fine here
        xLabels: ["This Wk", "Next Wk", "2 Wks After", "3 Wks After"],
      },
      // holds the chart's categories
      categories: [
        "Current",
        "Wk Before",
        "2 Wks Before",
        "3 Wks Before",
        "4 Wks Before",
      ],
    };
  },
  computed: {
    // returns the request parameters needed for requesting the chart data from the server
    requestParams() {
      var requestParams = [];

      // add 5 request objects for appointments as of specific dates
      for (let i = 0; i < 5; i++) {
        requestParams.push({
          type: "bookingsAsOfDate",
          startDate: this.moment()
            .subtract(i, "week")
            .startOf("week")
            .toISOString(),
          endDate: this.moment()
            .add(3 - i, "week")
            .endOf("week")
            .toISOString(),
          asOfDate: this.moment().subtract(i, "week").toISOString(),
        });
      }
      return requestParams;
    },
  },
  created() {
    this.requestData();
  },
  methods: {
    requestData() {
      // create a promise for each request
      var allPromises = [];
      for (let i = 0; i < this.requestParams.length; i++)
        allPromises[i] = VPIDataService.getBookingsAsOfDate(
          this.requestParams[i].startDate,
          this.requestParams[i].endDate,
          this.requestParams[i].asOfDate
        );

      Promise.all(allPromises)
        .then((res) => {
          // save the response data
          this.rawData = res;
          for (let i = 0; i < this.rawData.length; i++) {
            this.rawData[i] = this.rawData[i].data;
          }
          // now insert the data into the chart
          this.insertData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    insertData() {
      // create the options object for the chart based on this.chartParams
      this.chartOptionsObject = this.constructOptionsSet(
        this.chartParams.yLabel
      );

      let apptTimes = [];
      // get the appointment times
      for (let i = 0; i < 5; i++) {
        apptTimes[i] = [];
        for (let j = 0; j < this.rawData[i].length; j++)
          apptTimes[i][j] = this.rawData[i][j].AptDateTime;
      }

      // convert the appointment times into the data that will be in the chart
      let chartData = [];
      for (let i = 0; i < apptTimes.length; i++) {
        chartData[i] = this.aggregateData(
          apptTimes[i],
          "week",
          {
            startDate: this.moment().subtract(i, "week").startOf("week"),
            endDate: this.moment()
              .add(3 - i, "week")
              .endOf("week"),
          },
          false,
          null
        );
      }

      // create the data object for the chart
      this.chartDataObject = this.constructData(
        this.categories,
        chartData,
        this.chartParams.xLabels,
        [false, true, true, true, true]
      );
    },
  },
};
</script>

// This mixin contains tools (methods) that are useful when displaying chart data
import moment from 'moment'

export default {
    methods: {
        // aggregates/groups the data based on the aggregateLevel (hour, day, month...)
        // in a given dateRange. If isTimeAxis is true, it returns an array of objects with
        // both a date and a value. Otherwise just an array of the values will be returned.
        // For each datapoint that lies in a specific aggregateLevel, the value for that
        // aggregateLevel will be incremented by 1, unless the increment parameter is not null.
        // In that case, the value will be incremented by increment[index] where data[index] is
        // the corresponding datapoint. 
        aggregateData(data, aggregateLevel, dateRange, isTimeAxis, increment) {
            // hash table mapping time to data points (ex: 03/24/2020 -> 13 appointments)
            var counts = new Map();
            var newData = [];
            var currentDate = moment(dateRange.startDate).startOf(aggregateLevel);
            var dataIndex = 0;

            // prevents infinite loops
            if (typeof data === 'undefined' || data.length == 0)
                return newData;

            // Helps with debugging
            if (increment != null && data.length != increment.length) {
                console.log("Error in aggregateData: the data and increment arrays are different lengths");
            }

            // go one by one through each hour, day, week... (depends on aggregateLevel)
            while (currentDate.isSameOrBefore(moment(dateRange.endDate).startOf(aggregateLevel))) {
                let count = 0;
                // count the # of data points that lie on that hour or day or week
                // data must be sorted by date for this to work
                while (moment(data[dataIndex]).startOf(aggregateLevel).isSame(currentDate)) {
                    // prevents infinite loops
                    if (dataIndex >= data.length)
                        break;
                    count += (increment == null) ? 1 : increment[dataIndex];
                    dataIndex++;
                }

                counts.set(currentDate.toISOString(), count);
                currentDate.add(1, aggregateLevel);
            }

            // put the new grouped data into newData
            counts.forEach(function (value, index) {
                if (isTimeAxis) {
                    newData.push({
                        x: moment(index),
                        y: value
                    });
                } else {
                    newData.push(value);
                }
            });
            return newData
        },
        // gets the moving average for 4 units of time (ex: unit: "day" or "week") and the normal data too
        // data parameter is in this form (same form that aggregateData returns): [{x: moment date, y: number }]
        // returns an array of length 2 where index 0 is the moving average data and index 1 is the normal data
        getMovingAverage(data, unit, startDate) {
            var movingAverageData = [];
            var normalData = [];

            // for each unit of time that will be displayed on the chart
            for (let i = 3; i < data.length; i++) {
                // get the moving average (as long as the date is in the current date range)
                if (
                    this.moment(startDate)
                        .startOf(unit)
                        .isSameOrBefore(
                            data[i].x.startOf(
                                unit
                            )
                        )) {
                    movingAverageData.push({
                        x: data[i].x,
                        y: (data[i].y + data[i - 1].y + data[i - 2].y + data[i - 3].y) / 4
                    });
                    normalData.push({
                        x: data[i].x,
                        y: data[i].y
                    }
                    );
                }

            }
            return [movingAverageData, normalData];
        },
        // gets the running total
        // data parameter is in this form: [{ x: moment date, y: number }, etc ...] (same form that aggregateData returns)
        getRunningTotal(data) {
            let newData = [];
            let count = 0;
            for (let i = 0; i < data.length; i++) {
                count += data[i].y;
                newData[i] = {
                    x: data[i].x,
                    y: count
                }
            }
            return newData;
        }
    }
}
import { createVuetify } from "vuetify";
import store from "@/store";
import 'vuetify/styles';
import * as components from 'vuetify/components';
//import * as labsComponents from 'vuetify/labs/components';
import * as directives from 'vuetify/directives';

const default_theme = {
  dark: false,
  colors: {
    primary: "#4A90E2",
    secondary: "#424242",
    accent: "#82B1FF",
    error: "#a70000",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
    white: "#ffffff",
    // ------------------------------
    // vuetify categories above
    // custom categories below
    // ------------------------------
    list: "#ffffff", // the background color of lists
    divider: "#e0e0e0", // the color of the panel borders and dividers
    text: "#202020", // the color of the text
    msg_box_self: "#d9f7dc", // the background color of the message box for messages sent
    msg_box_friend: "#f5f5f5", // the background color of the message box for messages received
    highlighted: "#e4edf8",
    highlighted_content: "#4A90E2",
    hovered: "f5f5f5",
  }
}

const vuetify = createVuetify({
  // All this theme stuff I just added!!!
  components: {
    ...components
  },
  directives,
  theme: {
    defaultTheme: "default_theme",
    options: {
      customProperties: true,
    },
    dark: store.getters.darkMode,
    variations: {
      colors: ['primary', 'secondary'],
      darken: 2,
    },
    themes: {
      default_theme,
      light: default_theme,
      dark: default_theme
    },
  },
  icons: {
    iconfont: "mdiSvg",
  },
});

export default vuetify;

<template>
  <div style="fles: 1; display: flex; justify-content: center; align-items: center; width: 250px;">
    <div style="flex: 1; text-align: center;">
      <h5>Connecting </h5>
      <v-progress-linear style="margin-left: 10%; width: 80%" indeterminate></v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConnectedDisplay",
};
</script>

<template>
  <v-card style="display: flex; flex-direction: column" height="480" width="600">
    <v-card tile color="primary" height="60" style="display: flex; align-items: center">
      <v-card-text class="ml-5 white--text" style="font-size: 21px">
        My Profile
      </v-card-text>
      <v-card-actions>
        <!-- note: we are temporarily disabling the button here until we finish creating the update user routes -->
        <v-btn v-show="!editPressed" class="mt-2 mr-2" disabled @click="editButtonPressed()">
          Edit
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card flat tile style="display: flex; flex-direction: column; align-items: center" height="370">
      <div
        class="mt-3"
        style="
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 300px;
          "
      >
        <v-avatar v-bind:color="hashedColor(username)" size="80" class="text-white">
          {{ userInitials }}
        </v-avatar>
      </div>
      <v-card
        flat
        tile
        style="
            flex: 3;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 350px;
          "
      >
        <div class="mt-5" style="height: 65x; display: flex; align-items: center">
          <v-text-field class="fieldWidth" variant="outlined" v-model="firstName" :readonly="!editPressed" density="compact" label="First Name"> </v-text-field>
          <div style="width: 30px"></div>
          <v-text-field class="fieldWidth" variant="outlined" v-model="lastName" :readonly="!editPressed" density="compact" label="Last Name"> </v-text-field>
        </div>
        <div
          style="
              height: 65px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
        >
          <v-text-field variant="outlined" v-model="email" style="width: 350px" :readonly="!editPressed" density="compact" label="Email" />
        </div>
        <div
          style="
              height: 65px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
        >
          <v-text-field class="fieldWidth" variant="outlined" v-model="username" :readonly="!editPressed" density="compact" label="Username" />
          <div style="width: 30px"></div>
          <v-text-field class="fieldWidth" variant="outlined" v-model="userRole" disabled density="compact" label="Role" />
        </div>
        <div
          style="
              height: 65px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
        >
          <v-text-field
            type="password"
            v-model="fakePassword"
            append-icon="mdi-eye-off"
            disabled
            style="width: 350px"
            density="compact"
            variant="outlined"
            label="Password"
          />
        </div>
      </v-card>
    </v-card>
    <v-card flat tile height="50" style="display: flex; justify-content: flex-end">
      <v-btn text dark v-show="editPressed" color="primary" width="100" @click="saveButtonPressed()">
        Save
      </v-btn>
      <v-btn class="mr-2" text dark v-show="editPressed" color="red" width="100" @click="cancelButtonPressed()">
        Cancel
      </v-btn>
    </v-card>
  </v-card>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import { mapGetters } from "vuex";

export default {
  name: "ProfileDialog",
  components: {},
  mixins: [colorMixin],
  data() {
    return {
      editPressed: false,
      fakePassword: "ejfioaejf",
    };
  },
  computed: {
    ...mapGetters(["userInitials", "firstName", "lastName", "email", "username", "userRole"]),
  },
  methods: {
    editButtonPressed() {
      this.editPressed = true;
      this.$emit("editStatus", true);
    },
    cancelButtonPressed() {
      this.editPressed = false;
      this.$emit("editStatus", false);
    },
    saveButtonPressed() {
      this.editPressed = false;
      this.$emit("editStatus", false);
    },
  },
};
</script>

<style scoped>
.fieldWidth {
  width: 150px;
}

/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
  outline: none;
}

/* Optional: Customize .focus-visible */
.focus-visible {
  outline-color: #2196f3;
}
</style>
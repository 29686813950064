import http from "../http-common";
import axios from "axios";


class ConvoService {
  getConvos = (type = "") => (type ? http.get(`/convos?type=${type}`) : http.get("/convos"));

  getTodayConvos = ()=>{http.get(`/convos/today`)};

  getConvo = (convo_id) => http.get(`/convos/${convo_id}`);

  addConvo = (data) => http.post("/convos", data);

  addMessage = (convo_id, message) => {axios.post(process.env.VUE_APP_API_URL +`/api/convos/${convo_id}/messages`, {message});}

  addGeneralMessage = (convo_id, formData) => {axios.post(process.env.VUE_APP_API_URL +`/api/convos/${convo_id}/generalmessage`,formData);}

  getMessages = (convo_id) => http.get(`/convos/${convo_id}/messages`);

  updateConvoStatus = (convo_id, status) => http.put(`/convos/${convo_id}/status`, { status });

  addReminder = (convo_id, date) => http.post(`/convos/${convo_id}/reminders`, { date });

  updateReminder = (convo_id, date) => http.put(`/convos/${convo_id}/reminders`, { date });

  deleteReminder = (convo_id) => http.delete(`/convos/${convo_id}/reminders`);

  getParticipants = (convo_id) => http.get(`/convos/${convo_id}/participants`);

  addParticipant = (convo_id) => http.post(`/convos/${convo_id}/participants`);

  updateLastReadTime = (convo_id) => http.put(`/convos/${convo_id}/participants`);

  deleteParticipant = (convo_id) => http.delete(`/convos/${convo_id}/participants`);

  updateMentionStatus = (convo_id, message_id, params) =>
    http.put(`/convos/${convo_id}/messages/${message_id}/mentions`, params);

  // INTERNAL CONVOS
  getClientInternalConvos = (contact_id) => http.get(`convos/internal/all/${contact_id}`);

  getClientInternalConvosToday = () => http.get(`convos/internal/allToday`);

  getClientInternalConvosLater = () => http.get(`convos/internal/allLater`);

  createInternalConvo = (data) => http.post("convos/internal", data);
  
  deleteInternalConvo = (convo_id) => http.delete(`/convos/internal/${convo_id}`);

  getInternalConvoMessages = (convo_id) => http.get(`convos/internal/messages/${convo_id}`);

  addInternalMessage = (convo_id, message) => axios.post(process.env.VUE_APP_API_URL +`/api/convos/internal/${convo_id}/messages`, message);
  
  getConvoMediaUrl = (convo_message_id, media_id) => http.defaults.baseURL + `/convos/internal/messages/${convo_message_id}/media/${media_id}`;

  updateInternalLastReadTime = (convo_id) => http.put(`/convos/internal/${convo_id}/participants`);

  updateInternalMentionStatus = (convo_id, convo_message_id, params) =>
    http.put(`/convos/internal/${convo_id}/messages/${convo_message_id}/mentions`, params);

  deleteInternalConvoReminder = (convo_id) => http.delete(`/convos/internal/${convo_id}/reminder`);

  addInternalReminder = (data) => http.post("/convos/internal/reminder", data);

  updateInternalReminder = (data) => http.put("/convos/internal/reminder", data);
}

export default new ConvoService();

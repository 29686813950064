<template>
  <v-card>
    <div>this is the patient profile</div>
    <div>this is the patient profile</div>
    <div>this is the patient profile</div>
    <div>this is the patient profile</div>
  </v-card>
</template>

<script>
export default {
  name: "PatientProfile",
};
</script>

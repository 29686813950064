import MiscService from "@/services/miscService";

// this is the vuex module for the Front Desk Workflow
export const moduleFrontDesk = {
  namespaced: true,
  state: {
    // an array containing all the calls
    calls: [],
    // the current call selected
    call: {},
    
    // true if the calls are loading (they are being received from the server)
    loading: true,
    //all the calls for participant
    allCalls: [],
    //all calls for filtering based on internal convo
    all_calls:[],
    
    allcontact_Typesdata: [],
  },
  getters: {
    all_calls(state){
      return state.all_calls;
    },
    calls(state) {
      return state.calls;
    },
   
    call(state) {
      return state.call;
    },
    
    get_AllContact_Typesdata(state) {
      return state.allcontact_Typesdata;
    },    
    callsByContact(state, getters) {
      return (contact_id) => {
        return state.calls.filter((c) => getters.contactByCall(c) === contact_id);
      };
    },
    voicemails(state, getters) {
      return getters.calls.filter((c) => c.call_missed && c.has_recording);
    },
    unreturnedCalls(state, getters) {
      return getters.calls.filter((c) => c.unreturned);
    },
    // returns the main contact_id associated with the provided call (if any)
    contactByCall() {
      return (call) => {
        // let n = call.comm_participants.find((p) => p.contact_id) || {};
        // return n.contact_id || null;
        let isContact=call.isContact;
        if (isContact){
          let n = call.contact_id || {}
          return parseInt(n) || null;
        }
        return null;
      };
    },
    // returns the main number associated with the provided call (if any)
    numberByCall() {
      return (call) => {
        // let n =
          // call.comm_participants.find((p) => p.contact_id) ||
          // call.comm_participants.find((p) => p.phone_num && !p.user_id) ||
          // {};
        // return n.phone_num || null;
        let n = call.phone_num || call.first_name || {}
        return n || null
      };
    },
    loading(state) {
      return state.loading;
    },
    getAllCalls(state) {
      return state.allCalls
    },
  },
  mutations: {
    all_Calls(state,value){
      state.all_calls = value;
    },
    Calls(state, value) {
      state.calls = value;
    },   
      
    set_AllContact_Typesdata(state, value) {
      console.log("set_data", value);
      state.allcontact_Typesdata = value;
    },
    setcalls(state, value) {
      state.calls = value;
    },   
    changeCall(state, value) {
      state.call = value;
    },
    changeLoading(state, value) {
      state.loading = value;
    },
    setAllCalls(state, newState) {
      return state.allCalls = newState;
    },
    updateCallDetails(state,newState) {
      let elementIndex = [];
      let ph1,ph2,ph3;
      ph1=newState[0].ph1;
      if(newState[0].ph1.length>10){
        ph1=newState[0].ph1.substring(newState[0].ph1.length-10);
      }
      ph2=newState[0].ph2;
      if(newState[0].ph2.length>10){
        ph2=newState[0].ph2.substring(newState[0].ph2.length-10);
      }
      ph3=newState[0].ph3;
      if(newState[0].ph3.length>10){
        ph3=newState[0].ph3.substring(newState[0].ph3.length-10);
      }
      for (let i = 0; i < state.calls.length ; i++) {
        let callObject = state.calls[i]
        if(callObject.contact_id == newState[0].contact_id){
          callObject.first_name=newState[0].first_name;
          callObject.last_name=newState[0].first_name;
          callObject.ph1=newState[0].ph1;
          callObject.ph2=newState[0].ph2;
          callObject.ph3=newState[0].ph3;
          callObject.textable1=newState[0].textable1;
          callObject.textable2=newState[0].textable2;
          callObject.textable3=newState[0].textable3;
          elementIndex.push(i);
        }else if(callObject.contact_id==newState[0].ph1 ||callObject.contact_id==ph1 ||callObject.contact_id==ph2 ||callObject.contact_id==ph3 ||callObject.contact_id==newState[0].ph2 || callObject.contact_id==newState[0].ph3 ){
          callObject.contact_id=newState[0].contact_id;
          callObject.first_name=newState[0].first_name;
          callObject.last_name=newState[0].first_name;
          callObject.ph1=newState[0].ph1;
          callObject.ph2=newState[0].ph2;
          callObject.ph3=newState[0].ph3;
          callObject.textable1=newState[0].textable1;
          callObject.textable2=newState[0].textable2;
          callObject.textable3=newState[0].textable3;
          callObject.phone_num="";
          elementIndex.push(i);
        }
      }
      console.log(" the elementindex is:"+elementIndex);
      if(elementIndex.length>1){
        console.log("state calls:"+state.calls);
        for(let i=elementIndex.length-1;i!=0;i-- ){
          console.log("removing element at index"+elementIndex[i]+" the location of i"+i);
        state.calls.splice(elementIndex[i], 1);
        console.log("after removing:"+state.calls);
        }
      }
      return state.calls;
    },
    addNewCall(state, newState) {
      let elementIndex = -1;
      for (let i = 0; i < state.calls.length ; i++) {
        let callObject = state.calls[i]
        if(callObject.contact_id == newState[0].contact_id){
          elementIndex = i;
          break;
        }
      }
      
      if(elementIndex != -1)
        state.calls.splice(elementIndex, 1);
      state.calls.unshift(newState[0])
      return state.calls
    },
    callDetails(state, newState) {
      let selectedContactId = state.call.contact_id
      if(selectedContactId == newState[0].contact_id) {
        let newEntry = newState[0]
        state.allCalls.push(newEntry)
        state.allcontact_Typesdata[newEntry.contact_type].push(newEntry);
      }
      return state.allCalls
    },
  },
  actions: {
   
    async retrieveCalls({ commit, getters, rootGetters }) {
      try {
        let res = await MiscService.getCalls();
        const contactsHash = rootGetters["people/contactsHash"];
        const typeHash = rootGetters["people/contactTypes"];
        let mapper = {};
        let allcontact_Typesdata = {};
        
        for (let type of typeHash) {
          mapper[type.type_id] = type.type_name;
          allcontact_Typesdata[type.type_name] = [];
        }
        console.log("allcontact_Typesdata", allcontact_Typesdata);
        let calls = res.data.map((c) => {
          if(c == null || c===null){console.log("we have a null");}else{
         if(c.isContact){
            let contact=contactsHash[c.contact_id];
            c["ph1"] = contact.ph1
            c["ph2"] = contact.ph2
            c["ph3"] = contact.ph3
            c["textable1"] = contact.textable1
            c["textable2"] = contact.textable2
            c["textable3"] = contact.textable3
            c["contact_type"] = contact.contact_type
            let type =  contact.contact_type
            let typename = mapper[type];
            if(typename){
              allcontact_Typesdata[typename].push(c);
            }
            
          } else {
            c["phone_num"] = c.contact_id
          }
          return c;
        }
      });

        commit("set_AllContact_Typesdata", allcontact_Typesdata);      


        commit("Calls", calls);
        commit("all_Calls", calls);

        // now we must update call because the corresponding call in calls may have changed
        if (getters.call.call_id)
          commit("changeCall", getters.calls.find((e) => e.call_id === getters.call.call_id) || {});

        commit("changeLoading", false);
      } catch (err) {
        commit("changeLoading", false);
        throw new Error(err);
      }
    },
  },
};

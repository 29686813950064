<template>
  <v-card style="display: flex; flex-direction: column" height="400" width="600">
    <v-card tile height="60" color="primary" style="display: flex; align-items: center">
      <v-card-text class="ml-5 white--text" style="font-size: 21px">
        Settings
      </v-card-text>
    </v-card>
    <v-card tile height="340" style="display: flex">
      <v-card width="250">
        <v-list dense nav>
          <v-list-item class="pa-0" v-for="(item, index) in items" :key="item.title" @click="listItemSelected(index)">
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title v-if="item.selected" class="ml-3 font-weight-black">{{ item.title }}</v-list-item-title>
            <v-list-item-title v-else class="ml-3">{{ item.title }}</v-list-item-title>
            <v-icon v-if="item.selected == true" color="black">mdi-chevron-right</v-icon>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card width="350">
        <component v-bind:is="component" />
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import Password from "@/components/Settings/Password";
import Notifications from "@/components/Settings/Notifications";
import MicrophoneAndAudio from "@/components/Settings/MicrophoneAndAudio";
import Appearance from "@/components/Settings/Appearance";

export default {
  name: "SettingsDialog",
  components: {
    Password,
    Notifications,
    MicrophoneAndAudio,
    Appearance,
  },
  watch: {},
  data() {
    return {
      items: [
        { name: "Password", title: "Password", icon: "mdi-key-variant", selected: true },
        { name: "Notifications", title: "Notifications", icon: "mdi-bell", selected: false },
        {
          name: "MicrophoneAndAudio",
          title: "Microphone & Audio",
          icon: "mdi-microphone",
          selected: false,
        },
        { name: "Appearance", title: "Appearance", icon: "mdi-palette", selected: false },
      ],
      component: "Password",
    };
  },
  methods: {
    listItemSelected(itemIndex) {
      this.items.map(function(item) {
        // Setting 'selected' field of all items to false.
        item.selected = false;
        return item;
      });
      this.items[itemIndex].selected = true; // Setting 'selected' field for item that was just clicked to true.
      this.component = this.items[itemIndex].name;
    },
  },
};
</script>

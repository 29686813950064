<template>
  <div style="height: 100%; display: flex">
    <div style="flex: 3"></div>
    <div v-if="passwordUpdated == false" class="container" style="flex: 7">
      <h1 class="text-center">Update your password</h1>
      <div class="mt-4" style="width: 300px; height: 50px; border: 1px solid gray">
        <v-text-field v-model="newPassword" solo flat label="New password" type="password"></v-text-field>
      </div>
      <div class="mt-4" style="width: 300px; height: 50px; border: 1px solid gray">
        <v-text-field v-model="newConfirmedPassword" solo flat label="Confirm password" type="password"></v-text-field>
      </div>
      <v-btn
        dark
        color="light-blue darken-3"
        width="300"
        height="50"
        class="mt-5 text-capitalize font-weight-bold"
        @click="updatePasswordPressed"
      >
        Update password
      </v-btn>
      <p class="mt-4 text-center" style="width: 320px">
        Make sure it's at least 8 characters including a number and a lowercase letter. Read some documentation on
        <a href="https://help.github.com/articles/creating-a-strong-password/"> safer password practices</a>.
      </p>
    </div>
    <div v-else class="container">
      <h1 class="text-center">Update your password</h1>
      <p class="mt-4 text-center">Your password has been saved.</p>
      <v-btn
        color="white"
        width="300"
        height="50"
        class="mt-5 text-capitalize font-weight-bold"
        @click="redirectToLoginPage"
      >
        Sign back in
      </v-btn>
    </div>
    <div style="flex: 3; align-self: flex-end">
      <v-alert
        class="mr-0 mb-4"
        :value="showAlert"
        width="300"
        height="50"
        dark
        color="red"
        dismissible
        transition="slide-x-reverse-transition"
        >{{ alertMsg }}</v-alert
      >
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/authService";

// ****NOTEEE***: Revamp the transitions for the v-alert when you get the chance

export default {
  name: "PasswordUpdate",
  components: {},
  data() {
    return {
      passwordUpdated: false,
      newPassword: null,
      newConfirmedPassword: null,
      showAlert: false,
      alertMsg: "Invalid password",
      updateDisabled: false,
    };
  },
  methods: {
    hideAlert: function() {
      // `event` is the native DOM event
      setTimeout(() => {
        this.showAlert = false;
        this.updateDisabled = false;
      }, 3000);
    },
    // Checking if the password entered contains at least one lowercase letter.
    containsLowerCaseLetter(password) {
      return /[a-z]/.test(password);
    },
    // Checking if the password entered contains at least one digit.
    containsNumber(password) {
      return /\d/.test(password);
    },
    updatePasswordPressed() {
      if (this.updateDisabled == false) {
        const passwordsDoNotMatch = this.newPassword != this.newConfirmedPassword;
        const noLowerCaseLetter = this.containsLowerCaseLetter(this.newPassword) == false;
        const noNumber = this.containsNumber(this.newPassword) == false;
        if (!this.newPassword || passwordsDoNotMatch || noLowerCaseLetter || noNumber || this.newPassword.length < 8) {
          if (passwordsDoNotMatch) {
            this.alertMsg = "Passwords do not match";
          } else {
            this.alertMsg = "Invalid password";
          }
          this.updateDisabled = true;
          this.showAlert = true;
          this.hideAlert();
        } else {
          var data = { password: this.newPassword };
          AuthService.updatePassword(this.$route.params.token, data)
            .then((response) => {
              if (response.data == "password updated!") {
                this.passwordUpdated = true; // This will change the display of this component
              } else {
                this.alertMsg = response.data;
                this.updateDisabled = true;
                this.showAlert = true;
                this.hideAlert();
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
    redirectToLoginPage() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*.container.my-font {
  font-family: "Alegreya Sans";
}*/

#no-background-hover::before {
  background-color: transparent !important;
}

.remember-password-btn {
  color: #01579b;
}

.remember-password-btn:hover {
  color: black;
}
</style>

<template>
  <div style="fles: 1; display: flex; justify-content: center; align-items: center; width: 250px;">
    <div style="flex: 1; text-align: center;">
      <h5>Calling '{{ displayTitle }}'</h5>
      <v-progress-linear style="margin-left: 5%; width: 90%" indeterminate></v-progress-linear>
    </div>
    <div class="cancel-btn icon-align">
      <v-btn color="red" variant="text" style="width: 25px; height: 25px;" icon @click="cancelOutboundCall()">
        <v-icon color="white" size="x-small">mdi-phone-off</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "CallingDisplay",
  props: {
    displayTitle: String,
  },
  methods: {
    ...mapMutations("plivo", ["setCallBarDisplay"]),

    cancelOutboundCall() {
      // Canceling the call and setting the CallBar display back to "default."
      this.plivoBrowserSdk.client.hangup();
      this.setCallBarDisplay("default");
    },
  },
};
</script>

<style scoped>
.cancel-btn {
  background: #f8302c;
  border-radius: 100%;
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.icon-align {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

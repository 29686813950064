import MiscService from "@/services/miscService";
import moment from "moment";
import ConvoService from "@/services/convoService";
import Vue from "vue";

// this is the vuex module for the Outstanding Claims Workflow
export const moduleOSClaims = {
  namespaced: true,
  state: {
    // an array containing all the claims
    claims: [],
    // the current claim selected
    claim: {},
    // saves the last selected carrier so that in the case the user leaves the page and comes back,
    // the same carrier will be selected
    lastSelectedCarrier: "",
    // contains the allowed values for each claim property. Every other property will be filtered out in 'filteredClaims'
    // if a claim property is not included in this object, it will not be filtered
    filter: null,
    // contains the configuration for the defaultFilter
    // used to display the filtering options
    defaultFilter: null,
    // true if the claims/convos are loading (they are being received from the server)
    loading: true,
    //General convo process name:
    process: '',
  },
  getters: {
    _process(state) {
      return state.process
    },
    claims(state) {
      let claims = state.claims;

      let priority = (c) => {
        if (!c.convo_id) return 2;
        if (c.convo.convo_status) return 3;
        return moment(c.convo.messages_updated_at).isBefore(moment().subtract(1, "d")) ? 1 : 4;
      };

      // add a priority property to each claim
      claims.forEach((c) => {
        c["priority"] = priority(c);
      });

      return claims;
    },
    claim(state) {
      return state.claim;
    },
    filteredClaims(state) {
      if (!state.filter) return state.claims;
      return state.claims.filter((c) => {
        let keys = Object.keys(state.filter);
        for (let i = 0; i < keys.length; i++) {
          if (state.filter[keys[i]].includes(c[keys[i]])) return true;
          return false;
        }
      });
    },
    lastSelectedCarrier(state) {
      return state.lastSelectedCarrier;
    },
    filter(state) {
      return state.filter;
    },
    defaultFilter(state) {
      return state.defaultFilter;
    },
    loading(state) {
      return state.loading;
    },
    // gets the number of filters currently applied
    // used for display purposes
    filterCount(state, getters) {
      let count = 0;
      Object.keys(getters.defaultFilter).forEach((key) => {
        count += getters.defaultFilter[key].length - getters.filter[key].length;
      });
      return count;
    },
    convoID(state, getters) {
      return getters.claim.convo_id || null;
    },
    claimsWithOpenConvos(state, getters) {
      let convoPriority = (c) => (c.convo.userReminder ? 2 : c.convo.userMentioned ? 1 : 3);
      return getters.claims
        .filter((e) => e.convo && e.convo.convo_status)
        .sort((a, b) => {
          let pA = convoPriority(a);
          let pB = convoPriority(b);
          // if p! and pB are equal and they both have reminders
          if (pA === pB && pA === 2)
            return moment(a.convo.userReminder.reminder_date).isBefore(moment(b.convo.userReminder.reminder_date))
              ? -1
              : 1;
          return pA <= pB ? -1 : 1;
        });
    },
    carriers(state, getters) {
      // count frequency and urgency for each carrier based on claims
      let freq = {};
      let urgency = {};
      for (let i = getters.filteredClaims.length - 1; i > -1; i--) {
        let c = getters.filteredClaims[i].Carrier;
        !freq[c] ? (freq[c] = 1) : freq[c]++;
        if (getters.filteredClaims[i].priority !== 4) !urgency[c] ? (urgency[c] = 1) : urgency[c]++;
      }

      // get unique carriers
      let carriers = [];
      getters.filteredClaims.forEach((claim) => {
        if (carriers.findIndex((x) => x.name === claim.Carrier) <= -1)
          carriers.push({
            name: claim.Carrier,
            num: claim.PhNum,
            freq: freq[claim.Carrier],
            urgent: urgency[claim.Carrier] || 0,
          });
      });

      // sort by urgency
      return carriers.sort((a, b) => b.urgent - a.urgent);
    },
  },
  mutations: {
    changeProcess(state, value) {
      state.process = value;
    },
    changeClaims(state, value) {
      state.claims = value;
    },
    changeClaim(state, value) {
      state.claim = value;
    },
    changeLastSelectedCarrier(state, value) {
      state.lastSelectedCarrier = value;
    },
    changeFilter(state, value) {
      state.filter = value;
    },
    changeDefaultFilter(state, value) {
      state.defaultFilter = value;
    },
    changeLoading(state, value) {
      state.loading = value;
    },
    // assigns a newly created convo to a claim in the state
    // payload.convo specifies the convo
    // payload.DSSClaimsID specifies the DSSClaimsID of the claim that the convo belongs to
    addConvo(state, payload) {
      // first, check if the convo belongs to the selected claim
      if (state.claim.DSSClaimsID === payload.DSSClaimsID) {
        state.claim["convo"] = payload.convo;
        state.claim["convo_id"] = payload.convo.convo_id;
      } else {
        // otherwise, go through all the claims and find the one to add the convo to
        let claim = state.claims.find((c) => c.DSSClaimsID === payload.DSSClaimsID);
        if (claim) {
          claim["convo"] = payload.convo;
          claim["convo_id"] = payload.convo.convo_id;
        }
      }
    },
  },
  actions: {
    async retrieveClaims({ dispatch, commit, getters }, status) {
      try {
        if (status == "todayNews") {
          let res = await ConvoService.getTodayConvos();
          console.log("res",res)
          let claims = res.data;
          claims = claims.map(convo => ({ convo }));
          let convos = [];

          // populate the convos array
          claims.forEach((c) => {
            c.DSSClaimsID = c.convo.convo_id
            if (c.convo) convos.push(c.convo);
          });
          // add convos to convos module
          await dispatch("convos/addNewConvos", convos, { root: true });

          // add claims to this module
          commit("changeClaims", claims);

          // for now, we only filter by claim type
          commit("changeDefaultFilter", { Type: [...new Set(claims.map((claim) => claim.Type))] });
          if (!Object.keys(getters.claim).length) commit("changeFilter", getters.defaultFilter);
        }
        else if (status != "osclaims") {
          let res = await ConvoService.getConvos(status);
          let claims = res.data;
          claims = claims.map(convo => ({ convo }));
          let convos = [];

          // populate the convos array
          claims.forEach((c) => {
            c.DSSClaimsID = c.convo.convo_id
            if (c.convo) convos.push(c.convo);
          });
          // add convos to convos module
          await dispatch("convos/addNewConvos", convos, { root: true });

          // add claims to this module
          commit("changeClaims", claims);

          // for now, we only filter by claim type
          commit("changeDefaultFilter", { Type: [...new Set(claims.map((claim) => claim.Type))] });
          if (!Object.keys(getters.claim).length) commit("changeFilter", getters.defaultFilter);
        }
        else {
          let res = await MiscService.getOutstandingClaims();
          let claims = res.data;
          let convos = [];

          // populate the convos array
          claims.forEach((c) => {
            if (c.convo) convos.push(c.convo);
          });
          // add convos to convos module
          await dispatch("convos/addNewConvos", convos, { root: true });

          // add claims to this module
          commit("changeClaims", claims);

          // for now, we only filter by claim type
          commit("changeDefaultFilter", { Type: [...new Set(claims.map((claim) => claim.Type))] });
          if (!Object.keys(getters.claim).length) commit("changeFilter", getters.defaultFilter);
        }

      } catch (err) {
        throw new Error(err);
      }
    },
  },
};

<template>
  <div>
    <v-menu :close-on-content-click="false" offset-x max-width="300" offset-overflow>
      <template v-slot:activator="{ props }">
        <v-icon v-bind="props">mdi-cog</v-icon>
      </template>
      <v-card v-if="sections">
        <div class="mx-2">
          <div v-for="section in sections" v-bind:key="section.title">
            <v-list-subheader class="my-0">{{ section.title }}</v-list-subheader>
            <!-- check boxes -->
            <div v-if="section.type == 'check-boxes'">
              <div v-for="option in section.options" v-bind:key="option.title">
                <v-checkbox
                  class="my-0"
                  v-model="status[section.title]"
                  @change="$emit(section.eventName, status[section.title])"
                  :label="option"
                  :value="option"
                  hide-details
                  dense
                ></v-checkbox>
              </div>
            </div>
            <!-- drop down -->
            <div v-if="section.type == 'drop-down'">
              <v-select
                class="my-0"
                v-model="status[section.title]"
                :items="section.options"
                @change="$emit(section.eventName, status[section.title])"
                dense
              ></v-select>
            </div>
            <!-- filter component -->
            <div v-if="section.type == 'filter-component'">
              <v-expansion-panels>
                <v-expansion-panel v-for="option in section.options" :key="option.title">
                  <v-expansion-panel-title>
                    {{ option.title }}
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <div v-for="subOption in option.options" v-bind:key="subOption.title">
                      <v-checkbox
                        class="my-0"
                        v-model="status[section.title][option.title]"
                        @change="$emit(section.eventName, { [option.title]: status[section.title][option.title] })"
                        :label="subOption"
                        :value="subOption"
                        hide-details
                        dense
                      ></v-checkbox>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "MiniSettings",
  props: {
    sections: { type: Array },
  },
  data() {
    return {
      // keeps track of what is selected in the settings for each settings component
      // (ex: radio buttons, drop down)
      status: {},
    };
  },
  created() {
    // for each section
    this.sections.forEach((s) => {
      if (s.type === "filter-component") {
        this.status[s.title] = {};
        s.options.forEach((o) => {
          this.status[s.title][o.title] = o.options;
        });
      } else this.status[s.title] = s.defaultConfig;
    });
  },
};
// Here is an example of the format that the sections prop should be in.
// (Radio buttons are no longer available but will be added if needed)
// sections: [
//         {
//           type: "check-boxes",
//           title: "Select any number of boxes",
//           eventName: "checkBoxChanged",
//           options: [
//             "Box 1",
//             "Box 2",
//             "Box 3",
//           ],
//           defaultConfig: ["Box 1", "Box 2"]
//         },
//         {
//           type: "drop-down",
//           title: "Select one",
//           eventName: "dropDownChanged",
//           options: [
//             "Choice 1",
//             "Choice 2",
//             "Choice 3",
//           ],
//           defaultConfig: "Choice 1"
//         },
// This is a custom filter component made up of collapsable checkbox groups. All the checkboxes
// begin in the checked state. (ex: if hair color brown is unchecked, the event,
// filterChanged() will be emitted with paramater {"Hair Color": ["black"]}
//         {
//           type: "filter-component",
//           title: "Filter by",
//           eventName: "filterChanged",
//           options: [
//             { title: "Hair color", options: ["brown", "black"] },
//             { title: "Location", options: ["USA", "Canada"] },
//             { title: "Age", options: ["0-10", "10+"] },
//           ]
//         }
//       ]
</script>

<template>
  <div>
    <div v-if="!passwordChanged" style="display: flex; flex-direction: column; align-items: center;">
      <v-form ref="entryForm" @submit.prevent="changePasswordClick()">
        <v-text-field
          class="mt-9"
          label="Current password"
          v-model="currentPassword"
          :rules="currentPasswordRules"
          type="password"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          class="mt-1"
          label="New password"
          v-model="newPassword"
          :rules="newPasswordRules"
          type="password"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          class="mt-1"
          label="Confirm password"
          v-model="confirmedNewPassword"
          :rules="confirmedNewPasswordRules"
          type="password"
          outlined
          dense
        ></v-text-field>
        <v-btn class="mt-1" color="primary" type="submit">Change password</v-btn>
      </v-form>
    </div>
    <div v-else style="display: flex; justify-content: center">
      <h5 style="margin-top: 130px">Password changed!</h5>
    </div>
  </div>
</template>

<script>
import MiscService from "@/services/miscService";

export default {
  name: "Password",
  data() {
    return {
      passwordChanged: false,
      currentPassword: null,
      newPassword: null,
      confirmedNewPassword: null,
      currentPasswordRules: [],
      newPasswordRules: [],
      confirmedNewPasswordRules: [],
    };
  },
  watch: {
    currentPassword() {
      this.currentPasswordRules = [];
    },
    newPassword() {
      this.newPasswordRules = [];
    },
    confirmedNewPassword() {
      this.confirmedNewPasswordRules = [];
    },
  },
  methods: {
    changePasswordClick() {
      let data = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmedNewPassword: this.confirmedNewPassword,
      };
      MiscService.updatePassword(data)
        .then((response) => {
          if (response.data == "password updated!") {
            this.passwordChanged = true;
          } else {
            this.currentPasswordRules = response.data[0];
            this.newPasswordRules = response.data[1];
            this.confirmedNewPasswordRules = response.data[2];
          }
        })
        .catch((e) => {
          console.log(e);
        });
      let self = this;
      self.$refs.entryForm.validate();
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <v-menu activator="parent" v-model="menuOpened" :close-on-content-click="false">
      <template v-slot:activator="{ props: menu }">
        <v-tooltip location="bottom" text="Audio Settings">
          <template v-slot:activator="{ props: tooltip }">
            <v-btn v-bind="mergeProps(menu, tooltip)" icon>
              <v-icon color="blue-grey darken-3">
                mdi-headphones
              </v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </template>
      <v-list class="pa-0">
        <v-list-item
          class="pb-0"
          style="
            display: flex;
            flex-direction: column;
            align-items: start;
            outline: 1px solid #e0e0e0;
            width: 425px;
            height: 130px;
            overflow-y: hidden;
          "
          v-for="(setting, index) in settings"
          :key="index"
        >
          <div>
            <div v-if="index == 0" class="mt-4 mb-2" style="display: flex; align-items: center; width: 360px">
              <v-list-item-title style="font-weight: 550; font-size: 14px">{{ setting.title }}</v-list-item-title>
              <v-icon color="black" left>mdi-microphone</v-icon>
              <span :style="dotTypeOne" class="dot"></span>
              <span :style="dotTypeTwo" class="dot"></span>
              <span :style="dotTypeOne" class="dot mr-7"></span>
            </div>
            <div v-else class="mt-3" style="display: flex; width: 360px; justify-content: space-between;">
              <v-list-item-title style="font-weight: 550; font-size: 14px">{{ setting.title }}</v-list-item-title>
              <v-btn
                @click="testAudioOutputDevice(index)"
                :disabled="setting.state == 'playing'"
                color="teal darken-3"
                variant="text"
                class="text-capitalize red--text font-weight-medium"
                ><v-icon style="margin-right: 5px" id="custom-disabled">mdi-volume-high</v-icon>{{ setting.state }}</v-btn
              >
            </div>
          </div>
          <v-select
            color="teal darken-3"
            item-color="teal darken-3"
            density="compact"
            variant="outlined"
            :value="setting.currDevice.name"
            :items="setting.deviceList"
            item-title="name"
            item-value="id"
            @update:modelValue="changeDevice($event, index)"
            class="mt-2"
            style="
              height: 50px;
              width: 370px;
              font-size: 14px;
            "
          ></v-select>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mergeProps } from 'vue';

export default {
  data() {
    return {
      startButton: null, // Will serve as the start button start process which measures the microphone level every 100ms
      stopButton: null, // Will serve as the stop button to end the aforementioned process
      menuOpened: false, // Will track whether audio settings menu is correctly open or closed
      inputDevices: [],
      outputDevices: [],
      dotTypeOneHeight: 4, // Will dynamically change the height of the middle dot visualizing microphone level
      dotTypeTwoHeight: 4, // Will dynamically change the height of the side dots visualizing microphone level
      settings: [
        {
          title: "Microphone",
          deviceList: this.inputDevices,
          currDevice: null,
          state: null,
        },
        {
          title: "Ringing",
          deviceList: this.outputDevices,
          currDevice: null,
          state: "test",
        },
        {
          title: "Speakers",
          deviceList: this.outputDevices,
          currDevice: null,
          state: "test",
        },
      ],
      sound: null,
    };
  },
  methods: {
    mergeProps,
    // This function will be called whenever the device list changes.
    updateDeviceList() {
      //console.log("device list has changed!");
      this.deviceListToggleKey = !this.deviceListToggleKey;
      navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos) => {
          // Resetting/clearing input and output devices list so we can repopulate it.
          this.inputDevices = [];
          this.outputDevices = [];
          for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];
            const device = { id: deviceInfo.deviceId, name: deviceInfo.label };
            if (deviceInfo.kind == "audioinput") {
              this.inputDevices.push(device);
            } else if (deviceInfo.kind == "audiooutput") {
              this.outputDevices.push(device);
              // console.log(deviceInfo);
            }
          }

          // Here, we are handling the case that enumerateDevices() was unable to
          // find the registered media input and output devices, in which we will
          // create a default device id and name.
          if (this.inputDevices.length == 0) {
            const device = {
              id: "48fj2390rjjjj",
              name: "System Default Microphone Device",
            };
            this.inputDevices.push(device);
          }
          if (this.outputDevices.length == 0) {
            const device = {
              id: "48fj2390rjjjj",
              name: "System Default Speaker Device",
            };
            this.outputDevices.push(device);
          }

          // Here we are updating the device list for each of the settings (microphone, ringer, speaker),
          // and also updating the current device being used to the device that was just plugged in.
          this.settings[0].deviceList = this.inputDevices;
          this.settings[0].currDevice = this.inputDevices[0];
          this.settings[1].deviceList = this.outputDevices;
          this.settings[1].currDevice = this.outputDevices[0];
          this.settings[2].deviceList = this.outputDevices;
          this.settings[2].currDevice = this.outputDevices[0];
          console.log(this.settings);
          console.log("device list has been updated!");
        })
        .catch((err) => console.log(err));
    },
    changeDevice(deviceId, settingIndex) {
      // Only going to change the device if the id is not the fake one we made for
      // the case that no devices were found.
      if (deviceId !== "48fj2390rjjjj") {
        if (settingIndex == 0) {
          // This corresponds with the 'Microphone' setting
          // Getting the index of the input device which was selected/clicked
          const inputDeviceIndex = this.inputDevices.findIndex((device) => device.id == deviceId);
          // Changing the current microphone device being used to the input device which
          // was just clicked.
          this.settings[settingIndex].currDevice = this.inputDevices[inputDeviceIndex];
        } else {
          // Handling case that setting was either 'Ringing' or 'Speakers'
          // Getting the index of the input device which was selected/clicked
          const outputDeviceIndex = this.outputDevices.findIndex((device) => device.id == deviceId);
          // Changing either the current 'ringing' or 'speakers' device (whichever setting was clicked)
          // to the output device which was just clicked.
          this.settings[settingIndex].currDevice = this.outputDevices[outputDeviceIndex];
        }
        this.plivoSetInputOutputDevice(deviceId, settingIndex);
      }
    },
    // Note: In this method we are using the plivo apis to set the current input/output
    // device being used (we did get the device id's ourselves, without plivo) as this
    // does seem to be the more straightforward and concise solution, but eventually
    // we will change this to manually change the microphone, ringer, and speaker device
    // without plivo apis once we switch to the server side sdk or choose a different
    // service provider.
    plivoSetInputOutputDevice(deviceId, settingIndex) {
      if (settingIndex == 0) {
        this.plivoBrowserSdk.client.audio.microphoneDevices.set(deviceId);
      } else if (settingIndex == 1) {
        this.plivoBrowserSdk.client.audio.ringtoneDevices.set(deviceId);
      } else {
        this.plivoBrowserSdk.client.audio.speakerDevices.set(deviceId);
      }
    },
    // Here we will either receive the index of the 'ringing' setting or 'speaker' setting
    // and will change the current output device so that we can play a noise to test
    // the setting/device the user wants to test.
    testAudioOutputDevice(settingIndex) {
      // Resetting sound
      this.sound.pause();
      this.sound.currentTime = 0;
      // This is the case where the only output device is the fake device
      // (i.e. the default system speaker device), in which we do not have to do any
      // of the setSinkId() stuff as our device id is not valid, so we will simply just
      // play the sound and change the states of the settings from 'test' to 'playing'
      // back to 'test.'
      if (this.settings[2].currDevice.id == "48fj2390rjjjj") {
        this.settings[1].state = "test";
        this.settings[2].state = "test";
        this.settings[settingIndex].state = "playing";
        this.sound.play();
        this.sound.onended = () => {
          this.settings[settingIndex].state = "test";
        };
        // Now handling the regular case, no fake device ids!
      } else {
        // Resetting output device back to speaker
        const sinkId = this.settings[2].currDevice.id;
        this.sound
          .setSinkId(sinkId)
          .then(function() {
            //console.log("Audio output device attached: " + sinkId);
          })
          .catch(function(error) {
            console.log(error);
          });
        // Handling case that setting pressed is 'ringing' device setting
        if (settingIndex == 1) {
          this.settings[1].state = "playing";
          this.settings[2].state = "test";
          // Temporarily changing output device to the device set for the 'ringing' setting.
          let tempSinkId = this.settings[1].currDevice.id;
          this.sound
            .setSinkId(tempSinkId)
            .then(function() {
              //console.log("Audio output device attached: " + tempSinkId);
            })
            .catch(function(error) {
              console.log(error);
            });
          this.sound.play();
          this.sound.onended = () => {
            this.settings[1].state = "test";
            // Setting output device back to the device set for the 'speaker' setting.
            let tempSinkId = this.settings[2].currDevice.id;
            this.sound
              .setSinkId(tempSinkId)
              .then(function() {
                //console.log("Audio output device attached: " + tempSinkId);
              })
              .catch(function(error) {
                console.log(error);
              });
          };
        } else {
          this.settings[2].state = "playing";
          this.settings[1].state = "test";
          this.sound.play();
          this.sound.onended = () => {
            this.settings[2].state = "test";
          };
        }
      }
    },
  },
  computed: {
    // Dynamically sets the heights for each of the microphone setting visualization dots.
    dotTypeOne() {
      return {
        height: `${this.dotTypeOneHeight}px`,
      };
    },
    dotTypeTwo() {
      return {
        height: `${this.dotTypeTwoHeight}px`,
      };
    },
  },
  watch: {
    // Will track whether the audio settings menu is open or not, if it is not open,
    // we can stop the process which measures the microphone level every 100ms,
    // if menu is opened (the else branch) then we can start the process again.
    menuOpened: function(val) {
      if (val == false) {
        this.stopButton.click();
      } else {
        this.startButton.click();
      }
    },
  },
  beforeMount() {
    // Initializing sound object which we will play when testing output devices.
    this.sound = new Audio(require("@/../public/assets/iphoneringer.mp3"));

    // Here we are registering the function updateDeviceList to be called on
    // the ondevicechange event (i.e. the list of connected media devices has changed)
    navigator.mediaDevices.ondevicechange = (event) => {
      console.log(event);
      this.updateDeviceList();
    };
    // Updating the device list to start, so we can get our initial list of devices.
    this.updateDeviceList();

    // Big thanks to Minding for providing the async function used here to get the
    // microphone input volume level every 100ms.
    // Link to their code: https://stackoverflow.com/questions/33322681/checking-microphone-volume-in-javascript/64650826#64650826
    (async () => {
      let volumeCallback = null;
      let volumeInterval = null;
      this.startButton = document.createElement("start"); // Elements which we will later register listeners for, to halt and start the microphone getting level/volume every 100ms process.
      this.stopButton = document.createElement("stop");
      // Initialize
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
          },
        });
        const audioContext = new AudioContext();
        const audioSource = audioContext.createMediaStreamSource(audioStream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 512;
        analyser.minDecibels = -127;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.4;
        audioSource.connect(analyser);
        const volumes = new Uint8Array(analyser.frequencyBinCount);
        volumeCallback = () => {
          analyser.getByteFrequencyData(volumes);
          let volumeSum = 0;
          for (const volume of volumes) volumeSum += volume;
          const averageVolume = volumeSum / volumes.length;
          // If volume is below 25 then we will display as no volume.
          if (averageVolume < 25) {
            this.dotTypeTwoHeight = 4;
            this.dotTypeOneHeight = 4;
          } else {
            this.dotTypeTwoHeight = Math.round(Math.log(averageVolume) * 3.5);
            this.dotTypeOneHeight = Math.round(Math.log(averageVolume) * 2.5);
          }
          // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
          //console.log("heres the volume: " + averageVolume);
        };
      } catch (e) {
        console.error("Error during attempt to measure microphone volume level...", e);
      }

      // These listeners will be used to start and stop the process of measuring the
      // microphone volume level every 100 ms. Will be called in the 'watch' section above.
      this.startButton.addEventListener("click", () => {
        // Updating every 100ms (should be same as CSS transition speed)
        if (volumeCallback !== null && volumeInterval === null) volumeInterval = setInterval(volumeCallback, 100);
      });
      this.stopButton.addEventListener("click", () => {
        if (volumeInterval !== null) {
          clearInterval(volumeInterval);
          volumeInterval = null;
        }
      });
    })();
  },
};
</script>

<style scoped>
#custom-disabled {
  color: #424242 !important;
}

.dot {
  background-color: #00695c;
  border-radius: 2px;
  display: inline-block;
  width: 5px;
  margin-right: 2px;
}
</style>

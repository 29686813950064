// This mixin helps with generating colors for different components

export default {
  data() {
    return {
      // Random colors for the list (31 total)
      colors: [
        "#3366cc",
        "#dc3912",
        "#ff9900",
        "#109618",
        "#990099",
        "#0099c6",
        "#dd4477",
        "#66aa00",
        "#b82e2e",
        "#316395",
        "#994499",
        "#22aa99",
        "#aaaa11",
        "#6633cc",
        "#e67300",
        "#8b0707",
        "#651067",
        "#329262",
        "#5574a6",
        "#3b3eac",
        "#b77322",
        "#16d620",
        "#b91383",
        "#f4359e",
        "#9c5935",
        "#a9c413",
        "#2a778d",
        "#668d1c",
        "#bea413",
        "#0c5922",
        "#743411",
      ],
    };
  },
  methods: {
    // generates a random color
    randomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    // generated a 'random' color based on a hash value of a string
    // (i.e. the same string wil always return the same color)
    // hash function from: https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
    hashedColor(s) {
      var hash = 0;
      if (!s || s.length == 0) return this.colors[0];

      for (var i = 0; i < s.length; i++) {
        var char = s.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
      }
      return this.colors[Math.abs(hash % this.colors.length)];
    },
  },
};

<template>
  <div>
    <v-dialog v-model="reminderDialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span v-if="!hasReminder" class="text-h6">Set Conversation Reminder</span>
          <span v-else class="text-h6">Edit Conversation Reminder</span>
          <v-spacer></v-spacer>
          <span class="text-h6">{{phoneFormatTitle(contact.full_name)}}</span>
        </v-card-title>
        <v-card-text class="column justify-center m10">
          <v-list-subheader class="justify-center">Select a date and time</v-list-subheader>
          <div class="justify-center" style="text-align: center">
            <input style="width: 200px" type="datetime-local" v-model="reminderDate" :min="minReminderTime">
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="hasReminder" color="red darken-1" text @click="_deleteReminder()">
            Remove Reminder
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="_addReminder()">
            <div v-if="!hasReminder">Set new Reminder</div>
            <div v-else>Change Current Reminder</div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import { mapGetters } from "vuex";
import { phoneFormatTitle } from "@/util/util";

export default {
  name: "ReminderPanel",
  mixins: [colorMixin],
  props: {
    // used to activate the reminder dialog
    toggleDialog: { type: Boolean, default: false },
    contact: { type: Object, default: null}
  },
  data() {
    return {
      reminderDialog: false,
      hasReminder: false,
      reminderDate: this.moment().add(1, "minute").format("YYYY-MM-DDThh:mm"),
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    minReminderTime() {
      return this.moment().add(1, "minute").format("YYYY-MM-DDThh:mm");
    },
  },
  watch: {
    toggleDialog() {
      this.reminderDialog = !this.reminderDialog;
    },
  },
  methods: {
    _addReminder() {
      let date = this.moment(this.reminderDate).toISOString();
      console.log(date);
    },
    _deleteReminder() {
      console.log("delete");
    },
    phoneFormatTitle,
  },
};
</script>

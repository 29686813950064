<template>
  <div>
    <b-dropdown variant="link" no-caret dropleft>
      <template v-slot:button-content>
        <p class="h5 mb-2">
          <BIconGear />
        </p>
      </template>
      <div v-bind:key="section.title" v-for="section in sections">
        <b-dropdown-group v-bind:header="section.title">
          <b-col>
            <!-- radio buttons -->
            <div v-if="section.type == 'radio-buttons'">
              <b-form-group>
                <b-form-radio-group
                  v-model="status[section.title].selected"
                  :options="section.options"
                  stacked
                  size="sm"
                  @input="$emit(section.eventName, status[section.title].selected)"
                ></b-form-radio-group>
              </b-form-group>
            </div>
            <!-- check boxes -->
            <div v-if="section.type == 'check-boxes'">
              <b-form-group>
                <b-form-checkbox-group
                  v-model="status[section.title].selected"
                  :options="section.options"
                  stacked
                  size="sm"
                  @input="$emit(section.eventName, status[section.title].selected)"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
            <!-- drop down -->
            <div v-if="section.type == 'drop-down'">
              <b-form-select
                v-model="status[section.title].selected"
                :options="section.options"
                size="sm"
                @input="$emit(section.eventName, status[section.title].selected)"
              ></b-form-select>
            </div>
            <!-- filter component -->
            <div v-if="section.type == 'filter-component'">
              <b-button-group vertical>
                <div v-bind:key="option.text" v-for="option in section.options">
                  <b-button
                    v-b-toggle="option.text"
                    size="sm"
                    variant="link"
                    class="text-decoration-none shadow-none"
                  >
                    <BIconCaretDownFill class="when-closed" style="width: .8em; height: .8em;" />
                    <BIconCaretUpFill class="when-open" style="width: .8em; height: .8em;" />
                    {{option.text}}
                  </b-button>
                  <b-collapse v-bind:id="option.text.split(' ').splice(-1)[0]">
                    <b-form-group>
                      <b-form-checkbox-group
                        v-model="status[section.title][option.property]"
                        :options="section.options[section.options.findIndex(x => x.text == option.text)].options"
                        stacked
                        size="sm"
                        @change="$emit(section.eventName, status[section.title])"
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-collapse>
                </div>
              </b-button-group>
            </div>
            <!-- <b-dropdown-divider></b-dropdown-divider> -->
          </b-col>
        </b-dropdown-group>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "Settings",
  props: {
    sections: { type: Array },
    updateFilter: { type: Boolean },
  },
  data() {
    return {
      // keeps track of what is selected in the settings for each settings component
      // (ex: radio buttons, drop down)
      status: {},
    };
  },
  created() {
    // for each section
    for (let i = 0; i < this.sections.length; i++) {
      if (this.sections[i].type == "filter-component") {
        this.status[this.sections[i].title] = {};
        // for each filter option
        for (let j = 0; j < this.sections[i].options.length; j++) {
          let temp = this.sections[i].options[j].options;
          this.status[this.sections[i].title][
            this.sections[i].options[j].property
          ] = temp;
        }
      } else {
        this.status[this.sections[i].title] = {
          selected: this.sections[i].defaultConfig,
        };
      }
    }
  },
  watch: {
    updateFilter: function () {
      // for each section
      for (let i = 0; i < this.sections.length; i++) {
        if (this.sections[i].type == "filter-component") {
          this.status[this.sections[i].title] = {};
          // for each filter option
          for (let j = 0; j < this.sections[i].options.length; j++) {
            let temp = this.sections[i].options[j].options;
            this.status[this.sections[i].title][
              this.sections[i].options[j].property
            ] = temp;
          }
        }
      }
    },
  },
};

// Here is an example of the format that the sections prop should be in
// sections: [
//         {
//           type: "radio-buttons",
//           title: "Select only one",
//           eventName: "radioChanged",
//           options: [
//             { text: "Button 1", value: "1" },
//             { text: "Button 2", value: "2" },
//             { text: "Button 3", value: "3" },
//           ],
//           defaultConfig: "1"
//         },
//         {
//           type: "check-boxes",
//           title: "Select any number of boxes",
//           eventName: "checkBoxChanged",
//           options: [
//             { text: "Box 1", value: "1" },
//             { text: "Box 2", value: "2" },
//             { text: "Box 3", value: "3" },
//           ],
//           defaultConfig: ["1", "3"]
//         },
//         {
//           type: "drop-down",
//           title: "Select one",
//           eventName: "dropDownChanged",
//           options: [
//             { text: "Choice 1", value: "1" },
//             { text: "Choice 2", value: "2" },
//             { text: "Choice 3", value: "3" },
//           ],
//           defaultConfig: "3"
//         },
// This is a custom filter component made up of collapsable checkbox groups. All the checkboxes
// begin in the checked state, and if one changes, the event will be emitted with the current unckecked
// boxes as parameters. (ex below: if hair color brown and location USA are unchecked, the event,
// filterChanged() will be emitted with paramater [["hair color", "brown"], ["location", "USA"]])
//         {
//           type: "filter-component",
//           title: "Filter by",
//           eventName: "filterChanged",
//           options: [
//             { text: "Hair color", options: ["brown", "black"], property: "hairColor" },
//             { text: "Location", options: ["USA", "Canada"], property: "location" },
//             { text: "Age", options: ["0-10", "10+"], property: "age" },
//           ]
//         }
//       ]
</script>

<style scoped>
tt {
  font-size: 100px;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>

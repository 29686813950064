import moment from "moment";

// This mixin provides different functions to help with dates
export default {
  methods: {
    // provides the number of days/hours that the provided date is from the current time (ex: 3d, 4h, -2d)
    fromNow(date) {
      let r = moment(date).diff(moment().startOf("day"), "days");
      if (Math.abs(r) >= 1) return r + "d";
      r = moment(date).diff(moment(), "hours");
      if (Math.abs(r) >= 1) return r + "h";
      return "now";
    },
    // estimates the reading time in milliseconds of the text provided
    readingTime(text) {
      const wpm = 100;
      return (text.toString().trim().split(/\s+/).length / wpm) * 20000;
    },
    // converts a date to a string format that is easy to understand and read.
    // this format will be more specific for dates closer to the current date and
    // less specific for dates far away from the current date
    simpleDateFormat(date) {
      return moment(date).calendar(null, {
        // when the date is closer
        lastWeek: "ddd h:mm A",
        lastDay: "ddd h:mm A",
        sameDay: "[Today] h:mm A",
        // when the date is further away
        sameElse: "M/D/YYYY",
      });
    },
  },
};

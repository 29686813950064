<template>
  <div class="container">
    <div v-if="passwordResetEmailSubmitted == false" class="container">
      <h1 class="text-center">Reset Your Password</h1>
      <p class="mt-2">
        It happens to the best of us. Enter your email and we'll send you reset instructions.
      </p>
      <div class="mt-2" style="width: 300px; height: 50px; border: 1px solid gray">
        <v-text-field v-model="email" solo flat label="Email address" type="text"></v-text-field>
      </div>
      <v-btn
        dark
        color="light-blue darken-3"
        width="300"
        height="50"
        class="mt-6 text-capitalize font-weight-bold"
        @click="resetPassword"
      >
        Send password reset email
      </v-btn>
      <v-btn
        id="no-background-hover"
        class="mt-4 remember-password-btn text-capitalize font-weight-medium"
        :ripple="false"
        :plain="true"
        text
        @click="redirectToLoginPage"
        >I remember my password</v-btn
      >
    </div>
    <div v-else class="container">
      <h1 class="text-center">Reset Your Password</h1>
      <p class="mt-4 text-center" style="width: 310px">
        If that account is in our system, we emailed you a link to reset your password.
      </p>
      <v-btn
        color="white"
        width="300"
        height="50"
        class="mt-5 text-capitalize font-weight-bold"
        @click="redirectToLoginPage"
      >
        Return to sign in
      </v-btn>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/authService";

export default {
  name: "PasswordReset",
  components: {},
  data() {
    return {
      passwordResetEmailSubmitted: false,
      email: null,
    };
  },
  methods: {
    redirectToLoginPage() {
      this.$router.push("/");
    },
    resetPassword() {
      AuthService.resetPasswordEmail(this.email)
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
      this.passwordResetEmailSubmitted = true;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

/*.container.my-font {
  font-family: "Alegreya Sans";
}*/

#no-background-hover::before {
  background-color: transparent !important;
}

.remember-password-btn {
  color: #01579b;
}

.remember-password-btn:hover {
  color: black;
}
</style>

<template>
  <div style="display: flex; height: 100%;">
    <LeftPanel style="flex: 2" />
    <CenterPanel style="flex: 8" />
    <RightPanel style="flex: 0" />
  </div>
</template>

<script>
import LeftPanel from "@/components/Home/LeftPanel";
import CenterPanel from "@/components/Home/CenterPanel";
import RightPanel from "@/components/Home/RightPanel";

import { mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    LeftPanel,
    CenterPanel,
    RightPanel,
  },
  methods: {
    ...mapActions("home", ["retrieveConvos"]),
  },
  mounted() {
    this.retrieveConvos().catch((e) => {
      console.log(e);
    });
  },
};
</script>

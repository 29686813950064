<template>
  <v-card outlined style="height: 100%; display: flex; flex-direction: column">
    <v-list-subheader class="justify-center">Your Conversations</v-list-subheader>
    <template v-if="!sortedConvos.length">
      <v-card outlined style="flex: 20; position: relative;">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-card>
    </template>
    <template v-else>
      <v-virtual-scroll
        :items="sortedConvos"
        :item-height="45"
        :bench="20"
        style="
        flex: 1 1 100px;
        overflow-y: auto;
      "
      >
        <template v-slot="{ item }">
          <p style="margin-top: 100px; font-size: 20px" v-if="sortedConvos.length === 0">
            No Conversations
          </p>
          <v-list-item
            v-else
            class="ma-0 pa-0"
            :key="item.convo_id"
            @click="changeConvo(item)"
            :class="{ highlighted: convo.convo_id == item.convo_id }"
          >
            <template v-slot:prepend>
              <v-tooltip v-if="item.userMentioned > 0" location="top" text="You have an uncompleted task in this conversation with no reminder">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" class="mx-0" color="primary">
                    mdi-rhombus-medium
                  </v-icon>
                </template>
              </v-tooltip>
              <div v-else class="ml-2"></div>
            </template>
            <div>
              <v-list-item-title class="text-body-2" :style="item.unread ? 'font-weight: bold;' : ''">{{ item.convo_name }}</v-list-item-title>
            </div>
            <template v-if="item.reminders[0]">
              <v-list-item-action>
                <span style="font-size: .7rem;">{{
                  moment(item.reminders[0].reminder_date).fromNow()
                }}</span>
              </v-list-item-action>
              <v-list-item-action class="ml-1">
                <v-tooltip location="right" :text="moment(item.reminders[0].reminder_date).format('h:mmA M/D/YYYY')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-alarm</v-icon>
                  </template>
                </v-tooltip>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </template>
  </v-card>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default {
  name: "LeftPanel",
  mixins: [colorMixin],
  computed: {
    ...mapGetters("home", ["sortedConvos", "convo"]),
    ...mapGetters(["userId"]),
  },
  methods: {
    ...mapMutations("home", ["changeConvo"]),
  },
};
</script>

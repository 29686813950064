<template>
  <div>
    <b-spinner v-if="chartDataObject == null"></b-spinner>
    <div v-else>
      <b-row class="align-right">
        <b-col cols="2">
        </b-col>
        <b-col cols="8" align-self="end">
          <ChartTitle
            v-bind:title="chartParams.title"
            v-bind:aggregateLevel="aggregateLevel"
            @aggregate-level-changed="aggregateLevelChanged($event)"
          />
        </b-col>
        <b-col cols="2">
          <Settings
            v-bind:sections="settings"
            :updateFilter="toggleSettings"
            @groupByChanged="groupByChanged($event)"
            @filterChanged="filterChanged($event)"
            @measureChanged="measureChanged($event)"
          />
        </b-col>
      </b-row>
      <LineChart
        v-bind:chart-data="chartDataObject"
        v-bind:options="chartOptionsObject"
      />
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/VPI/LineChart";
import Settings from "@/components/VPI/Settings";
import ChartTitle from "@/components/VPI/ChartTitle";
import chartInfoMixin from "@/mixins/chartInfoMixin";
import chartToolsMixin from "@/mixins/chartToolsMixin";
import VPIDataService from "@/services/VPIDataService";

export default {
  name: "PastPayments",
  components: {
    LineChart,
    Settings,
    ChartTitle,
  },
  mixins: [chartInfoMixin, chartToolsMixin],
  data() {
    return {
      // holds the data object for the chart
      chartDataObject: null,
      // holds the options object for the chart
      chartOptionsObject: null,
      // holds the raw data that was recieved from the last request to the server
      rawData: null,
      // the date range of the payments in the chart
      dateRange: {
        startDate: this.moment()
          .subtract(1, "months")
          .toISOString()
          .substring(0, 10),
        endDate: this.moment().toISOString().substring(0, 10),
      },
      // information used to construct the chart
      chartParams: {
        title: "Payments",
        yAxes: [], // axis data is added and removed on demand
      },
      // holds the chart's categories
      // categories are added and removed on demand
      categories: [],
      // holds the aggregate level for the appointment data
      aggregateLevel: "week",
      // holds all the possible values of the grouping options
      // used for filtering
      allGroupOptions: {
        PaymentTYpe: [],
        Provider: [],
        ClinicNum: [],
        PaymentFrom: [],
      },
      // holds what the chart payment data should be grouped by
      groupBy: "none",
      // holds what the chart payment data should be filtered by (ex: { PaymentTYpe: ["Cash", "Credit"], Provider: ["provider1"] })
      // in the example, the chart would filter out everythig but cash and credit payments to provider1
      filterBy: {},
      // The measures for displaying the data (ex: amount, total amount)
      measures: ["amount"],
      // the format for the chart settings
      settings: [
        {
          type: "drop-down",
          title: "Payments Group by",
          eventName: "groupByChanged",
          options: [
            { text: "None", value: "none" },
            { text: "Form of Payment", value: "PaymentTYpe" },
            { text: "Paid to Provider", value: "Provider" },
            { text: "Paid to Clinic", value: "ClinicNum" },
            { text: "Paid by Patient or Insurance", value: "PaymentFrom" },
          ],
          defaultConfig: "none",
        },
        {
          type: "filter-component",
          title: "Payments Filter by",
          eventName: "filterChanged",
          options: [
            { text: "Form of Payment", options: [], property: "PaymentTYpe" },
            { text: "Paid to Provider", options: [], property: "Provider" },
            { text: "Paid to Clinic", options: [], property: "ClinicNum" },
            {
              text: "Paid by Patient or Insurance",
              options: ["Patient", "Insurance"],
              property: "PaymentFrom",
            },
          ],
        },
        {
          type: "check-boxes",
          title: "Measures",
          eventName: "measureChanged",
          options: [
            { text: "Amount", value: "amount" },
            { text: "Total Amount", value: "totalAmount" },
          ],
          defaultConfig: ["amount"],
        },
      ],
      // used to signal when the settings component should be rendered
      toggleSettings: false,
    };
  },
  computed: {
    // returns the request parameters needed for requesting the chart data from the server
    requestParams() {
      return {
        type: "dailyPayments",
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      };
    },
  },
  created() {
    this.requestData();
  },
  methods: {
    requestData() {
      VPIDataService.getDailyPayments(
        this.requestParams.startDate,
        this.requestParams.endDate
      )
        .then((res) => {
          // save the response data
          this.rawData = res.data;
          // initialize the filter options
          this.updateFilters(true);
          // now insert the data into the chart
          this.insertData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    insertData() {
      this.categories = [];
      let paymentTimes = [];
      let paymentAmounts = [];

      let keys = Object.keys(this.filterBy);

      // get the payment dates and amounts ($) and create all categories
      for (let i = 0; i < this.rawData.length; i++) {
        // filter the data based on 'this.filterBy'
        let ignore = false;
        for (let j = 0; j < keys.length; j++) {
          let property = keys[j];
          if (
            this.rawData[i][property] != null &&
            !this.filterBy[property].includes(this.rawData[i][property])
          ) {
            ignore = true;
            break;
          }
        }

        if (ignore) continue;

        // get category
        let category =
          this.groupBy == "none"
            ? "All Payments"
            : this.rawData[i][this.groupBy];

        if (category == null) {
          console.log(
            "Notice: " + this.groupBy + " is null on " + this.rawData[i].PmtDate
          );
        } else {
          let categoryIndex = this.categories.indexOf(category);

          // create new category if necessary
          if (categoryIndex == -1) {
            this.categories.push(category);
            categoryIndex = this.categories.indexOf(category);
          }

          if (paymentTimes[categoryIndex] == null) {
            paymentTimes[categoryIndex] = [];
            paymentAmounts[categoryIndex] = [];
          }
          paymentTimes[categoryIndex].push(this.rawData[i].PmtDate);
          paymentAmounts[categoryIndex].push(this.rawData[i].TotalAMt);
        }
      }

      let chartData = [];
      for (let i = 0; i < this.categories.length; i++) {
        chartData[i] = this.aggregateData(
          paymentTimes[i],
          this.aggregateLevel,
          this.dateRange,
          true,
          paymentAmounts[i]
        );
      }

      // these will contain the additional categories/chartData (if any) that may result from
      // one or more measure being changed
      let additionalCategories = [];
      let additionalChartData = [];

      this.chartParams.yAxes = [];

      // change the yAxes data, categories, and chartData based on the selected measures
      if (this.measures.includes("amount")) {
        this.chartParams.yAxes.push({ side: "left", label: "", type: "money" });
      }

      // add the running total categories and data
      if (this.measures.includes("totalAmount")) {
        this.chartParams.yAxes.push({
          side: "right",
          label: "",
          type: "money",
        });

        // create the additional categories
        for (let i = 0; i < this.categories.length; i++)
          additionalCategories[i] = this.categories[i] + " Running Total";

        // calculate running total for each category
        for (let i = 0; i < chartData.length; i++)
          additionalChartData[i] = this.getRunningTotal(chartData[i]);
      }

      // remove the normal categories and data if needed.
      // this statement must come after this.categories and chartData
      // are done being used for the running totals calculation
      if (!this.measures.includes("amount")) {
        this.categories = [];
        chartData = [];
      }

      // add the additional categories and running total data to the chart
      this.categories = this.categories.concat(additionalCategories);
      chartData = chartData.concat(additionalChartData);

      // create the options object for the chart based on this.chartParams
      this.chartOptionsObject = this.constructOptionsTime(
        this.chartParams.yAxes,
        {
          min: new Date(
            this.moment(this.dateRange.startDate)
              .startOf(this.aggregateLevel)
              .toISOString()
          ),
          max: new Date(
            this.moment(this.dateRange.endDate)
              .startOf(this.aggregateLevel)
              .toISOString()
          ),
        },
        this.aggregateLevel
      );

      // create the data objects for the chart
      this.chartDataObject = this.constructData(
        this.categories,
        chartData,
        null,
        null
      );
    },
    // updates the filtering options in the settings
    // if init is true, initializes 'this.filterBy'
    // should only be run when the date range changes and at chart initialization
    updateFilters(init) {
      // format the options in the filter-component of 'this.settings'
      for (let i = 0; i < this.settings.length; i++) {
        if (this.settings[i].type == "filter-component") {
          for (let j = 0; j < 4; j++) {
            let property = this.settings[i].options[j].property;
            let categories = this.getCategories(property);
            // set the filtering options
            this.settings[i].options[j].options = categories;
            if (init) this.filterBy[property] = categories;
          }
        }
      }
      this.toggleSettings = !this.toggleSettings;
    },
    getCategories(groupBy) {
      if (groupBy == "none") return ["All Payments"];

      let categories = [];
      // find all categories
      for (let i = 0; i < this.rawData.length; i++) {
        // get category based on the data
        let category = this.rawData[i][groupBy];

        // add category if it is new
        if (category != null && categories.indexOf(category) == -1)
          categories.push(category);
      }
      return categories;
    },
    async aggregateLevelChanged(newValue) {
      this.aggregateLevel = await newValue;
      this.insertData();
    },
    async measureChanged(newValue) {
      this.measures = await newValue;
      this.insertData();
    },
    async groupByChanged(newValue) {
      this.groupBy = await newValue;
      this.insertData();
    },
    async filterChanged(newValue) {
      this.filterBy = await newValue;
      this.insertData();
    },
    async updateRange(newRange) {
      this.dateRange = await {
        startDate: this.moment(newRange.startDate)
          .toISOString()
          .substring(0, 10),
        endDate: this.moment(newRange.endDate).toISOString().substring(0, 10),
      };
      this.requestData();
    },
  },
};
</script>

<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div style="display: flex; justify-content: space-around;" class="mt-2">
      <v-badge :model-value="nowRecalls.some((c) => c.convo.unread)" dot overlap>
        <v-tooltip location="right" text="Now">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              style="flex: 1 1 1;"
              icon
              @click="selectedTab = 'now'"
              v-bind:color="selectedTab === 'now' ? 'primary' : ''"
            >
              <v-icon>mdi-calendar-alert</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-badge>
      <v-badge :model-value="laterRecalls.some((c) => c.convo.unread)" dot overlap>
        <v-tooltip location="right" text="Later">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              style="flex: 1 1 1;"
              icon
              @click="selectedTab = 'later'"
              v-bind:color="selectedTab === 'later' ? 'primary' : ''"
            >
              <v-icon>mdi-calendar-blank-outline</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-badge>
    </div>
    <v-divider class="my-1" />
    <v-progress-linear v-if="loading" indeterminate />
    <div v-if="!loading && !displayedRecalls.length" class="text-caption text-center" style="color: #757575">
      No Conversations Here
    </div>
    <RecycleScroller
      v-else
      :items="displayedRecalls"
      :item-size="35"
      key-field="DSSRecallID"
      v-slot="{ item, index }"
      style="flex: 1 1 0; overflow-y: auto;"
    >
      <v-hover v-slot="{ hover }">
        <div
          @click="changeRecall(item)"
          :class="{
            highlighted: recall.DSSRecallID === item.DSSRecallID,
            hovered: hover && recall.DSSRecallID !== item.DSSRecallID,
          }"
          :style="index === participatingIndex && selectedTab !== 'now' ? 'border-top: 1px solid #777777;' : ''"
          style="display: flex; justify-content: space-between; align-items: center; height: 35px;"
          class="unselectable"
        >
          <div
            style="flex: 1 1 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            class="mx-2 text-body-2"
            :class="item.convo.unread ? 'font-weight-bold' : ''"
          >
            <v-tooltip v-if="item.convo.userMentioned > 0 && !item.convo.userReminder"
              location="top"
              text="You have an uncompleted task in this conversation with no reminder"
            >
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" color="primary">
                  mdi-rhombus-medium
                </v-icon>
              </template>
            </v-tooltip>
            {{ item.convo.convo_name }}
          </div>
          <div v-if="item.convo.userReminder" style="flex: 1 1 1; min-width: max-content;" class="mr-1">
            <v-tooltip location="right" :text="moment(item.convo.userReminder.reminder_date).format('h:mmA M/D/YYYY')">
              <template v-slot:activator="{ props }">
                <div v-bind="props" style="font-size: .7rem;">
                  {{ fromNow(item.convo.userReminder.reminder_date) }}
                  <v-icon>mdi-alarm</v-icon>
                </div>
              </template>
            </v-tooltip>
          </div>
          <div v-else-if="selectedTab !== 'now' && !item.userParticipant">
            <v-tooltip location="right" text="You are not a participant of this conversation">
              <template v-slot:activator="{ props }">
                <div v-bind="props">
                  <v-icon>mdi-account-off</v-icon>
                </div>
              </template>
            </v-tooltip>
          </div>
        </div>
      </v-hover>
    </RecycleScroller>
  </div>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import momentMixin from "@/mixins/momentMixin";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default {
  name: "LeftPanel",
  mixins: [colorMixin, momentMixin],
  data() {
    return {
      // the current tab selected
      selectedTab: "now",
    };
  },
  computed: {
    ...mapGetters("recall", ["recallsWithOpenConvos", "recall", "loading"]),
    ...mapGetters(["userId"]),
    nowRecalls: function() {
      return this.recallsWithOpenConvos.filter((r) => {
        // all conversations with reminders after today
        if (r.convo.userReminder && this.moment(r.convo.userReminder.reminder_date).isAfter(this.moment(), "day"))
          return false;
        // all conversations where the user is not a participant
        if (!r.convo.convo_participants.some((p) => p.user_id === this.userId)) return false;
        // otherwise
        return true;
      });
    },
    laterRecalls: function() {
      return this.recallsWithOpenConvos
        .filter((r) => {
          // all conversations with reminders after today
          if (r.convo.userReminder && this.moment(r.convo.userReminder.reminder_date).isAfter(this.moment(), "day"))
            return true;
          // all conversations where the user is not a participant
          if (!r.convo.convo_participants.some((p) => p.user_id === this.userId)) return true;
          // otherwise
          return false;
        })
        .map((r) => {
          r.userParticipant = r.convo.convo_participants.some((p) => p.user_id === this.userId);
          return r;
        });
    },
    // the recalls to display in the current tab
    displayedRecalls: function() {
      return this.selectedTab === "now" ? this.nowRecalls : this.laterRecalls;
    },
    participatingIndex: function() {
      return this.laterRecalls.findIndex((c) => !c.userParticipant);
    },
  },
  methods: {
    ...mapMutations("recall", ["changeRecall"]),
  },
};
</script>

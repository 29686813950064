// This mixin contains the base data and options used to construct every chart
export default {
    data() {
        return {
            // these are all the possible colors for the categories
            allColors: [
                '#59a14f', '#4e79a7', '#9c755f', '#f28e2b', '#e15759', '#76b7b2', '#b07aa1', '#bab0ac', '#edc948', '#ff9da7'
            ]
        }
    },
    methods: {
        // constructs a chart js options object with time on the x-axis
        // 'yAxes' is in the form [{side: "", label: "", type: "" }, ...]
        // - the side property must be "left" or "right" and the type property must be "number" or "money"
        // 'xRange' must be in the form {min: , max:}
        // 'aggregateLevel' is "hour", "day", "week", "month", etc...
        constructOptionsTime(yAxes, xRange, aggregateLevel) {
            let yAxesObjects = [];

            // for each y-axis, create the yAxesObject
            for (let i = 0; i < yAxes.length; i++) {
                yAxesObjects[i] = {
                    id: yAxes[i].side,
                    position: yAxes[i].side,
                    ticks: {
                        beginAtZero: true,
                        callback: function (value) {
                            if (yAxes[i].type == "money")
                                return "$" + (value / 1000) + "k"
                            else
                                return value
                        }
                    },
                    scaleLabel: {
                        display: true,
                        labelString: yAxes[i].label
                    }
                };
            }

            return {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: false,
                },
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        usePointStyle: true
                    }
                },
                scales: {
                    yAxes: yAxesObjects,
                    xAxes: [{
                        type: 'time',
                        display: true,
                        time: {
                            unit: aggregateLevel,
                        }
                    }]
                },
                animation: {
                    duration: 0
                },
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                            // rangeMin: {
                            //     x: xRange.min
                            // },
                            // rangeMax: {
                            //     x: xRange.max
                            // }
                        },
                        zoom: {
                            enabled: true,
                            mode: 'x',
                            // rangeMin: {
                            //     x: xRange.min
                            // },
                            // rangeMax: {
                            //     x: xRange.max
                            // }
                        }
                    }
                }
            };
        },
        // constructs a chart js options object with a set x-axis (no zoom/pan)
        constructOptionsSet(yLabel) {
            return {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: false,
                },
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        usePointStyle: true
                    }
                },
                scales: {
                    yAxes: [{
                        id: "left",
                        ticks: {
                            beginAtZero: true,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: yLabel
                        }
                    }],
                    xAxes: [{
                        display: true,
                    }]
                },
                animation: {
                    duration: 0
                },
            };
        },
        // constructs all the data objects for one chart
        // the categories and data arrays should be the same length
        // if using a time x-axis, 'labels' should be null
        // if some lines should be hidden, 'hideLine' should be an array of booleans,
        // where if (hideLines[i] == true) then the line of the category at index 'i' should be hidden
        // otherwise, hideLine can be null
        constructData(categories, data, labels, hideLine) {
            var datasets = [];

            // construct the dataset for each category
            for (let i = 0; i < categories.length; i++)
                datasets[i] = this.constructDataset(categories[i], data[i], hideLine == null ? false : hideLine[i], i);

            return {
                labels: labels,
                datasets: datasets
            };
        },
        // constructs the dataset object of one category for a chart
        // if 'hideLine' is true, the line will be hidden
        constructDataset(category, data, hideLine, colorIndex) {
            let color = this.allColors[colorIndex]
            return {
                showLine: !hideLine,
                label: category,
                borderColor: color,
                backgroundColor: color,
                fill: false,
                pointBorderColor: color,
                pointBackgroundColor: color,
                pointRadius: hideLine ? 6 : 3,
                pointHoverRadius: 8,
                pointHitRadius: 6,
                pointBorderWidth: 2,
                pointStyle: hideLine ? 'rectRot' : 'rectRounded',
                lineTension: .3,
                yAxisID: (typeof category === 'string' && category.toLowerCase().includes("running")) ? 'right' : 'left',
                data: data,
            };
        }
    }
}
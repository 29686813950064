<template>
  <v-data-table
    :headers="headers"
    :items="client_items"
    :items-per-page-options="[
      { value: 5, title: '5' },
      { value: 10, title: '10' },
      { value: 15, title: '15' },
      { value: 20, title: '20' },
    ]"
    item-value="client_id"
    :sort-by="[{ key: 'client_id', order: 'desc' }]"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Current Clients</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn dark class="mb-2" @click="addNew">
          Onboard Client
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.name="{ item }">
      <TableEditField
        v-if="item.client_id === editedItem.client_id"
        :value="editedItem.name"
        ref="name_field"
        :rules="officeNameRules"
        @updated="(val) => (editedItem.name = val)"
      />
      <span v-else>{{ item.name }}</span>
    </template>
    <template v-slot:item.timezone="{ item }">
      <TableEditSelect
        v-if="item.client_id === editedItem.client_id"
        :value="editedItem.timezone"
        @updated="(val) => (editedItem.timezone = val)"
        width="150"
        ref="timezone_field"
        :items="timezones"
        :rules="timeZoneRules"
      />
      <span v-else>{{ item.timezone }}</span>
    </template>
    <template v-slot:item.business_number="{ item }">
      <TableEditField
        v-if="item.client_id === editedItem.client_id"
        :value="editedItem.business_number"
        ref="num_field"
        :rules="phoneNumberRules"
        width="130"
        @updated="(val) => (editedItem.business_number = val)"
      />
      <span v-else>{{ phoneFormat(item.business_number) }}</span>
    </template>
    <template v-slot:item.address_line_1="{ item }">
      <TableEditField
        v-if="item.client_id === editedItem.client_id"
        :value="editedItem.address_line_1"
        ref="address_field"
        :rules="officeAddressRules"
        @updated="(val) => (editedItem.address_line_1 = val)"
      />
      <span v-else>{{ item.address_line_1 }}</span>
    </template>
    <template v-slot:item.address_line_2="{ item }">
      <TableEditField
        v-if="item.client_id === editedItem.client_id"
        :value="editedItem.address_line_2"
        ref="address_field_2"
        :rules="[]"
        width="40"
        :required="false"
        @updated="(val) => (editedItem.address_line_2 = val)"
      />
      <span v-else>{{ item.address_line_2 }}</span>
    </template>
    <template v-slot:item.city="{ item }">
      <TableEditField
        v-if="item.client_id === editedItem.client_id"
        :value="editedItem.city"
        ref="city_field"
        :rules="officeCityRules"
        width="150"
        @updated="(val) => (editedItem.city = val)"
      />
      <span v-else>{{ item.city }}</span>
    </template>
    <template v-slot:item.state="{ item }">
      <TableEditSelect
        v-if="item.client_id === editedItem.client_id"
        :value="editedItem.state"
        @updated="(val) => (editedItem.state = val)"
        width="170"
        ref="state_field"
        :items="states"
        :rules="officeStateRules"
      />
      <span v-else>{{ item.state }}</span>
    </template>
    <template v-slot:item.zip="{ item }">
      <TableEditField
        v-if="item.client_id === editedItem.client_id"
        :value="editedItem.zip"
        ref="zip_field"
        :rules="officeZipRules"
        width="70"
        @updated="(val) => (editedItem.zip = val)"
      />
      <span v-else>{{ item.zip }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <div v-if="item.client_id === editedItem.client_id">
        <v-tooltip location="bottom" text="Cancel">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="mr-2"
              size="small"
              @click="cancelEdit"
            >
              mdi-cancel
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="bottom" text="Save">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="mr-2"
              size="small"
              @click="createClientClick"
            >
              mdi-content-save
            </v-icon>
          </template>
        </v-tooltip>
      </div>
      <div v-else>
        <v-tooltip location="bottom" text="Endpoints">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="mr-2"
              size="small"
              @click="
                router.push({
                  path: `/clientendpoints/${item.client_id}`,
                })
              "
            >
              mdi-account
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="bottom" text="Phones">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="mr-2"
              size="small"
              @click="
                router.push({ path: `/clientphones/${item.client_id}` })
              "
            >
              mdi-phone
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="bottom" text="Edit">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="mr-2"
              size="small"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="bottom" text="Delete">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" size="small" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>


<script>
import AdminService from "@/services/adminService";
import router from "@/router";
import EventBus from "@/eventBus";
import { phoneFormat, states } from "@/util/util";
import TableEditField from "../components/CustomComponents/TableEditField.vue";
import TableEditSelect from "../components/CustomComponents/TableEditSelect.vue";

export default {
  components: { TableEditField, TableEditSelect },
  data: () => ({
    router,
    states,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        title: "Client ID",
        align: "start",
        sortable: true,
        key: "client_id",
      },
      { title: "Office Name", key: "name" },
      { title: "Time Zone", key: "timezone" },
      { title: "Business Number", key: "business_number" },
      { title: "Address Line 1", key: "address_line_1" },
      { title: "Address Line 2", key: "address_line_2", sortable: false },
      { title: "City", key: "city" },
      { title: "State", key: "state" },
      { title: "Zip Code", key: "zip" },
      { title: "Actions", key: "actions", sortable: false },
    ],
    client_items: [],
    createdObj: false,
    editedIndex: -1,
    editedItem: {
      client_id: 0,
      name: "",
      timezone: "",
      business_number: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip: "",
    },
    defaultItem: {
      client_id: 0,
      name: "",
      timezone: "",
      business_number: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip: "",
    },
    officeNameRules: [],
    officeAddressRules: [],
    officeCityRules: [],
    officeStateRules: [],
    officeZipRules: [],
    phoneNumberRules: [],
    timeZoneRules: [],
    timezones: [
      "Eastern",
      "Central",
      "Mountain",
      "Pacific",
      "Alaska",
      "Hawaii",
    ],
    rules: {
      required: (value) => !!value || "Required.",
      phone: (value) => {
        const pattern = /^\d{10}$/;
        return pattern.test(value) || "Invalid phone number.";
      },
      city: (value) => {
        const pattern = /^[a-zA-Z ]+$/;
        return pattern.test(value) || "Invalid city name.";
      },
      zipCode: (value) => {
        const pattern = /^\d{5}$/;
        return pattern.test(value) || "Must be a 5 digit zip code";
      },
      officeName: (value) => {
        return (
          value.toLowerCase() != "dss team" || "Cannot use that office name."
        );
      },
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    phoneFormat,
    async initialize() {
      let client_list = await AdminService.getClients();
      this.client_items = client_list.data;
    },

    editItem(item) {
      this.editedIndex = this.client_items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    cancelEdit() {
      if (this.editedItem.client_id == 0) {
        this.client_items.splice(this.editedIndex, 1);
        this.createdObj = false;
      }
      this.close();
    },

    addNew() {
      if (!this.createdObj) {
        this.createdObj = true;
        const addObj = Object.assign({}, this.defaultItem);
        this.client_items.unshift(addObj);
        this.editItem(addObj);
      }
    },

    deleteItem(item) {
      this.editedIndex = this.client_items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await AdminService.deleteClient(
        this.client_items[this.editedIndex].client_id
      );
      this.client_items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedItem.client_id == 0) {
        const data = {
          officeName: this.editedItem.name,
          officeAddressLine1: this.editedItem.address_line_1,
          officeAddressLine2: this.editedItem.address_line_2,
          officeCity: this.editedItem.city,
          officeState: this.editedItem.state,
          officeZip: this.editedItem.zip,
          businessNumber: this.editedItem.business_number,
          timeZone: this.editedItem.timezone,
        };
        let res = await AdminService.createNewClient(data);
        console.log(res);
        this.editedItem.client_id = res.data.client_id;
        this.createdObj = false;
      } else {
        await AdminService.updateClient(
          this.client_items[this.editedIndex].client_id,
          this.editedItem
        );
      }
      Object.assign(this.client_items[this.editedIndex], this.editedItem);
      this.close();
    },

    createClientClick() {
      const phoneExists = !!this.editedItem.business_number;

      if (phoneExists) {
        this.editedItem.business_number.replace(/\D/g, "");
        if (this.editedItem.business_number.length == 11)
          this.editedItem.business_number =
            this.editedItem.business_number.slice(1);
      }
      const phonePattern = /^\d{10}$/;
      const zipPattern = /^\d{5}$/;
      const cityPattern = /^[a-zA-Z ]+$/;

      if (
        !!this.editedItem.name &&
        !!this.editedItem.address_line_1 &&
        !!this.editedItem.city &&
        !!this.editedItem.state &&
        !!this.editedItem.zip &&
        phoneExists &&
        !!this.editedItem.timezone &&
        zipPattern.test(this.editedItem.zip) &&
        cityPattern.test(this.editedItem.city) &&
        phonePattern.test(this.editedItem.business_number) &&
        this.editedItem.name.toLowerCase() != "Team DSS"
      ) {
        this.save();
      } else {
        EventBus.$emit("alert", "Failed to save, invalid data given.");
        this.officeNameRules = [this.rules.required, this.rules.officeName];
        this.officeAddressRules = [this.rules.required];
        this.officeCityRules = [this.rules.required, this.rules.city];
        this.officeStateRules = [this.rules.required];
        this.officeZipRules = [this.rules.required, this.rules.zipCode];
        this.timeZoneRules = [this.rules.required];
        this.phoneNumberRules = [this.rules.required, this.rules.phone];
      }
    },
  },
};
</script>
<template>
  <div
    class="pa-2"
    style="
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 250px;
    "
  >
    <div class="answer-btn icon-align">
      <v-btn color="green" variant="text" style="width: 25px; height: 25px;" icon @click="answer">
        <v-icon color="white" size="x-small">mdi-phone</v-icon>
      </v-btn>
    </div>
    <div
      style="
        text-align: center;
        flex: 7;
        word-wrap: break-word;
        overflow: hidden;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <p>{{ inboundCallerDisplayTitle }}</p>
    </div>
    <div class="ignore-btn icon-align">
      <v-btn variant="text" style="width: 25px; height: 25px;" @click="ignore">
        <v-icon color="white" size="small">mdi-phone-hangup</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import PlivoService from "@/services/plivoService";
import { mapGetters } from "vuex";

export default {
  name: "IncomingCallDisplay",
  components: {},
  props: {
    inboundCallerDisplayTitle: String,
    inboundCallUUID: String,
  },
  computed: {
    ...mapGetters("plivo", ["getCurrConferenceName"]),
  },
  methods: {
    async answer() {
      // check if the user is already in a call
      if (this.getCurrConferenceName) await PlivoService.transferUser();

      this.plivoBrowserSdk.client.answer(this.inboundCallUUID, "letring");
    },

    ignore() {
      // Ignoring the call and emitting an event to the 'CallBar' signaling that we have ignored the call.
      this.plivoBrowserSdk.client.ignore(this.inboundCallUUID);
      this.$emit("incomingCallIgnored");
    },
  },
};
</script>

<style scoped>
.icon-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer-btn {
  background: green;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  animation: answer-btn-animation 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes answer-btn-animation {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 5px rgba(108, 173, 69, 0.4);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(17, 194, 62, 0.4), 0 0 0 20px rgba(23, 173, 31, 0.2);
  }
  25% {
    box-shadow: 0 0 0 15px rgba(12, 235, 112, 0.4), 0 0 0 30px rgba(24, 168, 43, 0.2);
  }
}

.ignore-btn {
  background: #f8302c;
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  animation: ignore-btn-animation 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes ignore-btn-animation {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 5px rgba(202, 28, 28, 0.4);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(228, 19, 19, 0.4), 0 0 0 20px rgba(196, 33, 12, 0.2);
  }
  25% {
    box-shadow: 0 0 0 15px rgba(221, 111, 111, 0.295), 0 0 0 30px rgba(206, 136, 136, 0.644);
  }
}
</style>

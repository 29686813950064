export function phoneFormat(number) {
    return phoneFormatTitle(number);
    //return "(" + number.substring(0,3) + ") " + number.substring(3, 6) + "-" + number.substring(6);
}

export function phoneFormatTitle(number) {
    const pattern = /^\d{10}$/;
    if(pattern.test(number))
        return "(" + number.substring(0,3) + ") " + number.substring(3, 6) + "-" + number.substring(6);
    else return number;
}

export const states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];

export function designationToList(bits) {
    let str = "";
    for(let i = 0; i < 10; i++) {
        if(bits % 2 == 1) {
            str += (i + ",");
        }
        bits = bits >> 1;
    }

    return str.substring(0, str.length - 1)
}

export function designationToArray(bits) {
    let arr = [];
    for(let i = 0; i < 10; i++) {
        if(bits % 2 == 1) {
            arr.push(i);
        }
        bits = bits >> 1;
    }

    return arr;
}

export function displayTime(timeStamp) {
    const date = new Date(timeStamp);

    let hours = date.getHours();
    if(hours > 12) hours = hours - 12;

    let minutes = date.getMinutes();
    return hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + (hours > 11 ? 'PM' : 'AM');
}
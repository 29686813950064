<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
    <v-progress-linear v-if="loading" indeterminate />
    <div v-if="!loading && !recalls.length" class="text-caption text-center" style="color: #757575">
      No Recalls Found
    </div>
    <div style="flex: 1 1 1; height: 100%;">
      <!--SplitGrid direction="row" style="height: 100%;" :gutterSize="1" :rowMinSize="100">
        <SplitGridArea sizeUnit="fr" :sizeValue="70" style="display: flex; flex-direction: column;">
          <RecycleScroller
            :items="recalls"
            :item-size="50"
            key-field="DSSRecallID"
            v-slot="{ item }"
            style="flex: 1 1 0; overflow-y: auto;"
          >
            <v-hover v-slot="{ hover }">
              <div
                @click="changeRecall(item)"
                :class="{
                  highlighted: recall.DSSRecallID === item.DSSRecallID,
                  hovered: hover && recall.DSSRecallID !== item.DSSRecallID,
                }"
                style="display: flex; justify-content: space-between; align-items: center; height: 50px;"
                class="unselectable"
              >
                <div
                  style="flex: 1 1 1; display: flex; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  class="ml-1"
                >
                  <ContactPopup style="flex: 1 1 1;" :contact_id="item.contact_id" />
                  <div style="flex: 1 1 1; display: flex; flex-direction: column;" class="ml-3">
                    <div style="flex: 1 1 1;" class="text-body-1">{{ item.FName + " " + item.LName }}</div>
                    <div style="flex: 1 1 1; color: #757575;" class="text-caption">
                      {{ "Due: " + item.DueDate + ", Last contacted: " + item.lastContacted }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="hover || recall.DSSRecallID === item.DSSRecallID"
                  style="flex: 1 1 1; min-width: max-content; display: flex;"
                  class="mr-3"
                >
                  <div style="flex: 1 1 1;">
                    <v-btn
                      v-if="item.WirelessPhNum"
                      icon
                      size="small"
                      @click="phoneCall(item.FName + ' ' + item.LName, item.WirelessPhNum)"
                    >
                      <v-icon>mdi-cellphone</v-icon>
                    </v-btn>
                  </div>
                  <div style="flex: 1 1 1;">
                    <v-btn
                      v-if="item.HmPhNum"
                      icon
                      size="small"
                      @click="phoneCall(item.FName + ' ' + item.LName, item.HmPhNum)"
                    >
                      <v-icon>mdi-home</v-icon>
                    </v-btn>
                  </div>
                  <div style="flex: 1 1 1;">
                    <v-btn
                      v-if="item.WkPhNum"
                      icon
                      size="small"
                      @click="phoneCall(item.FName + ' ' + item.LName, item.WkPhNum)"
                    >
                      <v-icon>mdi-briefcase</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-hover>
          </RecycleScroller>
        </SplitGridArea>
        <SplitGridGutter />
        <SplitGridArea v-if="appointments" sizeUnit="fr" :sizeValue="30" style="display: flex; flex-direction: column;">
          <v-data-table
            style="flex: 1 1 0; overflow-y: auto;"
            dense
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="appointments"
            item-key="AptNum"
          >
            <template v-slot:[`item.AptDateTime`]="{ item }">
              {{ moment(item.AptDateTime).format("M/D/YYYY h:mmA") }}
            </template>
          </v-data-table>
        </SplitGridArea>
      </SplitGrid -->
    </div>
  </div>
</template>

<script>
//import { SplitGrid, SplitGridArea, SplitGridGutter } from "vue-split-grid";
import eventBus from "@/eventBus";
import MiscService from "@/services/miscService";
import ContactPopup from "@/components/Avatar/ContactPopup";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default {
  name: "RightPanel",
  components: { ContactPopup },
  data() {
    return {
      appointments: null,
      // For the v-data-table of appointments
      headers: [
        { text: "Appointment Date", value: "AptDateTime" },
        { text: "Procedures", value: "ProcDescript" },
      ],
    };
  },
  computed: {
    ...mapGetters("recall", ["recalls", "recall", "loading"]),
  },
  watch: {
    recall() {
      this.getAppointments();
    },
    // used for manually updating the icons in the datatable
    carrierClaims() {
      this.tableKey += 1;
    },
  },
  created() {
    if (this.recall) this.getAppointments();
  },
  methods: {
    ...mapMutations("recall", ["changeRecall"]),
    phoneCall(name, number) {
      eventBus.$emit("call-event", { name, number });
    },
    getAppointments() {
      MiscService.getAppointments(this.recall.contact_id)
        .then((res) => {
          this.appointments = res.data;
        })
        .catch((err) => {
          eventBus.$emit("alert", "Error fetching appointments");
          console.error(err);
        });
    },
  },
};
</script>

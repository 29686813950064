<template>
  <div class="container">
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm";
import router from "@/router";
import { mapState } from "vuex";
import store from "@/store"

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  computed: {
    ...mapState(["validUser"]),
  },
  created: function() {
    console.log('Inside login created -store', store.getters)
    if (store.getters.loggedIn) router.push({ path: "frontdesk" });
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>

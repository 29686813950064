<template>
  <div
    v-if="getConferences[getCurrConferenceName]"
    class="pa-2"
    style="
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 320px;
    "
  >
    <!-- Conditional display that will be shown when there are conferences on hold. -->
    <v-menu
      v-if="
        Object.keys(getConferences).length > 1 || (getCurrConferenceName && getCurrConferenceName.startsWith('temp'))
      "
      tile
      z-index="5"
      nudge-bottom="20"
      nudge-right="30"
      right
      transition="slide-y-transition"
      offset-y
      rounded
      :close-on-content-click="false"
      :value="showOtherCalls"
    >
      <template v-slot:activator="{ props }">
        <v-badge
          :model-value="!showOtherCalls"
          overlap
          color="green lighten-2"
          :content="numDropdownConferences"
          class="mr-4"
        >
          <v-btn
            v-bind="props"
            @click="toggleShowOtherCalls()"
            icon
            height="27"
            width="27"
            :outlined="showOtherCalls"
            color="grey darken-1"
          >
            <v-icon v-if="showOtherCalls" size="18">expand_less</v-icon>
            <v-icon v-else size="18">mdi-phone</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <v-list
        v-for="conferenceName in Object.keys(getConferences)"
        :key="conferenceName"
        color="grey lighten-4"
        class="pa-0"
        style="width: 250px"
      >
        <!-- Only showing in the dropdown menu the conferences that are not the currently selected conference. -->
        <template v-if="conferenceName !== getCurrConferenceName">
          <v-list-item
            @click="resumeConference(conferenceName)"
            class="pa-0"
            style="
              outline: 1px solid #757575;
              height: 75px;
              display: flex;
              justify-content: space-between;
            "
          >
            <!-- List of avatar circles (one for each participant, except the current browser user). -->
            <!-- THIS CODE HERE IS REDUNDANT. WE CAN MAKE THIS CLEANER! -->
            <div
              style="
                display: flex;
                flex-direction: row-reverse;
                flex-wrap: nowrap;
                justify-content: end;
                align-items: center;
              "
              :style="getConferences[conferenceName].length > 3 ? 'margin-left: 12px ' : 'margin-left: 19px'"
            >
              <!-- Key is participant.type + participant.id to ensure that the key is always unique -->
              <div
                v-for="participant in getConferences[conferenceName]"
                :key="participant.type + participant.id"
                style="flex: 1 1 1; margin-left: -8px"
              >
                <!-- If the participant is not the current user in the browser, then only will we display their avatar. -->
                <template v-if="participant.type === 'contact' || participant.id !== userId">
                  <!-- Case 1: Unknown number -->
                  <UnknownPopup
                    v-if="!participant.id"
                    :phoneNum="participant.displayTitle"
                    :dense="getConferences[conferenceName].length > 2"
                  />
                  <!-- Case 2: User -->
                  <UserPopup
                    v-else-if="participant.type === 'user'"
                    :user_id="participant.id"
                    :dense="getConferences[conferenceName].length > 2"
                  />
                  <!-- Case 3: Contact -->
                  <ContactPopup
                    v-else
                    :contact_id="participant.id"
                    :dense="getConferences[conferenceName].length > 2"
                  />
                </template>
              </div>
            </div>
            <!-- END OF THE REDUNDANT CODE. -->

            <!-- Conference title and hold time display. -->
            <div
              style="
                width: 125px;
                max-width: 145px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 70px;
                text-align: center;
                margin-left: 5px;
              "
              class="pa-2"
            >
              <span style="font-size: 13px">
                {{ calculateConferenceDisplayTitle(conferenceName) }}
              </span>
              <!--<span v-if="timeDisplays[conference.name]" class="grey--text text--darken-2" style="font-size: 12px">-->
              <!-- on hold for {{ timeDisplays[conference.name][3] }} -->
              <!--{{ timeDisplays[conference.name][1] }}-->
              <!-- FOR NOW WILL USE CALL TIME INSTEAD OF HOLD TIME HERE! -->
              <!--</span>-->
              <span class="grey--text text--darken-2" style="font-size: 12px">
                time unavailable
              </span>
            </div>
            <div>
              <v-btn height="25" width="25" fab class="mr-1" color="grey lighten-2" @click="mergeCall($event, conferenceName, getCurrConferenceName)">
                <v-icon size="15">
                  mdi-call-split
                </v-icon>
              </v-btn>
            </div>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <!-- Display if no actively selected call -->
    <h6
      v-if="getCurrConferenceName && getCurrConferenceName.startsWith('temp') && Object.keys(getConferences).length > 1"
      style="margin-right: 70px; margin-top: 6px"
    >
      Select a call to resume
    </h6>

    <!-- Otherwise, there is an actively selected call, so we can show the standard in call display components. -->
    <template v-else>
      <!-- List of avatar circles (one for each participant, except the current browser user). -->
      <div
        style="
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: nowrap;
          justify-content: end;
          align-items: center;
        "
        :style="Object.keys(getConferences).length > 1 ? 'margin-left: 5px ' : 'margin-left: 10px'"
      >
        <!-- Key is participant.type + participant.id to ensure that the key is always unique,
             will later change this to just participant.plivoId, because that will be unique for each participant.  -->
        <div
          v-for="participant in getConferences[getCurrConferenceName]"
          :key="participant.type + participant.id"
          style="flex: 1 1 1; margin-left: -8px"
        >
          <!-- If the participant is not the current user in the browser, then only will we display their avatar. -->
          <template v-if="participant.type === 'contact' || participant.id !== userId">
            <!-- Case 1: Unknown number -->
            <UnknownPopup v-if="!participant.id" :phoneNum="participant.displayTitle" :dense="avatarDense" />
            <!-- Case 2: User -->
            <UserPopup v-else-if="participant.type === 'user'" :user_id="participant.id" :dense="avatarDense" />
            <!-- Case 3: Contact -->
            <ContactPopup v-else :contact_id="participant.id" :dense="avatarDense" />
          </template>
        </div>
      </div>

      <!-- Call title display and time display. -->
      <div
        style="
          flex: 6;
          text-align: center;
          word-wrap: break-word;
          overflow: hidden;
          margin-left: 14px;
          max-width: 130px;
        "
      >
        <v-tooltip :text="'Show ' + phoneFormatTitle(displayTitle) + ' Call History and Profile'" location="bottom">
          <template v-slot:activator="{ props }">
            <h6 v-bind="props" style="font-size: 13px" class="mb-0 mt-4">
              <a v-if="getCallType()" @click="populateFrontDesk"><u>{{ phoneFormatTitle(displayTitle) }}</u></a>
              <div v-else>{{ phoneFormatTitle(displayTitle) }}</div>
            </h6>
          </template>
        </v-tooltip>
        <!--<p style="font-size: 13px">
         {{ timeDisplays[getCurrConferenceName][1] }}
      </p>-->
        <p style="font-size: 13px">time unavailable</p>
      </div>

      <!-- Four buttons (mute, hold, keypad, add to call) display. -->
      <div class="ml-2" style="display: flex; flex-direction: column">
        <div style="display: flex">
          <v-btn icon variant="elevated"  height="25" width="25" density="compact" class="mr-1" color="grey lighten-2" @click="toggleMicOff()">
            <v-icon color="black" v-if="micOff" size="15" :disabled="this.messagePlaying">mdi-microphone-off</v-icon>
            <v-icon v-else color="black" size="15">mdi-microphone</v-icon>
          </v-btn>
          <!-- disabled this for now while we don't have the functionality. -->
          <v-btn icon variant="elevated"  height="25" width="25"  class="mr-1" color="grey lighten-2" disabled>
            <v-icon size="15" color="black" :disabled="messagePlaying">mdi-pause</v-icon>
          </v-btn>
        </div>
        <div class="mt-1" style="display: flex">
          <v-menu offset-y open-on-hover rounded :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn icon  variant="elevated"  v-bind="props" height="25" width="25"  class="mr-1" color="grey lighten-2">
                <v-icon color="black" size="15" :disabled="messagePlaying">mdi-dialpad</v-icon>
              </v-btn>
            </template>
            <v-card color="grey lighten-2">
              <Keypad />
            </v-card>
          </v-menu>

          <!-- Add to call button. -->
          <v-btn
            variant="elevated"
            icon
            height="25"
            width="25"
            class="mr-1"
            color="grey lighten-2"
            @click="setCallBarDisplay('addToCall')"
          >
            <v-icon color="black" size="15" :disabled="messagePlaying">mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- Hangup/leave conference button. -->
      <v-btn icon  height="35" width="35" variant="elevated"   dark class="ml-1" color="red" @click="hangup()" style="margin-right: 1px">
        <v-icon size="medium" color="white" :disabled="messagePlaying">mdi-phone-hangup</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import Keypad from "@/components/CallBar/Keypad";
import UnknownPopup from "@/components/Avatar/UnknownPopup";
import UserPopup from "@/components/Avatar/UserPopup";
import ContactPopup from "@/components/Avatar/ContactPopup";

import colorMixin from "@/mixins/colorMixin";

import PlivoService from "@/services/plivoService";

import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { phoneFormatTitle } from "@/util/util";
import EventBus from "@/eventBus";

export default {
  name: "InCallDisplay",
  components: {
    Keypad,
    UnknownPopup,
    UserPopup,
    ContactPopup,
  },
  mixins: [colorMixin],
  data() {
    return {
      showOtherCalls: false, // If this is set to true, UI will display the other calls currently on hold.
      micOff: false, // If true, the browser user is muted.

      time: "00:00",
      timeBegan: null,
      started: null,
      running: false,

      // Will be set to true while the automated message informing that the call will be recorded for quality assurance
      // purposes is playing (only ever set to true during outbound calls, because the PHLO plays the automated message
      // before the call is even received for inbound calls).
      messagePlaying: false,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    ...mapGetters("plivo", ["getConferences", "getCurrConferenceName", "getCurrCallUuid"]),

    // Computes whether we need the avatar sizes to be smaller or not. This value will be true if the
    // number of participants in the current conference is greater than two.
    avatarDense: function() {
      return this.getConferences[this.getCurrConferenceName].length > 2;
    },

    // Computes the string that should be used as the title for the currently selected call (based on the participants).
    displayTitle: function() {
      return this.calculateConferenceDisplayTitle(this.getCurrConferenceName);
    },

    // Computes the number of conferences that are in the dropdown menu (aka are not actively selected conferences).
    // The value computed here will be used as the content for the badge, which is the number of calls that are
    // ongoing but not the actively selected one by the user of the browser.
    numDropdownConferences: function() {
      /*if (this.getCurrConferenceName.startsWith("temp")) return Object.keys(this.getConferences).length -1;
      else return Object.keys(this.getConferences).length;*/
      let conferences = {...this.getConferences}
      let conferencesNameArray = Object.keys(conferences)
      if (this.getCurrConferenceName.startsWith("temp")) {
        let filteredArray = conferencesNameArray.filter(conference => !conference.startsWith('temp'))
        return filteredArray.length
      } else return conferencesNameArray.length - 1;
    },
  },
  methods: {
    ...mapMutations("plivo", ["setCallBarDisplay"]),
    phoneFormatTitle,
    // Toggles the 'showOtherCalls' variable (in the data section).
    toggleShowOtherCalls() {
      this.showOtherCalls = !this.showOtherCalls;
    },

    // Toggles the 'micOff' variable (in the data section), and mutes or unmutes the browser user.
    toggleMicOff() {
      if (this.micOff) this.plivoBrowserSdk.client.unmute();
      else this.plivoBrowserSdk.client.mute();
      this.micOff = !this.micOff;
    },

    //Merge the calls when the user clicks merge call button.
    async mergeCall(event, conferenceName, userCurrConference) {
      event.stopPropagation();
      await PlivoService.mergeCall({conferenceName: conferenceName, currRoomName: userCurrConference});
    },

    // This function takes the name of a conference (that is stored as a field) in the Vuex conferences object and
    // based on the participants of the conference, returns the appropriate display title for that conference.
    calculateConferenceDisplayTitle(name) {
      console.log("in calculateConferenceDisplayTitle");
      console.log("name", name);
      console.log("conferences", this.getConferences);
      // Getting the participants array for the conference we are trying to calculate the display title of.
      const participants = this.getConferences[name];
      console.log("participants", participants);
      const numParticipants = participants.length;
      console.log('calculateConferenceDisplayTitle - Num of Participants', numParticipants)
      // We are finding the index of the first participant in the participants array who is not the current user in the
      // browser (We don't care about the user in the browser because we only want to show in the conference display
      // title the titles of the people that the user is on call with, not the user themselves).
      {/*const isNotCurrentBrowserUser = (participant) => participant.type === "contact" || participant.id !== this.userId;*/}

      {/*const displayTitleOneIndex = participants.findIndex(isNotCurrentBrowserUser);*/}

      // Storing the 'displayTitle' of the participant whose index we just found in the previously line.
      {/*const displayTitleOne = displayTitleOneIndex < 0 ? "" : participants[displayTitleOneIndex].displayTitle;*/}
      // In the case that there are 4 or more participants in the conference, we are only going to display the title of
      // one participant and then say '& <num other people> others' (ex: Aabid Roshan & 2 others).
      {/*{if (numParticipants > 3) {
        // numParticipants - 2 since we have to subtract the browser user and the participant whose title we are
        // displaying.
        return displayTitleOne + " & " + eval(numParticipants - 2) + " others";
      } else if (numParticipants === 3) {
        console.log('Num Participant === 3, Participant', participants)
        const isNotCurrentBrowserUserAndIsNotDisplayTitleOne = (participant, index) => {
          isNotCurrentBrowserUser(participant) && index !== displayTitleOneIndex;
        };

        const displayTitleTwoIndex = participants.findIndex(isNotCurrentBrowserUserAndIsNotDisplayTitleOne);
        console.log(displayTitleTwoIndex)
        const displayTitleTwo = participants[displayTitleTwoIndex].displayTitle;

        return displayTitleOne + " & " + displayTitleTwo;
      } else {
        return displayTitleOne;
      }}*/}
      let displayTitle = (notCurrentUser) => {
        let resultArray = []
        notCurrentUser.map((participant) => {
          if(resultArray.length === 2) {
            resultArray.push('Others')
            return
          }
          console.log('calculateConferenceDisplayTitle - Display Title', participant.displayTitle)
          return resultArray.push(participant.displayTitle)
        })
        return resultArray.join(' & ')
      }

      let notCurrentUser = participants.filter(participant => participant.id !== this.userId)
      return displayTitle(notCurrentUser)
    },

    // This function takes in a conferenceName and transfers the current user in the browser
    // to the conference room specified by the parameter.
    resumeConference(conferenceName) {
      PlivoService.rejoinConference({
        callUuid: this.getCurrCallUuid,
        conferenceName: conferenceName,
      })
        .then(() => {
          this.showOtherCalls = false; // Can hide the dropdown now.
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // This function is called when the user presses the red hangup/leave button in the UI, which results in a
    // request to our server to take the necessary actions to remove the user from the current conference they are in.
    async hangup() {
      try {
        await PlivoService.leaveConference({
          conferenceName: this.getCurrConferenceName,
          callUuid: this.getCurrCallUuid,
          // Sending the number of conferences that the user is currently in so that
          // in the case that the user is another conference other than the one
          // that the user wants to leave, the server will transfer the user into a
          // temporary room until the user selects a conference to take off hold
          // (or all those conferences end before the user selects).
          numConferences: Object.keys(this.getConferences).length,
        });
      } catch (err) {
        console.log(err);
      }
    },
    populateFrontDesk() {
      let conf = this.getConferences[this.getCurrConferenceName];
      let is_contact0 = conf.length > 0 && conf[0].type == "contact" && conf[0].id != null;
      let is_contact1 = conf.length > 1 && conf[1].type == "contact" && conf[1].id != null;

      //console.log(conf);
      if(is_contact0 || is_contact1)
        EventBus.$emit("changeFrontDesk", { contact_id: is_contact0 ? conf[0].id : conf[1].id, type: 'contact' }); 
      else
        EventBus.$emit("changeFrontDesk", { contact_id:conf[0].phoneNum ? conf[0].phoneNum : conf[1].phoneNum, type: 'contact' });
    },
    getCallType() {
      let conf = this.getConferences[this.getCurrConferenceName];
      return conf[0].type != 'user' || (conf.length > 1 && conf[1].type != 'user');
    }
  },
};
</script>

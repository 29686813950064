import ConvoService from "@/services/convoService";

// this is the vuex module that stores convo data that is used by many different components
export const moduleConvos = {
  namespaced: true,
  state: {
    // convos is an object containing convo data. The convo_id is the key and the convo is the value
    // each convo contains nested information like in the structure below
    //  convos : {
    //   ..., (misc convo data from the convos table)
    //   convo_mentions [{convo_message_id, user_id}, ...],
    //   convo_participants: [{last_read_time, user_id}, ...],
    //   convo_reminders: [{reminder_for_who, reminder_date}, ...],
    //   -----------------------------------------------------------------
    //   the fields above are populated by sequelize
    //   the fields below are computed and are different for each user
    //   -----------------------------------------------------------------
    //   userMentioned: (integer indicating the number of uncompleted tasks this user has in the convo),
    //   userReminder: (a reminder object for the user corresponding to this convo),
    //   unread: (true if the user has unread messages in this convo),
    // }
    convos: {},
    internal_convos: [],
    all_contact_id:[],
    general_convos:[],
    referencedConvo:{},
  },
  getters: {
    referencedConvo(state){
      return state.referencedConvo;
    },
    general_convos(state){
      return state.general_convos;
    },
    all_contact_id(state){
      return state.all_contact_id
    },
    convos(state) {
      return state.convos;
    },
    // gets a convo by its convo_id
    convo(state) {
      return (convo_id) => state.convos[convo_id] || null;
    },
    // gets a convo's messages by its convo_id
    messages(state, getters) {
      return (convo_id) => {
        let convo = getters.convo(convo_id);
        if (convo && convo.convo_messages) return convo.convo_messages;
        return null;
      };
    },
    internal_convos(state) {
      return state.internal_convos;
    },
    // gets a convo by its convo_id
    internal_convo(state) {
      return (id) => state.internal_convos.find((c) => c.convo_id === id);
    },
    // gets whole list of convos for a contact
    contact_internal_convo(state) {
      return (contact_id) => {
        if(contact_id){
          return state.internal_convos.filter((c) => c.convo_references[0].contact_id === contact_id || c.convo_references[0].comm_participant.contact_id == contact_id);
        }
        else{
          return
        }
      };
    },
    // gets convos based on a comm
    internal_convos_on_comm(state) {
      return (comm_participant_id) => {
        return state.internal_convos.filter((c) => (c.convo_references[0].comm_participant_id === comm_participant_id));
      };
    },
    // gets convos referencing a contact only
    internal_convos_on_contact(state) {
      return (contact_id) => {
        return state.internal_convos.filter((c) => (c.convo_references[0].contact_id === contact_id));
      };
    },
    // gets a convo's messages by its convo_id
    internal_messages(state, getters) {
      return (id) => {
        let int_convo = getters.internal_convo(id);
        if (int_convo && int_convo.convo_messages) return int_convo.convo_messages;
        return null;
      };
    },
  },
  mutations: {
    changeReferencedConvo(state,value){
      state.referencedConvo = value;
    },
    setGeneralConvos(state,value){
      state.general_convos=value;
    },
    setAllContactId(state,value){
      console.log("value",value)
      state.all_contact_id = value;
    },
    convos(state, value) {
      state.convos = value;
    },
    // adds/updates the information for an entire convo
    addConvo(state, convo) {
      state.convos[convo.convo_id] = convo;
    },
    // adds/updates the information for an entire convo
    addInternalConvo(state, convo) {
      convo["unread"] = (convo.convo_participants ? convo.convo_participants[0].last_read_time < convo.messages_updated_at : true);
      convo["userMentioned"] = (convo.convo_mentions ? convo.convo_mentions.length : 0);
      state.internal_convos.unshift(convo);
    },
    setInternalConvos(state,convos){
      state.internal_convos=convos;
    },
    // just like addConvo except the computed fields are calculated for the convo
    // payload.convo specifies the convo
    // payload.user_id specifies the user_id of the logged in user
    addNewConvo(state, payload) {
      let convo = payload.convo;
      convo["userReminder"] = null;
      convo["unread"] = true;
      convo["userMentioned"] = 0;
      if (convo.convo_mentions && convo.convo_mentions.length)
        convo.userMentioned = convo.convo_mentions.reduce((a, m) => (m.user_id === payload.user_id ? a + 1 : a), 0);
      state.convos[convo.convo_id] = convo;
    },
    // adds/updates the messages of a convo
    setMessages(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      convo.convo_messages = payload.convo_messages;
    },
    setInternalMessages(state, payload) {
      let internal_convo = state.internal_convos.find((c) => c.convo_id === payload.convo_id)
      if (!internal_convo) return;
      internal_convo.convo_messages = payload.convo_messages;
    },
    // adds a message to a convo in the state
    // payload.convo_id specifies the convo
    // payload.message specifies the message (mentions should be included as a nested array)
    // payload.user_id should specify the user_id of the logged in user
    addMessage(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      if (convo.convo_messages) convo.convo_messages.push(payload.message);
      convo.userMentioned += payload.message.convo_mentions.reduce(
        (a, m) => (m.user_id === payload.user_id ? a + 1 : a),
        0
      );
      payload.message.convo_mentions.forEach((m) =>
        convo.convo_mentions.push({ convo_message_id: payload.message.convo_message_id, user_id: m.user_id })
      );
      convo.unread = true;
    },
    // updates the convo unread property
    // payload.convo_id specifies the convo
    // payload.unread specifies the value to assign to the unread property
    updateUnread(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      convo.unread = payload.unread;
    },
    updateInternalUnread(state, payload) {
      let internal_convo = state.internal_convos.find(c => c.convo_id === parseInt(payload.convo_id));
      if (!internal_convo) return;
      internal_convo.unread = payload.unread;
    },
    // updates the convos status of a convo in the state
    // payload.convo_id specifies the convo
    // payload.status specifies the status
    updateConvoStatus(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      convo["convo_status"] = payload.status;
    },
    // adds a participant to a convo in the state
    // payload.convo_id specifies the convo
    // payload.participant specifies the participant object
    addParticipant(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      convo.convo_participants.push(payload.participant);
    },
    // deletes a participant from a convo in the state
    // payload.convo_id specifies the convo
    // payload.user_id specifies the user
    deleteParticipant(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      convo.convo_participants = convo.convo_participants.filter((p) => p.user_id !== payload.user_id);
    },
    // adds a reminder to a convo in the state
    // payload.convo_id specifies the convo
    // payload.reminder specifies the reminder: { reminder_date, reminder_for_who}
    // payload.forUser should be true if the reminder is for the user logged in
    addReminder(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      convo.convo_reminders.push(payload.reminder);
      if (payload.forUser) convo["userReminder"] = payload.reminder;
    },
    // adds an internal reminder to an internal convo in the state
    // payload.convo_id specifies the convo
    // payload.reminder specifies the reminder: { reminder_date, reminder_for_who}
    // payload.forUser should be true if the reminder is for the user logged in
    addInternalReminder(state, payload) {
      let convo = state.internal_convos[payload.convo_id];
      if (!convo) return;
      convo.convo_reminders.push(payload.reminder);
      if (payload.forUser) convo["userReminder"] = payload.reminder;
    },
    // deletes a reminder from a convo in the state
    // payload.convo_id specifies the convo
    // payload.reminder_for_who specifies who the reminder is for
    // payload.forUser should be true if the reminder is for the user logged in
    deleteReminder(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      convo.convo_reminders = convo.convo_reminders.filter((r) => r.reminder_for_who !== payload.reminder_for_who);
      if (payload.forUser) convo.userReminder = null;
    },
    // deletes an internal convo reminder from the state
    // payload.convo_id specifies the convo
    // payload.reminder_for_who specifies who the reminder is for
    // payload.forUser should be true if the reminder is for the user logged in
    deleteInternalReminder(state, payload) {
      let convo = state.internal_convos[payload.convo_id];
      if (!convo) return;
      convo.convo_reminders = convo.convo_reminders.filter((r) => r.reminder_for_who !== payload.reminder_for_who);
      if (payload.forUser) convo.userReminder = null;
    },
    // updates a convo reminder in the state
    // payload.convo_id specifies the convo
    // payload.reminder_for_who specifies who the reminder is for
    // payload.reminder_date specifies the reminder date
    // payload.forUser should be true if the reminder is for the user logged in
    updateReminder(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      let reminder = convo.convo_reminders.find((r) => r.reminder_for_who === payload.reminder_for_who);
      if (!reminder) console.error("reminders not found");
      reminder.reminder_date = payload.reminder_date;
      if (payload.forUser) convo["userReminder"] = reminder;
    },
    // updates an internal convo reminder in the state
    // payload.convo_id specifies the convo
    // payload.reminder_for_who specifies who the reminder is for
    // payload.reminder_date specifies the reminder date
    // payload.forUser should be true if the reminder is for the user logged in
    updateInternalReminder(state, payload) {
      let convo = state.internal_convos[payload.convo_id];
      if (!convo) return;
      let reminder = convo.convo_reminders.find((r) => r.reminder_for_who === payload.reminder_for_who);
      if (!reminder) console.error("reminders not found");
      reminder.reminder_date = payload.reminder_date;
      if (payload.forUser) convo["userReminder"] = reminder;
    },
    // completes a mention for a message in a convo in the state
    // payload.convo_id specifies the convo
    // payload.convo_message_id specifies the message
    // payload.user_id specifies the user_id of the user that is completing the mention
    // payload.forUser should be true if the mention is for the user logged in
    completeMention(state, payload) {
      let convo = state.convos[payload.convo_id];
      if (!convo) return;
      let startSize = convo.convo_mentions.length;
      convo.convo_mentions = convo.convo_mentions.filter(
        (m) => m.convo_message_id !== payload.convo_message_id || m.user_id !== payload.user_id
      );
      if (payload.forUser) convo.userMentioned -= startSize - convo.convo_mentions.length;
      if (convo.convo_messages) {
        let message = convo.convo_messages.find((m) => m.convo_message_id === payload.convo_message_id);
        if (message) {
          message.convo_mentions = message.convo_mentions.map((m) => {
            if (m.user_id === payload.user_id) m.completed = true;
            return m;
          });
        }
      }
    },
    // adds a message to an internal convo
    // payload.convo_id specifies the convo
    // payload.message specifies the message
    // payload.user_id should specify the user_id of the logged in user
    addInternalMessage(state, payload) {
      let internal_convo = state.internal_convos.find(c => c.convo_id === parseInt(payload.convo_id));
      if (!internal_convo) return;
      if (internal_convo.convo_messages) internal_convo.convo_messages.push(payload.message);
      if(payload.message.convo_mentions) {
        internal_convo.userMentioned += payload.message.convo_mentions.reduce(
          (a, m) => (m.user_id === payload.user_id ? a + 1 : a),
          0
        );
        payload.message.convo_mentions.forEach((m) =>
          internal_convo.convo_mentions.push({ convo_message_id: payload.message.convo_message_id, user_id: m.user_id })
        );
      }
      if(payload.user_id != payload.message.user_id)
        internal_convo.unread = true;
    },
    // completes a mention for a message in a convo in the state
    // payload.convo_id specifies the convo
    // payload.convo_message_id specifies the message
    // payload.user_id specifies the user_id of the user that is completing the mention
    // payload.forUser should be true if the mention is for the user logged in
    completeInternalMention(state, payload) {
      let message_id = parseInt(payload.message_id)
      let internal_convo = state.internal_convos.find(c => c.convo_id === parseInt(payload.convo_id));
      if (!internal_convo) return;
      let startSize = internal_convo.convo_mentions.length;
      internal_convo.convo_mentions = internal_convo.convo_mentions.filter(
        (m) => m.message_id !== message_id || m.user_id !== payload.user_id
      );
      
      if (payload.forUser) internal_convo.userMentioned -= startSize - internal_convo.convo_mentions.length;
      if (internal_convo.convo_messages) {
        let message = internal_convo.convo_messages.find((m) => m.message_id === message_id);
        if (message) {
          message.convo_mentions = message.convo_mentions.map((m) => {
            if (m.user_id === payload.user_id) m.completed = true;
            return m;
          });
        }
      }
    },
    deleteInternalConvo(state, payload) {
      state.internal_convos = state.internal_convos.filter(c => c.convo_id != payload.internal_convo_id);
    }
  },
  actions: {
    // adds the provided list of convos to the state if they are not already present
    async addNewConvos({ commit, getters }, convos) {
      // add each new convo to the state
      convos.forEach((c) => {
        if (!getters.convo(c.convo_id)) {
          commit("addConvo", c);
        }
      });
    },
    // adds the provided list of convos to the state if they are not already present
    async addNewInternalConvos({ commit, getters }, convos) {
      // add each new convo to the state
      // convos.forEach((c) => {
      //   if (!getters.internal_convo(c.convo_id)) {
      //     commit("addInternalConvo", c);
      //   }
      // });
      getters("internal_convos",convos);
    },
    // retrieves convos based on the provided type and adds them to the state
    async retrieveConvos({ dispatch }, type) {
      try {
        let convos = await ConvoService.getConvos(type);
        convos = convos.data;
        if (!convos) throw new Error("convos with type: " + type + " not found");
        dispatch("addNewConvos", convos);
      } catch (err) {
        throw new Error(err);
      }
    },
    // retrieves convos based on the provided type and adds them to the state
    async retrieveInternalConvos({ dispatch },contact) {
      console.log(contact.rootState.contactState.contact_id)
      try {
        let convos = await ConvoService.getClientInternalConvos(contact.rootState.contactState.contact_id);
        convos = convos.data;
        if (!convos) throw new Error();
        dispatch("addNewInternalConvos", convos);
      } catch (err) {
        throw new Error(err);
      }
    },
    // retrieves a convo based on convo_id and adds it to the state
    async retrieveConvo({ commit }, convo_id) {
      try {
        let convo = await ConvoService.getConvo(convo_id);
        convo = convo.data;
        if (!convo) throw new Error("convo with convo_id: " + convo_id + " not found");
        commit("addConvo", { convo });
      } catch (err) {
        throw new Error(err);
      }
    },
    // acts as a async messages getter that fetches the messages if they are not in the state
    async getMessages({ dispatch, getters }, convo_id) {
      try {
        if (!convo_id) throw new Error("no convo_id given");
        let messages = getters.messages(convo_id);

        // if the messages are already in the state
        if (messages) {
          dispatch("markAsRead", convo_id);
          return messages;
        }

        // if the messages need to be fetched
        await dispatch("retrieveMessages", convo_id);
        messages = getters.messages(convo_id);

        if (messages) {
          dispatch("markAsRead", convo_id);
          return messages;
        }

        throw new Error("messages were not set properly in the vuex convos module");
      } catch (err) {
        throw new Error(err);
      }
    },
    // retrieves messages based on convo_id and adds them to the state
    async retrieveMessages({ commit }, convo_id) {
      try {
        let convo_messages = await ConvoService.getMessages(convo_id);
        convo_messages = convo_messages.data;
        convo_messages.forEach(message => {
          if (message.convo_attachments.length > 0) {
            message.convo_attachments.forEach(attachment => {
              attachment.convo_message_id = message.convo_message_id;
            });
          }
        });
        console.log("convo_messages:",convo_messages)
        if (!convo_messages) throw new Error("messages for convo_id: " + convo_id + " not found");
        commit("setMessages", { convo_id, convo_messages });
      } catch (err) {
        throw new Error(err);
      }
    },
    // marks the given convo_id as read in the store and lets the
    // server know that the convo provided has been read by the user
    async markAsRead({ commit }, convo_id) {
      try {
        commit("updateUnread", { convo_id, unread: false });
        await ConvoService.updateLastReadTime(convo_id);
      } catch (err) {
        throw new Error(err);
      }
    },
    // acts as a async messages getter that fetches the messages if they are not in the state
    async getInternalMessages({ dispatch, getters }, convo_id) {
      try {
        if (!convo_id) throw new Error("no convo_id given");
        let messages = getters.internal_messages(convo_id);

        // if the messages are already in the state
        if (messages) {
          //dispatch("markAsRead", convo_id);
          return messages;
        }

        // if the messages need to be fetched
        await dispatch("retrieveInternalMessages", convo_id);
        messages = getters.internal_messages(convo_id);
        if (messages) {
          dispatch("markInternalAsRead", convo_id);
          return messages;
        }

        throw new Error("messages were not set properly in the vuex convos module");
      } catch (err) {
        throw new Error(err);
      }
    },
    // marks the given internal_convo_id as read in the store and lets the
    // server know that the convo provided has been read by the user
    async markInternalAsRead({ commit }, convo_id) {
      try {
        commit("updateInternalUnread", { convo_id, unread: false });
        await ConvoService.updateInternalLastReadTime(convo_id);
      } catch (err) {
        throw new Error(err);
      }
    },
    // retrieves messages based on convo_id and adds them to the state
    async retrieveInternalMessages({ commit }, convo_id) {
      try {
        let convo_messages = await ConvoService.getInternalConvoMessages(convo_id);
        convo_messages = convo_messages.data;
        if (!convo_messages) throw new Error("messages for convo_id: " + convo_id + " not found");
        commit("setInternalMessages", { convo_id, convo_messages });
      } catch (err) {
        throw new Error(err);
      }
    },
  },
};

<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // stores the latest value of the xTicks computed properts so that we know when it has changed
      xTicksRef: [],
    };
  },
  computed: {
    xTicks: {
      get: function () {
        if (this._data._chart)
          return this._data._chart.scales["x-axis-0"]._ticks.map(function (e) {
            return e.value;
          });
        else return [];
      },
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    xTicks: function (newValue) {
      let different = false;
      if (newValue.length == this.xTicksRef.length) {
        for (let i = 0; i < newValue.length; i++) {
          if (newValue[i] != this.xTicksRef[i]) {
            different = true;
            break;
          }
        }
      } else {
        different = true;
      }
      if (different) {
        this.xTicksRef = newValue;
        this.$emit("xTicksChanged", {
          first: newValue[0],
          last: newValue[newValue.length - 1],
          length: newValue.length,
        });
      }
    },
  },
};
</script>
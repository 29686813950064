<template>
  <v-card
    flat
    tile
    style="
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <v-form v-if="emailSent == false" ref="entryForm" @submit.prevent="createUserClick()">
      <v-text-field
        style="width: 200px"
        label="First Name"
        v-model="firstName"
        :rules="firstNameRules"
        outlined
        dense
      ></v-text-field>
      <v-text-field
        style="width: 200px"
        label="Last Name (Optional)"
        v-model="lastName"
        outlined
        dense
      ></v-text-field>
      <v-select
        style="width: 200px"
        label="Role"
        :items="roles"
        v-model="role"
        :rules="roleRules"
        outlined
        dense
      >
      </v-select>
      <v-text-field
        label="Activation email"
        v-model="email"
        :rules="emailRules"
        outlined
        dense
      ></v-text-field>
      <v-btn style="width: 200px" color="primary" type="submit"
        >create user</v-btn
      >
    </v-form>
    <v-card flat style="display: flex; flex-direction: column;" v-else>
        <h4 class="text-center mb-4" style="font-size: 20px; width: 200px;">Activation email sent!</h4>
        <v-icon class="mb-3" color=success size=70>mdi-send-check</v-icon>
    </v-card>
  </v-card>
</template>

<script>
import MiscService from "@/services/miscService";

export default {
  name: "CreateUser",
  data() {
    return {
      firstName: null,
      lastName: null,
      role: null,
      email: null,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      firstNameRules: [],
      roleRules: [],
      emailRules: [],
      roles: ["Member", "Admin"],
      emailSent: false,
    };
  },
  watch: {
    firstName() {
      this.firstNameRules = [];
    },
    role() {
      this.roleRules = [];
    },
    email() {
      this.emailRules = [];
    },
  },
  methods: {
    createUserClick() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        !!this.firstName &&
        !!this.role &&
        !!this.email &&
        pattern.test(this.email) == true
      ) {
        // Here's our intial check to see if all the fields are valid
        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          role: this.role,
        };
        MiscService.sendAccountActivationEmail(this.email, data)
          .then((response) => {
            if (response.data == "User with email already exists.") {
              this.emailRules = ["User with email already exists."];
              let self = this;
              self.$refs.entryForm.validate();
            } else {
                this.emailSent = true;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.firstNameRules = [this.rules.required];
        this.roleRules = [this.rules.required];
        this.emailRules = [this.rules.required, this.rules.email];
        let self = this;
        self.$refs.entryForm.validate();
      }
    },
  },
};
</script>
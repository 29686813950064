<template>
  <v-card
    style="display: flex; flex-direction: column"
    height="400"
    width="600"
  >
    <v-card
      tile
      height="60"
      color="primary"
      style="display: flex; align-items: center"
    >
      <v-card-text class="ml-5 white--text" style="font-size: 21px">
        Admin
      </v-card-text>
    </v-card>
    <v-card tile height="340" style="display: flex">
      <v-card width="240">
        <v-list dense nav>
          <v-list-item
            class="pa-0"
            v-for="(item, index) in items"
            :key="item.title"
            @click="listItemSelected(index)"
          >
            <v-icon class="ml-3">{{ item.icon }}</v-icon>
            <v-list-item-title
              v-if="item.selected"
              class="ml-3 font-weight-black"
              >{{ item.title }}</v-list-item-title
            >
            <v-list-item-title v-else class="ml-3">{{
              item.title
            }}</v-list-item-title>
            <v-icon v-if="item.selected == true" color="black"
              >mdi-chevron-right</v-icon
            >
          </v-list-item>
        </v-list>
      </v-card>
      <v-card width="360">
          <component v-bind:is="component" />
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import CreateUser from "@/components/Admin/CreateUser";
import ManageAccounts from "@/components/Admin/ManageAccounts";

export default {
  name: "AdminDialog",
  components: {
      CreateUser,
      ManageAccounts,
  },
  watch: {},
  data() {
    return {
      items: [
        { name: "CreateUser", title: "Create User", icon: "mdi-account-plus", selected: true },
        { name: "ManageAccounts", title: "Manage Accounts", icon: "mdi-account-multiple-minus", selected: false },
      ],
      component: 'CreateUser',
    };
  },
  methods: {
    listItemSelected(itemIndex) {
        this.items.map(function (item) { // Setting 'selected' field of all items to false. 
          item.selected = false;
          return item;
        });
        this.items[itemIndex].selected = true; // Setting 'selected' field for item that was just clicked to true.
        this.component = this.items[itemIndex].name; 
      }
    },
};
</script>
<template>
  <div>
    <v-app-bar height=80 elevation="0" clipped-left fixed app style="border-bottom: 1px solid lightgray; background-color: #f5f5f5">
      <img class="ml-4 mr-1" :src="require('../../public/assets/logo.png')" height="35" />
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title style="margin-right: 0.5rem" v-if="validUser">{{ clientName }}</v-toolbar-title>

      <CallBar v-if="validUser" style="height: 65px" />

      <AudioSettings v-if="validUser" />

      <!-- right navbar stuff -->
      <div v-if="validUser">
        <v-app-bar-nav-icon>
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" icon>
                <v-avatar v-bind:color="hashedColor(username)" size="40">
                  <span class="text-white">{{ userInitials }}</span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <div class="justify-center mx-2 mt-2">
                <div class="mx-auto text-center py-2">
                  <v-avatar v-bind:color="hashedColor(username)" size="40">
                    <span class="text-white">{{ userInitials }}</span>
                  </v-avatar>
                  <br />
                  <br />
                  <h4>{{ username }}</h4>
                  <p class="caption mt-1">
                    {{ email }}
                  </p>
                  <v-divider class="my-3"></v-divider>
                  <v-btn variant="plain" @click="profileDialog = true" text>
                    Profile
                    <v-icon right> mdi-account </v-icon>
                  </v-btn>
                  <v-divider class="my-3"></v-divider>
                  <v-btn variant="plain" v-if="userRole.toLowerCase() == 'admin'" @click="adminDialog = true" text>
                    Admin
                    <v-icon right> mdi-account-supervisor </v-icon>
                  </v-btn>
                  <v-divider v-if="userRole.toLowerCase() == 'admin'" class="my-3"></v-divider>
                  <v-btn variant="plain" @click="settingsDialog = true" text>
                    Settings
                    <v-icon right> mdi-cog </v-icon>
                  </v-btn>
                  <v-divider class="my-3"></v-divider>
                  <v-btn variant="plain" @click="logOut" text>
                    Logout
                    <v-icon right> mdi-logout-variant </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-menu>
        </v-app-bar-nav-icon>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-if="validUser" permanent expand-on-hover rail app>
      <v-list dense>
        <v-list-item
          :class="{ highlighted: $route.path === item.route }"
          v-for="item in this.navbarItems"
          :key="item.title"
          :to="item.route"
          :title="item.title"  
        >
          <template v-slot:prepend>
            <v-icon :color="$route.path === item.route ? 'blue' : ''">{{ item.icon }}</v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-dialog :persistent="profileDialogPersist" v-model="profileDialog" max-width="500">
      <ProfileDialog v-if="profileDialog == true" @editStatus="profileDialogEvent" />
    </v-dialog>
    <v-dialog v-model="settingsDialog" max-width="500">
      <SettingsDialog v-if="settingsDialog == true" />
    </v-dialog>
    <v-dialog v-model="adminDialog" max-width="500">
      <AdminDialog v-if="adminDialog == true" />
    </v-dialog>
  </div>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import CallBar from "@/components/CallBar/CallBar";
import AudioSettings from "@/components/AudioSettings";
import AdminDialog from "@/components/AdminDialog";
import ProfileDialog from "@/components/ProfileDialog";
import SettingsDialog from "@/components/SettingsDialog";
import router from "@/router";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { mapState } from 'vuex'

export default {
  name: "Navbar",
  components: {
    CallBar,
    AdminDialog,
    ProfileDialog,
    SettingsDialog,
    AudioSettings,
  },
  mixins: [colorMixin],
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      callingBarInput: null,
      validNumber: false,
      adminDialog: false,
      profileDialog: false,
      profileDialogPersist: false, // if this is true, then you can't click out of the profile dialog
      settingsDialog: false,
      clientOnboardingDialog: false,
      routeName: "",
    };
  },
  computed: {
    ...mapGetters(["loggedIn", "username", "userInitials", "email", "clientName", "clientId", "userRole"]),
    ...mapState(["validUser"]),
    navbarItems() {
      return this.getItems();
    },
  },
  methods: {
    ...mapActions(["logOut"]),
    routeChanged(item) {
      if (this.$route.path !== item.route) this.$router.push({ path: item.route });
    },
    profileDialogEvent(editingOn) {
      this.profileDialogPersist = editingOn;
    },
    checkAdminClientName() {
      return !!this.clientId && this.clientId == 1;
    },
    checkAdminRole(){
      return this.userRole.toLowerCase() === 'admin';
    },
    getItems() {
      // adding icons to the left panel
      const items = [...this.items];
      items.push({ title: "Front Desk Workflow", route: "/frontdesk", icon: "mdi-monitor" });
      items.push({ title: "Outstanding Claims Workflow", route: "/osclaims", icon: "mdi-currency-usd" });
      items.push({ title: "General Conversation", route: "/conversation", icon: "mdi-chat" });
      
      if (this.checkAdminRole()) {
        if (this.checkAdminClientName()) {
          items.push({ title: "Client List", route: "/clientlist", icon: "mdi-account" });
        } else {
          items.push({ title: "User Phones", route: `/clientphones/${this.clientId}`, icon: "mdi-phone" });
          items.push({ title: "User Endpoints", route: `/clientendpoints/${this.clientId}`, icon: "mdi-account" });
        }
      }
      return items;
    },
  },
};
</script>

<template>
  <div>
    <h5 v-if="aggregateLevel == null" class="title">{{title}}</h5>
    <b-row v-else align-h="center">
      <b-form inline>
        <label class="title" for="inline-form-custom-select-pref">{{title}} by</label>
        <b-form-select
          class="left-space"
          v-model="selected"
          :options="options"
          size="sm"
          @input="$emit('aggregate-level-changed', selected)"
        ></b-form-select>
      </b-form>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ChartTitle",
  props: {
    title: { type: String },
    aggregateLevel: { type: String },
  },
  data() {
    return {
      selected: this.aggregateLevel,
      options: [
        { text: "Hour", value: "hour" },
        { text: "Day", value: "day" },
        { text: "Week", value: "week" },
        { text: "Month", value: "month" },
        { text: "Year", value: "year" },
      ],
    };
  },
  watch: {
    aggregateLevel: function(newValue) {
      this.selected = newValue;
    }
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  color: #666;
  font-size: 16px;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
}
.left-space {
  margin-left: 10px;
}
</style>
<template>
  <UserPopup :user_id="user_id" :noMenu="noMenu">
    <template v-slot="{ props }">
      <div v-bind="props" :style="`display: inline-block; color: ${color ? color : '#1d96f3'}; margin: -4px`">
        {{ text }}
      </div>
    </template>
  </UserPopup>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import UserPopup from "@/components/Avatar/UserPopup";

export default {
  name: "UserText",
  components: { UserPopup },
  mixins: [colorMixin],
  props: {
    // the text to display
    text: { type: String, default: "" },
    // the color of the text
    color: { type: String, default: "#1d96f3" },
    // the following props are passed directly to UserPopup (see UserPopup for more information)
    user_id: { type: Number, default: null },
    noMenu: { type: Boolean, default: false },
  },
};
</script>

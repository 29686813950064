<template>
  <div style="height: 100%; text-align: center;">
    <v-list-subheader v-if="!call.contact_id" class="justify-center" style="font-weight: bold;">
      Choose a call to get started
    </v-list-subheader>
    <FDMessenger :allCalls="getAllCalls" :contact="getHeaderDiv">
    </FDMessenger>
    <!-- <FDMessenger :allCalls="getAllCalls" :contact="getHeaderDiv">
    </FDMessenger> -->
  </div>
</template>

<script>
import FDMessenger from "@/components/FrontDesk/FDMessenger";
import { mapGetters } from "vuex";

export default {
  name: "CenterPanel",
  components: { FDMessenger },
  computed: {
    ...mapGetters("frontdesk", ["call", "contactByCall", "callsByContact", "getAllCalls"]),
    ...mapGetters("people", ["getHeaderInfo"]),
    calls: function() {
      return this.callsByContact(this.contactByCall(this.call));
    },
    getHeaderDiv: function() {
      return this.getHeaderInfo(this.call.contact_id)
    },
  },
};
</script>

<style scoped>
.chat-bubble-container {
  white-space: pre-wrap;
  display: flex;
}

.chat-bubble {
  text-align: left;
  font-size: 0.9rem;
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;
  width: auto;
  max-width: 80%;
}

.msg-center {
  background-color: #f5f5f5;
  align-items: center;
  align-content: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
</style>

<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col xs12 md6 class="widget">
          <FutureAppointments />
        </v-col>
        <v-col xs12 md6 class="widget">
          <AppointmentOutlookComparison />
        </v-col>
      </v-row>
      <v-row>
        <v-col xs12 md6 class="widget">
          <PastPayments />
          <!-- <TimeChartConstructor /> -->
        </v-col>
        <v-col xs12 md6 class="widget">
          <DailyNewAppointments />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FutureAppointments from "@/components/VPI/charts/FutureAppointments";
import PastPayments from "@/components/VPI/charts/PastPayments";
import AppointmentOutlookComparison from "@/components/VPI/charts/AppointmentOutlookComparison";
import DailyNewAppointments from "@/components/VPI/charts/DailyNewAppointments";
//import TimeChartConstructor from "@/components/VPI/TimeChartConstructor";

export default {
  name: "VPI",
  components: {
    FutureAppointments,
    AppointmentOutlookComparison,
    PastPayments,
    DailyNewAppointments,
    // TimeChartConstructor,
  },
};
</script>

<style lang="scss">
// import global styles
//@import "@/assets/vpi_style.scss";
</style>
<template>
  <div>
    <div v-show="numbersClicked !== ''" style="display: flex; justify-content: space-between; align-items: center">
      <h6 class="pa-2 pb-0" style="width: 150px; word-wrap: break-word; white-space: normal;">{{ numbersClicked }}</h6>
      <v-btn text color="primary" size="small" @click="numbersClicked = ''">
        Clear
      </v-btn>
    </div>
    <v-container class="grey lighten-5 mr-3 pa-0">
      <v-row v-for="x in 4" :key="x" no-gutters>
        <v-col
          class="d-flex"
          v-bind:class="{ 'justify-end': y == 1, 'justify-center': y == 2 }"
          v-for="y in 3"
          :key="y"
        >
          <v-btn class="mt-1 mb-1" fab size="large" color="grey lighten-5" @click="buttonClick(x, y)">
            <div>
              <span v-bind:class="{ 'mb-4': x + y == 2 }" style="display: block; font-size: 25px">
                {{ buttons[(x - 1) * 3 + (y - 1)].text }}
              </span>
              <small style="font-size: 8px">
                {{ buttons[(x - 1) * 3 + (y - 1)].subtext }}
              </small>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Keypad",
  data() {
    return {
      numbersClicked: "",
      buttons: [
        { text: "1", subtext: null },
        { text: "2", subtext: "A B C" },
        { text: "3", subtext: "D E F" },
        { text: "4", subtext: "G H I" },
        { text: "5", subtext: "J K L" },
        { text: "6", subtext: "M N O" },
        { text: "7", subtext: "P Q R S" },
        { text: "8", subtext: "T U V" },
        { text: "9", subtext: "W X Y Z" },
        { text: "*", subtext: null },
        { text: "0", subtext: null },
        { text: "#", subtext: null },
      ],
    };
  },
  methods: {
    buttonClick(x, y) {
      let text = this.buttons[(x - 1) * 3 + (y - 1)].text;
      this.numbersClicked += text;
      console.log("This button you just clicked is: " + text);
      this.plivoBrowserSdk.client.sendDtmf(text);
    },
  },
  mounted() {
    this.numbersClicked = "";
  },
};
</script>

<template>
  <div>
    <b-spinner v-if="chartDataObject == null"></b-spinner>
    <div v-else>
      <!-- settings -->
      <b-row class="align-right">
        <b-col cols="2">
        </b-col>
        <b-col cols="8" align-self="end">
          <ChartTitle
            v-bind:title="chartParams.title"
            v-bind:aggregateLevel="aggregateLevel"
            @aggregate-level-changed="aggregateLevelChanged($event)"
          />
        </b-col>
        <b-col cols="2">
          <Settings
            v-bind:sections="settings"
            @measureChanged="measureChanged($event)"
          />
        </b-col>
      </b-row>
      <LineChart
        class="move-upwards"
        v-bind:chart-data="chartDataObject"
        v-bind:options="chartOptionsObject"
      />
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/VPI/LineChart";
import Settings from "@/components/VPI/Settings";
import ChartTitle from "@/components/VPI/ChartTitle";
import chartInfoMixin from "@/mixins/chartInfoMixin";
import chartToolsMixin from "@/mixins/chartToolsMixin";
import VPIDataService from "@/services/VPIDataService";

export default {
  name: "FutureAppointments",
  components: {
    LineChart,
    Settings,
    ChartTitle,
  },
  mixins: [chartInfoMixin, chartToolsMixin],
  data() {
    return {
      // holds the data object for the chart
      chartDataObject: null,
      // holds the options object for the chart
      chartOptionsObject: null,
      // holds the raw data that was recieved from the last request to the server
      rawData: null,
      // the date range of the appointments in the chart
      dateRange: {
        startDate: this.moment().toISOString(),
        endDate: this.moment().add(1, "months").toISOString(),
      },
      // information used to construct the chart
      chartParams: {
        title: "All Future Appointments",
        yAxes: [], // axis data is added and removed on demand
      },
      // holds the chart's categories
      // categories are added and removed on demand
      categories: [],
      // holds the aggregate level for the appointment data
      aggregateLevel: "week",
      // The measures for displaying the data (ex: amount, total amount)
      measures: ["amount"],
      // the format for the chart settings
      settings: [
        {
          type: "check-boxes",
          title: "Measures",
          eventName: "measureChanged",
          options: [
            { text: "Amount", value: "amount" },
            { text: "Total Amount", value: "totalAmount" },
          ],
          defaultConfig: ["amount"],
        },
      ],
    };
  },
  computed: {
    // returns the request parameters needed for requesting the chart data from the server
    requestParams() {
      return {
        type: "bookings",
        // the 3 extra months are used for the moving average
        startDate: this.moment(this.dateRange.startDate)
          .subtract(3, "M")
          .toISOString(),
        endDate: this.dateRange.endDate,
      };
    },
  },
  created() {
    this.requestData();
  },
  methods: {
    requestData() {
      VPIDataService.getAppts(
        this.requestParams.startDate,
        this.requestParams.endDate
      )
        .then((res) => {
          // save the response data
          this.rawData = res.data;
          // now insert the data into the chart
          this.insertData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    insertData() {
      this.categories = ["All Appointments"];
      let apptTimes = [];

      // get the appointment times
      for (let i = 0; i < this.rawData.length; i++)
        apptTimes[i] = this.rawData[i].AptDateTime;

      // tempChartData contains the 3 extra months before the start date
      let tempChartData = this.aggregateData(
        apptTimes,
        this.aggregateLevel,
        {
          startDate: this.moment(this.dateRange.startDate)
            .subtract(3, "M")
            .toISOString(),
          endDate: this.dateRange.endDate,
        },
        true,
        null
      );

      let chartData = [];
      // add moving average category and data
      if (this.aggregateLevel != "year") {
        this.categories.push(
          "4 " + this.capitalize(this.aggregateLevel) + " Moving Average"
        );

        let data = this.getMovingAverage(
          tempChartData,
          this.aggregateLevel,
          this.dateRange.startDate
        );
        chartData[0] = data[1];
        chartData[1] = data[0];
      }

      // these will contain the additional categories/chartData (if any) that may result from
      // one or more measure being changed
      let additionalCategories = [];
      let additionalChartData = [];

      this.chartParams.yAxes = [];

      // change the yAxes data, categories, and chartData based on the selected measures
      if (this.measures.includes("amount")) {
        this.chartParams.yAxes.push({
          side: "left",
          label: "# of Appointments",
          type: "number",
        });
      }

      // add the running total categories and data
      if (this.measures.includes("totalAmount")) {
        this.chartParams.yAxes.push({
          side: "right",
          label: "# of Appointments",
          type: "number",
        });

        // create the additional categories but never for the moving average
        // for now, there should only ever be one running total category
        // (All Appointments Running Total)so we can just have one line
        additionalCategories[0] = this.categories[0] + " Running Total";

        // just one line is needed here too
        additionalChartData[0] = this.getRunningTotal(chartData[0]);
      }

      // remove the normal categories and data if needed.
      // this statement must come after this.categories and chartData
      // are done being used for the running totals calculation
      if (!this.measures.includes("amount")) {
        this.categories = [];
        chartData = [];
      }

      // add the additional categories and running total data to the chart
      this.categories = this.categories.concat(additionalCategories);
      chartData = chartData.concat(additionalChartData);

      // create the options object for the chart based on this.chartParams
      this.chartOptionsObject = this.constructOptionsTime(
        this.chartParams.yAxes,
        {
          min: new Date(
            this.moment(this.dateRange.startDate)
              .startOf(this.aggregateLevel)
              .toISOString()
          ),
          max: new Date(
            this.moment(this.dateRange.endDate)
              .startOf(this.aggregateLevel)
              .toISOString()
          ),
        },
        this.aggregateLevel
      );

      // create the data objects for the chart
      this.chartDataObject = this.constructData(
        this.categories,
        chartData,
        null,
        null
      );
    },
    async aggregateLevelChanged(newValue) {
      this.aggregateLevel = await newValue;
      this.insertData();
    },
    async measureChanged(newValue) {
      this.measures = await newValue;
      this.insertData();
    },
    async updateRange(newRange) {
      this.dateRange = await newRange;
      this.requestData();
    },
    // capitalizes a string
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

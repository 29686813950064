<template>
  <v-card class="container pa-0" elevation="5" height="500" width="700">
    <v-card class="text-center" height="500" width="450">
      <v-card-text>
        <h1 class="title text-primary mt-12">
          Sign in to DSS
        </h1>
        <v-form class="mt-2">
          <v-text-field prepend-icon="mdi-home" name="client" label="Client_Id" variant="underlined" v-model="client_id"></v-text-field>
          <v-text-field prepend-icon="mdi-account" name="login" label="Login" variant="underlined" v-model="username"></v-text-field>
          <v-text-field
            id="password"
            prepend-icon="mdi-key-variant"
            name="password"
            label="Password"
            type="password"
            variant="underlined"
            v-model="password"
            @keydown.enter="checkLogin"
          ></v-text-field>
        </v-form>
        <v-btn
          id="no-background-hover"
          class="mt-4 forgot-password-btn text-capitalize font-weight-medium"
          :ripple="false"
          variant="text"
          @click="redirectToPasswordResetPage"
          >Forgot your password?</v-btn
        >
      </v-card-text>
      <div class="text-center mt-3">
        <v-btn rounded class="bg-primary-darken-1" @click="checkLogin">SIGN IN</v-btn>
      </div>
    </v-card>
    <v-card height="500" width="250" class="bg-primary-darken-1">
      <v-card-text class="text-white mt-15">
        <h1 class="text-center">Hello there!</h1>
        <h5 class="text-center mt-2">Welcome to Integrated Communications</h5>
        <div class="container">
          <v-avatar color="white" size="35" class="mr-1"><v-icon size="20" color="black">mdi-phone</v-icon></v-avatar>
          <v-avatar color="white" size="35" class="mr-1"><v-icon size="20" color="black">mdi-message-text</v-icon></v-avatar>
          <v-avatar color="white" size="35" class="mr-1"><v-icon size="20" color="black">mdi-email</v-icon></v-avatar>
        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LogIn",
  data() {
    return {
      username: "",
      password: "",
      client_id:"",
      //rules: [],
    };
  },
  methods: {
    ...mapActions(["logIn"]),
    checkLogin() {
      this.logIn({ client_id:this.client_id,username: this.username, password: this.password }).catch((msg) => {
        alert(msg);
        //console.log(msg);
        //this.rules = ['Required.'];
      });
    },
    redirectToPasswordResetPage() {
      this.$router.push("/password/reset");
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

#no-background-hover::before {
  background-color: transparent !important;
}

.forgot-password-btn {
  color: #01579b;
}

.forgot-password-btn:hover {
  color: black;
}

.title {
  text-align: center;
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}
</style>

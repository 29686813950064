import eventBus from "@/eventBus";
import store from "@/store";

let options = {
  debug: "DEBUG",
  enableTracking: true,
  closeProtection: true,
  allowMultipleIncomingCalls: true,
  maxAverageBitrate: 48000,
};

// Plivo object initialization
var plivoBrowserSdk = new window.Plivo(options);

// Event registrations for plivo object
// --------------------------------------------------------------------------------
plivoBrowserSdk.client.on("onWebrtcNotSupported", () => {
  console.warn("no webRTC support");
  alert("Webrtc is not supported in this broswer, Please use latest version of chrome/firefox/opera/IE Edge");
});

plivoBrowserSdk.client.on("onLogin", () => {
  console.info("Plivo logged in");
  eventBus.$emit("onLogin");
});

plivoBrowserSdk.client.on("onLogout", () => {
  console.info("Plivo logged out");
  // try to log back in (will not work if user is logged out)
  store.dispatch("logInPlivoSDK");
});

plivoBrowserSdk.client.on("onLoginFailed", (cause) => {
  console.error("Plivo login failed", cause);
});

plivoBrowserSdk.client.on("onCallRemoteRinging", (callInfo) => {
  eventBus.$emit("onCallRemoteRinging", callInfo);
});

plivoBrowserSdk.client.on("onIncomingCallCanceled", (callInfo) => {
  eventBus.$emit("onIncomingCallCanceled", callInfo);
});

plivoBrowserSdk.client.on("onCallFailed", (cause, callInfo) => {
  console.error("onCallFailed", cause);
  if (callInfo) console.log(JSON.stringify(callInfo));
  eventBus.$emit("onCallFailed");
});

plivoBrowserSdk.client.on("onCallAnswered", (callInfo) => {
  eventBus.$emit("onCallAnswered", callInfo);
});

plivoBrowserSdk.client.on("onMediaConnected", (callInfo) => {
  console.info("onMediaConnected");
  if (callInfo) console.log(JSON.stringify(callInfo));
});

plivoBrowserSdk.client.on("onCallTerminated", (hangupInfo, callInfo) => {
  console.info(`onCallTerminated ${JSON.stringify(hangupInfo)}`);
  if (callInfo) console.log(JSON.stringify(callInfo));
  eventBus.$emit("onCallTerminated", callInfo);
});

plivoBrowserSdk.client.on("onCalling", () => {
  eventBus.$emit("onCalling");
});

plivoBrowserSdk.client.on("onIncomingCall", (callerID, extraHeaders, callInfo) => {
try{
  console.info("onIncomingCall : ", callerID, extraHeaders);
  if (callInfo) console.log(JSON.stringify(callInfo));
}catch(err){console.log(err);} 
 eventBus.$emit("onIncomingCall", callInfo, extraHeaders);
});

plivoBrowserSdk.client.on("onMediaPermission", (event) => {
  console.info("onMediaPermission", event);
});

plivoBrowserSdk.client.on("onConnectionChange", () => {
  console.info("onConnectionChange");
});

export default plivoBrowserSdk;

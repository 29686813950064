<template>
  <v-card>
  </v-card>
</template>

<script>
export default {
  name: "RightPanel",
};
</script>

import Vue from "vue";

// This is the Vuex module for Plivo.
export const modulePlivo = {
  namespaced: true,
  state: {
    // This object will hold the data for every ongoing conference (we will store every call as a conference,
    // where a standard call is simply a conference call with two people) that the browser currently has access to
    // (this includes the conference that the user is currently in as well as all of the conferences that
    // the user has on hold).
    conferences: {},
    // This object is an exact copy of the 'userDictionary' field in the server state. The 'userDictionary' will hold
    // field names for every user_id (belonging to the client) who is in a conference room (whether that is a standard
    // conference room with a patient, their personal temporary room, or a private room). The field value will be the
    // respective user's 'currConferenceName' (i.e. the room they are in) and their 'currCallUuid'
    // (i.e. the active call uuid the user is using to be present in the room).
    userDictionary: {},
    // Holds the name of the conference that the user is currently in.
    currConferenceName: null,
    // Holds the call UUID that the user in the browser is currently using to participate in a call
    // (or Plivo conference). This will be useful for the case where we want to transfer them from one conference to
    // another, in which case we will use Plivo's transfer API and use the call UUID specified here. In the case that
    // the user is not participating in any ongoing call, this field will be null.
    currCallUuid: null,
    // This string will indicate which display is mounted on the 'CallBar' component. The possible values are
    // 'default,' 'incomingCall', 'calling,' 'inCall,' and 'addToCall.'
    callBarDisplay: "default",
    // String indicating the display that was mounted on the 'CallBar' prior to the current display that is
    // mounted (as specified by 'callBarDisplay').
    prevCallBarDisplay: null,
  },
  getters: {
    getConferences(state) {
      return state.conferences;
    },
    getUserDictionary(state) {
      return state.userDictionary;
    },
    getCurrConferenceName(state) {
      return state.currConferenceName;
    },
    getCurrCallUuid(state) {
      return state.currCallUuid;
    },
    getCallBarDisplay(state) {
      return state.callBarDisplay;
    },
    getPrevCallBarDisplay(state) {
      return state.prevCallBarDisplay;
    },
  },
  mutations: {
    // Adds a new conference (i.e. a new field) to the 'conferences' object,
    // where the field name is the name of the conference and the field value
    // is the participants array for the conference. If there is already a field
    // for the conference, then that field is simply updated with the
    // updated participants array (which is in 'payload.conferenceParticipants').
    //
    // Payload Breakdown:
    // payload.conferenceName - String indicating conference name.
    // payload.conferenceParticipants - Array of participant objects (for the respective conference).
    addOrUpdateConference(state, payload) {
      state.conferences[payload.conferenceName] = payload.conferenceParticipants;
      console.log("conferences", state.conferences);
    },
    // Removes the conference specified by name (in the payload) from the 'conferences' object.
    //
    // Payload Breakdown:
    // payload.conferenceName - String indicating conference name.
    removeConference(state, payload) {
      delete state.conferences[payload.conferenceName];
    },
    setUserDictionary(state, newState) {
      state.userDictionary = newState;
    },
    setCurrConferenceName(state, newState) {
      state.currConferenceName = newState;
    },
    setCurrCallUuid(state, newState) {
      state.currCallUuid = newState;
    },
    setCallBarDisplay(state, newState) {
      state.callBarDisplay = newState || "default";
    },
    setPrevCallBarDisplay(state, newState) {
      state.prevCallBarDisplay = newState;
    },
  },
  actions: {
    // Updates the 'userDictionary' with the payload passed by the server, as well as updates the 'currConferenceName'
    // and 'currCallUuid' fields (and potentially the 'callBarDisplay' field).
    //
    // Payload Breakdown:
    // Entire payload is just the server's current and updated 'userDictionary' object (for the respective client).
    updateUserDictionary({ state, commit, rootGetters }, payload) {
      // Updating the 'userDictionary' in the Vuex store to match the 'userDictionary' in the server state.
      commit("setUserDictionary", payload);

      // Getting the value of the dictionary mapping where the key is the current browser user's user ID.
      const currUserInfo = payload[rootGetters.userId];

      // Checking if the mapping exists, if it does exist that means that the user is in a conference room currently.
      if (currUserInfo) {
        // If the 'currCallUuid' is null or if the current display is 'default', we must set the display to 'inCall'
        // since the user is now in a call (as a mapping exists for the user in the 'userDictionary').
        if (!state.currCallUuid || state.callBarDisplay === "default") commit("setCallBarDisplay", "inCall");
        // Updating the user's 'currCallUuid' and 'currConferenceName' with the new value from the updated
        // 'userDictionary.'
        commit("setCurrCallUuid", currUserInfo.callUuid);
        commit("setCurrConferenceName", currUserInfo.conferenceName);

        // Since the user is not in any conferences currently, we can reset 'currCallUuid' and 'currConferenceName'
        // to null.
      } else {
        commit("setCurrCallUuid", null);
        commit("setCurrConferenceName", null);
      }
    },
  },
};

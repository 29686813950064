<template>
  <div class="osclaims" style="height: 100%;">
    <vue-resizable class="left-panel" style="flex: 0.18 1 auto;" :min-width="100" :max-width="200">
      <ConversationLeftPanel />
    </vue-resizable>
    <div class="center-panel" style="flex: 1 1 auto;">
      <ConversationCentralPanel />
    </div>
  </div>
</template>

<script>
import VueResizable from 'vue-resizable';
import ConversationCentralPanel from "./CenterPanel.vue";
import ConversationLeftPanel from "./LeftPanel.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: 'Conversation',
  components: {
    VueResizable,
    ConversationCentralPanel,
    ConversationLeftPanel,
  },
  computed: {
    ...mapGetters("osclaims", ["loading"]),
  },
  methods: {
    ...mapMutations("osclaims", ["changeLoading"]),
    ...mapActions("osclaims", ["retrieveClaims"]),
    
  },
  created() {
    if (this.loading) {
      this.retrieveClaims()
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.changeLoading(false);
        });
    }
  },
};
</script>

<style scoped>
.osclaims {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.osclaims > * {
  margin: 0 1px;
}
</style>

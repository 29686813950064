<template>
  <div style="flex: 1;" class="mt-3">
    <div style="display: grid;" class="mx-2">
      <div v-if="convo && convo.owner" style="grid-area: 1/1/1/1; justify-self: start;">
        <UserPopup :user_id="convo.owner" />
        <div class="text-caption" style="color: #757575; display: flex; align-items:center; justify-content: center">
          <v-icon size="small">mdi-account</v-icon>
          Owner
        </div>
      </div>
      <div style="grid-area: 1/1/1/1; justify-self: center; align-self: center;" class="mx-2">
        Process: {{ this._process }}
        <div class="text-h6" style="display: flex; align-items:center;">
          <div style="flex 1 1 1">{{ header }}</div>
          <v-menu
            v-if="
              convo &&
                convo.convo_status &&
                (convo.owner === userId || convo.convo_participants.some((p) => p.user_id === userId))
            "
            offset-y
            max-width="300"
            offset-overflow
            style="flex 1 1 1"
          >
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" size="small">mdi-menu-down</v-icon>
            </template>
            <v-card>
              <div v-if="owner === userId" style="display: flex; flex-direction: column">
                <v-btn style="flex 1 1 1;" small text @click="$emit('updateConvoStatus', false)">
                  Close Conversation
                </v-btn>
                <v-btn style="flex 1 1 1;" small text>Transfer Conversation Ownership</v-btn>
              </div>
              <div v-else style="display: flex; flex-direction: column">
                <v-btn style="flex 1 1 1;" small text @click="$emit('leaveConversation')">
                  Leave Conversation
                </v-btn>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div
          v-if="convo && mentionedUsers.length"
          style="display: flex; flex-wrap: nowrap; justify-content: center; align-items: center"
        >
          <div style="color: #757575;" class="text-caption mr-1">
            Uncompleted Tasks:
          </div>
          <div v-for="user_id in mentionedUsers" :key="user_id" style="flex: 1 1 1; margin-right: -6px;">
            <UserPopup :user_id="user_id" dense />
          </div>
        </div>
        <div v-if="convo && !convo.convo_status" class="mt-1">
          <v-btn size="small" color="primary" @click="$emit('updateConvoStatus', true)">Reopen Conversation</v-btn>
        </div>
      </div>
      <div
        v-if="convo && convo.convo_participants && convo.convo_participants.length"
        style="grid-area: 1/1/1/1; justify-self: end;"
      >
        <div
          style="display: flex; flex-direction: row-reverse; flex-wrap: nowrap; justify-content: end; align-items: center;"
        >
          <div
            v-for="user in convo.convo_participants"
            :key="user.user_id"
            style="flex: 1 1 1; margin-left: -8px;"
          >
            <UserPopup :user_id="user.user_id" :badge="getReminder(user.user_id) ? true : false">
              <template v-slot:badge>
                <v-tooltip location="top" :text="moment(getReminder(user.user_id).reminder_date).format('h:mmA M/D/YYYY')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-alarm</v-icon>
                  </template>
                </v-tooltip>
              </template>
            </UserPopup>
          </div>
        </div>
        <div class="text-caption" style="color: #757575; display: flex; align-items:center; justify-content: center;">
          <v-icon size="small">mdi-account-multiple</v-icon>
          Participants
        </div>
      </div>
    </div>
    <v-divider class="mt-1"></v-divider>
  </div>
</template>

<script>
import UserPopup from "@/components/Avatar/UserPopup";
import { mapGetters } from "vuex";

export default {
  name: "MessengerHeader",
  components: {
    UserPopup,
  },
  props: {
    convo: { type: Object, default: null },
    header: { type: String, default: "" },
    owner: { type: Number, default: null},
  },
  computed: {
    ...mapGetters(["userId"]),
    ...mapGetters("osclaims",["_process"]),
    // removes the duplicated from the mentions for users that have been mentioned more than once
    mentionedUsers: function() {
      return [...new Set(this.convo.convo_mentions.map((m) => m.user_id))];
    },
  },
  methods: {
    // gets the reminder for the convo based on the participant provided
    getReminder(participant_id) {
      if (!this.convo || !this.convo.convo_reminders.length) return false;
      let r = this.convo.convo_reminders.find((r) => r.reminder_for_who === participant_id);
      return r ? r : false;
    },
  },
};
</script>

<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
    <!-- Carrier Search Box -->
    <v-text-field
      v-model="carrierSearchInput"
      variant="plain"
      flat
      hide-details
      clearable
      label="Search for a carrier"
      class="mt-1 ml-2"
    >
      <template v-if="filter" v-slot:prepend-inner>
        <v-badge :model-value="filterCount > 0" overlap :content="filterCount" bordered style="margin-right: .5rem; margin-top: 0rem;">
          <MiniSettings :sections="claimsSettings" @claimFilterChanged="changeFilter({ Type: $event })" />
        </v-badge>
      </template>
    </v-text-field>
    <v-divider />
    <v-progress-linear v-if="loading" indeterminate />
    <div v-if="!loading && !filteredCarriers.length" class="text-caption text-center" style="color: #757575">
      No Carriers Found
    </div>
    <div style="flex: 1 1 1; height: 100%; margin-top: 0.5rem;">
      <!-- SplitGrid direction="row" style="height: 100%;" :gutterSize="1" :rowMinSize="100">
        <SplitGridArea style="display: flex; flex-direction: column;">
          <RecycleScroller
            :items="filteredCarriers"
            :item-size="50"
            key-field="name"
            v-slot="{ item }"
            style="flex: 1 1 0; overflow-y: auto;"
          >
            <v-hover v-slot="{ hover }">
              <div
                @click="carrierSelected = item.name"
                :class="{
                  highlighted: carrierSelected === item.name,
                  hovered: hover && carrierSelected !== item.name,
                }"
                style="display: flex; justify-content: space-between; align-items: center; height: 50px; overflow-y: auto; overflow-x: hidden;"
                class="unselectable"
              >
                <div style="flex: 1 1 1;" class="ml-1">
                  <v-badge overlap icon="check" bordered :color="hashedColor(item.name)" :content="item.urgent || ''">
                    <v-avatar v-bind:color="hashedColor(item.name)" size="35">
                      <span class="white--text text-button">{{ item.freq }}</span>
                    </v-avatar>
                  </v-badge>
                </div>
                <div
                  style="flex: 1 1 1; display: flex; flex-direction: column; align-items: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  <div style="flex: 1 1 1;" class="text-body-1">{{ item.name }}</div>
                  <div style="flex: 1 1 1; color: #757575;" class="text-caption">{{ phoneFormat(item.num) }}</div>
                </div>
                <div style="flex: 1 1 1;" class="mr-1">
                  <v-hover v-slot="{ hover }">
                    <v-btn icon @click="phoneCall(item.name, item.num)" :color="hover ? 'primary' : ''">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </v-hover>
                </div>
              </div>
            </v-hover>
          </RecycleScroller>
        </SplitGridArea>
        <SplitGridGutter />
        <SplitGridArea v-if="carrierSelected" style="display: flex; flex-direction: column;">
          <v-data-table
            style="flex: 1 1 0; overflow-y: auto;"
            dense
            disable-pagination
            hide-default-footer
            show-expand
            single-expand
            :headers="headers"
            :items="carrierClaims"
            :item-class="rowStyle"
            :expanded="expanded"
            item-key="DSSClaimsID"
            @click:row="
              (item) => {
                changeClaim(item);
                expanded = [item];
              }
            "
          >
            <template v-slot:[`item.Status`]="{ item }">
              <v-tooltip location="left" :key="tableKey" :text="calcTooltip(item)">
                <template v-slot:activator="{ props }">
                  <template v-if="item.priority === 1">
                    <v-icon v-bind="props" color="red">mdi-exclamation-thick</v-icon>
                  </template>
                  <template v-else-if="item.priority === 2">
                    <v-icon v-bind="props" color="primary">mdi-alert-decagram</v-icon>
                  </template>
                  <template v-else-if="item.priority === 3 && item.convo.userReminder">
                    <div v-bind="props" style="color: grey;">
                      {{ fromNow(item.convo.userReminder.reminder_date) }}
                    </div>
                  </template>
                  <template v-else-if="item.priority === 3">
                    <v-icon v-bind="props">mdi-alarm-off</v-icon>
                  </template>
                  <template v-else-if="item.priority === 4">
                    <v-icon v-bind="props">mdi-check</v-icon>
                  </template>
                </template>
              </v-tooltip>
            </template>
            <template v-slot:[`item.DOS`]="{ item }">
              {{ moment(item.DOS).format("L") }}
            </template>
            <template v-slot:[`item.Amount`]="{ item }">
              {{ "$" + item.Amount }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="text-subtitle-2">
                <div>
                  <span style="font-weight: bold;">Date Sent: </span>
                  <div style="word-break: break-word;">
                    {{ moment(item.DateSent).format("L") }}
                  </div>
                </div>
                <div>
                  <span style="font-weight: bold;">Procedures: </span>
                  <div style="word-break: break-word;">
                    {{ item.Procedures }}
                  </div>
                </div>
              </td>
            </template>
          </v-data-table>
        </SplitGridArea>
      </SplitGrid -->
    </div>
  </div>
</template>

<script>
//import { SplitGrid, SplitGridArea, SplitGridGutter } from "vue-split-grid";
import EventBus from "@/eventBus";
import colorMixin from "@/mixins/colorMixin";
import momentMixin from "@/mixins/momentMixin";
import MiniSettings from "@/components/MiniSettings";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { phoneFormat } from "@/util/util";

export default {
  name: "RightPanel",
  mixins: [colorMixin, momentMixin],
  components: { MiniSettings },
  data() {
    return {
      // For the v-data-table of outstanding claims
      headers: [
        { sortable: false, value: "Status" },
        { text: "Type", value: "Type", filterable: true },
        { text: "Patient", value: "PatName" },
        { text: "DOS", value: "DOS" },
        { text: "Amount", value: "Amount" },
        { value: "data-table-expand" },
      ],
      expanded: [],
      // The carrier whose osclaims will be shown in the data table
      carrierSelected: null,
      carrierSearchInput: null,
      tableKey: 0,
    };
  },
  computed: {
    ...mapGetters("osclaims", [
      "claim",
      "filteredClaims",
      "convoID",
      "carriers",
      "lastSelectedCarrier",
      "claimsWithOpenConvos",
      "filter",
      "defaultFilter",
      "filterCount",
      "loading",
    ]),
    // Filtering carriers based on carrier search input
    filteredCarriers: function() {
      return !this.carrierSearchInput
        ? this.carriers
        : this.carriers.filter((carrier) =>
            carrier.name.toLowerCase().startsWith(this.carrierSearchInput.toLowerCase())
          );
    },
    // get all the osclaims of the carrier selected
    carrierClaims: function() {
      if (!this.carrierSelected) return [];
      // get the claims for the selected carrier
      let claims = this.filteredClaims.filter((c) => c.Carrier === this.carrierSelected);
      // sort the claims by priority
      return claims.sort((a, b) => {
        if (a.priority === b.priority && a.priority === 3) {
          if (!a.convo.userReminder) return -1;
          if (!b.convo.userReminder) return 1;
          return this.moment(a.convo.userReminder.reminder_date).isBefore(
            this.moment(b.convo.userReminder.reminder_date)
          )
            ? -1
            : 1;
        }

        return a.priority <= b.priority ? -1 : 1;
      });
    },
    claimsSettings: function() {
      return [
        {
          type: "check-boxes",
          title: "Filter Claims by Type",
          eventName: "claimFilterChanged",
          options: this.defaultFilter["Type"],
          defaultConfig: this.filter["Type"],
        },
      ];
    },
  },
  watch: {
    convoID() {
      // We will watch for a left panel convo click and scroll to that patient's specific carrier
      this.carrierSelected = this.claim.Carrier;
      var index = this.carriers.findIndex((carrier) => carrier.name === this.carrierSelected);
      if (document.getElementById(index)) document.getElementById(index).scrollIntoView();
      this.expanded = [this.claim];
    },
    // used for manually updating the icons in the datatable
    carrierClaims() {
      this.tableKey += 1;
    },
  },
  methods: {
    ...mapMutations("osclaims", ["changeClaim", "changeLastSelectedCarrier", "changeFilter"]),
    calcTooltip(item) {
      if(item.priority === 1) return "This claim is closed in this application but not in Open Dental";
      else if(item.priority === 2) return "unopened claim";
      else if(item.priority === 3 && item.convo.userReminder) return this.moment(item.convo.userReminder.reminder_date).format("h:mmA M/D/YYYY");
      else if(item.priority === 3) return "no reminder";
      else return "closed";
    },
    rowStyle(item) {
      var temp = "";
      if (item.priority === 2) temp += "font-weight-bold";
      if (item.DSSClaimsID == this.claim.DSSClaimsID) temp += " highlighted";
      return temp;
    },
    phoneCall(name, number) {
      this.carrierSelected = name;
      EventBus.$emit("call-event", { name, number });
    },
    phoneFormat,
  },
  mounted() {
    this.carrierSelected = this.lastSelectedCarrier;
    this.expanded = [this.claim];
  },
  beforeUnmount() {
    // before this component is destroyed, we must save the selected carrier
    // for the next time the user comes back
    this.changeLastSelectedCarrier(this.carrierSelected);
  },
};
</script>

<style scoped>
.v-data-table.v-data-table.v-data-table :deep(td) {
  font-size: 0.8rem;
  padding: 5px;
}
.v-data-table.v-data-table.v-data-table :deep(th) {
  font-size: 0.8rem;
  padding: 5px;
}
</style>

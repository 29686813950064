<template>
  <v-menu
    v-if="contact"
    close-delay="160"
    offset-y
    open-on-click
    :disabled="noMenu"
    :close-on-content-click="!showProfile"
  >
    <template v-slot:activator="{ props }">
      <div v-bind="props">
        <v-tooltip  :text="showProfile?contactData.first_name + ' ' + contactData.last_name:typeName" :disabled="!tooltip">
        <template v-slot:activator="{ props: tooltip_props }">
            <v-avatar
              v-bind="{...tooltip_props, ...props}"
              rounded="lg"
              :size="size"
              :color="getContactColor(contactData.contact_type)"
              style="margin-left: 4px;"
            >
            <span v-if="contactData.first_name==contactData.ph1">
            <v-btn v-bind="props" icon variant="text" size="x-small">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
            </span>
              <span style="color: white" :style="dense ? 'font-size: .7rem' : 'font-size: .9rem'" v-else>
                {{ contactData.first_name.substring(0, 2).toUpperCase() }}
              </span>
             
            </v-avatar>
          </template>
        </v-tooltip>
      </div>
    </template>
    <v-card v-if="showProfile">
      <div class="justify-center mx-2 py-2">
        <div class="mx-auto text-center">
          <v-avatar
            rounded="lg"
            size="2.2rem"
            v-bind:color="hashedColor(contactData.first_name)"
            style="margin-left: 4px;"
          >
            <span style="font-size: .9rem; color: white">
              {{ contactData.first_name.substring(0, 2).toUpperCase() }}
            </span>
          </v-avatar>
          <p class="caption mb-1">
            {{ contactData.first_name + " " + contactData.last_name + " - " + contactData.OD_PatNum }}
          </p>
          <v-divider class="my-1"></v-divider>
          <p v-if="contactData.ph1" class="caption mb-1">
            {{ phoneFormat(contactData.ph1) }}
          </p>
          <p v-if="contactData.ph2" class="caption mb-1">
            {{ phoneFormat(contactData.ph2) }}
          </p>
          <p v-if="contactData.ph3" class="caption mb-1">
            {{ phoneFormat(contactData.ph3) }}
          </p>
          <v-divider class="my-1"></v-divider>
          <p class="caption mb-1">
            {{ "View Profile" }}
          </p>
          <v-divider class="my-1 mb-1"></v-divider>
        </div>
      </div>
    </v-card>
    <v-card v-else-if="can_be_changed">
      <v-list>
        <div v-for="(contact_type, i) in types" :key="i">
        <v-list-item v-if="contact_type.type_id !== contactData.contact_type && contact_type.can_be_set==true">
          <v-list-item-title  @click="setContactType({contact_id:this.contact_id,type_id:contact_type.type_id})" >
            <v-tooltip  bottom > <template  v-slot:activator="{ props, on }">
                <v-avatar
                v-bind="props"
                        v-on="on"
                  rounded="lg"
                  :size="size"
                  :color="getContactColor(contact_type.type_id)"
                  style="margin-left: 4px;"
                >
                   <span v-if="contactData.first_name==contactData.ph1">
            <v-btn v-bind="props" icon variant="text" size="x-small">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
            </span>
              <span style="color: white" :style="dense ? 'font-size: .7rem' : 'font-size: .9rem'" v-else>
                {{ contactData.first_name.substring(0, 2).toUpperCase() }}
              </span>
                </v-avatar>
              </template><span>{{ contact_type.type_name }}</span>
              </v-tooltip>
             </v-list-item-title>
        </v-list-item>
      </div>
      </v-list>
      </v-card>
    
  </v-menu>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import { mapActions, mapGetters } from "vuex";
import { phoneFormat } from "@/util/util";


export default {
  name: "ContactPopup",
  mixins: [colorMixin],
  props: {
    // true if the avatar should have a visible badge. (The badge slot can be used to set the badge)
    badge: { type: Boolean, default: false },
    // true if the avatar should be small
    dense: { type: Boolean, default: false },
    // true if the avatar should have a tooltip
    tooltip: { type: Boolean, default: true },
    // the following props are passed directly to ContactPopup (see ContactPopup for more information)
    contact_id: { type: Number, default: null },
    noMenu: { type: Boolean, default: false },
    showProfile: {type: Boolean, default: true}
  },
  data() {
    return {
      contactData: null,
     
    };
  },
  created() {
   
  },
  watch: {
    contact_id: function() {
      this.getContactInfo();
    },
  },
  computed: {
    contact() {
    // Assuming getContact is a Vuex getter
    let data=this.$store.getters['people/contact'](this.contact_id);
    this.contactData=data;
    
    return data;
  },
  types() {
    // Fetching types array from Vuex state
    let data= this.$store.getters['people/contactTypes'];
    console.log("types set ",data);
    return data;
  },
  typeName() {
    // Calculating type_name based on contactData and types
    if (!this.contactData || !this.types) {
      return null; // or a default value if needed
    }
    const type = this.types.find(t => t.type_id === this.contactData.contact_type);
    console.log("typename to be returned is:",type);
    return type ? type.type_name : null; // Replace 'name' with the actual property in types
  },
  can_be_changed() {
    // Calculating type_name based on contactData and types
    if (!this.contactData || !this.types) {
      return null; // or a default value if needed
    }

    const type = this.types.find(t => t.type_id === this.contactData.contact_type);
    return type ? type.can_be_changed : null; // Replace 'name' with the actual property in types
  },
    size: function() {
      return this.dense ? "1.5rem" : "1.8rem";
    },

  },
  methods: {
    ...mapActions("people", ["getContact","getContactTypes","setContactType"]),
    ...mapGetters("people", ["contactTypes"]),
    getContactInfo() {
      
    },
    getContactColor(type_id) {
      console.log("Trying to get contact type for ID:", type_id);
      const defaultColor = "grey";
      try{
      const contact_types = this.types;
      // Find the contact type using Array.prototype.find
      const contactType = contact_types.find(ct => ct.type_id === type_id);
      console.log("The contactType is:",contactType);
      return contactType ? contactType.color : defaultColor;
      }catch(err){
        console.error(err.stack);
      }
      return defaultColor;
    },
    phoneFormat,
  },
};
</script>

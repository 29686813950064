import ConvoSerice from "@/services/convoService";
import moment from "moment";

// this is the vuex module for the Home Workflow
export const moduleHome = {
  namespaced: true,
  state: {
    // an array containing all the convos
    convos: [],
    // the current convo selected
    convo: {},
  },
  getters: {
    convos(state) {
      return state.convos;
    },
    convo(state) {
      return state.convo;
    },
    convoID(state, getters) {
      return getters.convo.convo_id;
    },
    sortedConvos(state, getters) {
      return getters.convos.sort((a, b) => {
        if (a.userMentioned === b.userMentioned) {
          if (!a.reminders[0]) return 1;
          if (!b.reminders[0]) return -1;
          return moment(a.reminders[0].reminder_date).isBefore(moment(b.reminders[0].reminder_date)) ? -1 : 1;
        }
        if (a.userMentioned === 0) return 1;
        if (b.userMentioned === 0) return -1;

        return a.userMentioned < b.userMentioned ? 1 : -1;
      });
    },
  },
  mutations: {
    convos(state, value) {
      state.convos = value;
    },
    changeConvo(state, value) {
      state.convo = value;
    },
  },
  actions: {
    retrieveConvos({ commit, getters, rootGetters }) {
      return new Promise((resolve, reject) => {
        ConvoSerice.getConvos()
          .then((res) => {
            let convos = res.data.map((c) => {
              // store the number of times the user is mentioned in each convo (only if there are no remindes set)
              if (c.convo_mentions.length && !c.convo_reminders.length)
                c["userMentioned"] = c.convo_mentions.reduce((a, m) => (m.user_id === rootGetters.userId ? a + 1 : a), 0);
              else c["userMentioned"] = 0;
              return c;
            });

            commit("convos", convos);
            // now we must update convo because the corresponding convo in convos may have changed
            if (getters.convo.convo_id) {
              commit("changeConvo", getters.convos.find((e) => e.convo_id == getters.convo.convo_id) || {});
            }
            return resolve();
          })
          .catch((e) => {
            return reject(e);
          });
      });
    },
  },
};

<template>
  <div style="display: flex">
    <v-btn
      size="small"
      fab
      color="grey lighten-3"
      style="margin-top: 12px; margin-left: 4px"
      @click="cancelAddToCall()"
    >
      <v-icon color="primary">mdi-arrow-left</v-icon>
    </v-btn>
    <div
      id="v-menuAnchor"
      class="pa-1 pr-3"
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
      "
    >
      <v-text-field
        @click="showDropdown = true"
        type="text"
        autocomplete="off"
        height="7"
        class="ml-0 pr-0 mt-1"
        background-color="grey lighten-3"
        style="flex: 1; height: 45px; overflow-y: none; margin-bottom: 4px"
        v-model="textfieldInput"
        flat
        solo
        label="Select a user to add"
      ></v-text-field>
      <v-icon class="mt-1" v-if="showDropdown" @click="showDropdown = !showDropdown">
        expand_less
      </v-icon>
      <v-icon class="mt-1" v-else @click="showDropdown = !showDropdown">
        expand_more
      </v-icon>
    </div>
    <v-menu
      attach="#v-menuAnchor"
      :content-class="filteredUsers.length == 0 ? 'elevation-0' : 'elevation-10'"
      nudge-bottom="65"
      nudge-right="30"
      offset-y
      rounded
      :close-on-content-click="false"
      :close-on-click="false"
      :value="showDropdown"
    >
      <v-card v-show="filteredUsers.length" color="grey lighten-5">
        <RecycleScroller
          :items="filteredUsers"
          :minItemSize="25"
          :item-size="60"
          key-field="user_id"
          v-slot="{ item }"
          style="
            width: 250px;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 300px;
          "
        >
          <v-hover v-slot="{ hover }">
            <div
              :class="{ hovered: hover }"
              style="display: grid; align-items: center"
              class="unselectable px-3"
              @click="addUserToCall(item)"
            >
              <div style="grid-area: 1/1/1/1; justify-self: start">
                <UserPopup :user_id="item.user_id" :tooltip="false" />
              </div>
              <div style="grid-area: 1/1/1/1; justify-self: center" class="text-center pa-2">
                <div>
                  {{ item.username }}
                </div>
                <div class="text-caption" style="color: #757575">user</div>
              </div>
            </div>
          </v-hover>
        </RecycleScroller>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import UserPopup from "@/components/Avatar/UserPopup";
import { mapGetters } from "vuex";

export default {
  name: "AddToCallDisplay",
  components: { UserPopup },
  mixins: [colorMixin],
  watch: {},
  data() {
    return {
      textfieldInput: null,
      showDropdown: true,
    };
  },
  computed: {
    ...mapGetters(["userId", "firstName", "lastName"]),
    ...mapGetters("plivo", ["getCurrConferenceName"]),
    ...mapGetters("people", ["users"]),

    filteredUsers: function() {
      return this.users.filter((user) => {
        // If the user is the current user in the browser, we will not
        // add them to the list of 'filteredUsers' because we don't want
        // the browser user to add themselves to the call (they are already in it
        // for crying out loud).
        if (user.user_id === this.userId) return false;
        if (!this.textfieldInput) return true;
        if (user.first_name && user.last_name) {
          const fullName = user.first_name + " " + user.last_name;
          return (
            fullName.toLowerCase().startsWith(this.textfieldInput.toLowerCase()) ||
            user.last_name.toLowerCase().startsWith(this.textfieldInput.toLowerCase()) ||
            user.username.toLowerCase().startsWith(this.textfieldInput.toLowerCase())
          );
        } else {
          return (
            user.first_name.toLowerCase().startsWith(this.textfieldInput.toLowerCase()) ||
            user.username.toLowerCase().startsWith(this.textfieldInput.toLowerCase())
          );
        }
      });
    },
  },
  methods: {
    addUserToCall(user) {
      // We will populate this variable with the Plivo SIP Headers we want to send, where the format is
      // a comma separated list of headers, where the string on the left of the equal sign is the header name
      // (which will always be prefixed with 'X-Ph-') and the string on the right of the equal sign is the header
      // value.
      //
      // See this link for more info on passing custom SIP headers:
      // https://www.plivo.com/docs/voice/use-cases/pass-custom-headers/node
      let plivoSipHeaders;

      if (user.last_name && this.lastName) {
        plivoSipHeaders = `Addtocall=${this.getCurrConferenceName},Calleruserid=${this.userId},Callerfirstname=${this.firstName},Callerlastname=${this.lastName},Calleeuserid=${user.user_id},Calleefirstname=${user.first_name},Calleelastname=${user.last_name}`;
      } else if (user.last_name) {
        plivoSipHeaders = `Addtocall=${this.getCurrConferenceName},Calleruserid=${this.userId},Callerfirstname=${this.firstName},Calleeuserid=${user.user_id},Calleefirstname=${user.first_name},Calleelastname=${user.last_name}`;
      } else if (this.lastName) {
        plivoSipHeaders = `Addtocall=${this.getCurrConferenceName},Calleruserid=${this.userId},Callerfirstname=${this.firstName},Callerlastname=${this.lastName},Calleeuserid=${user.user_id},Calleefirstname=${user.first_name}`;
      } else {
        plivoSipHeaders = `Addtocall=${this.getCurrConferenceName},Calleruserid=${this.userId},Callerfirstname=${this.firstName},Calleeuserid=${user.user_id},Calleefirstname=${user.first_name}`;
      }

      // In the case that the user clicked on another user to add to the call, we will send back as payload
      // to the 'CallBar' the body that the 'CallBar' will use when it makes the request to
      // to add the selected user to the conference call.
      let payload = {
        user_id: user.user_id,
        sipHeaders: plivoSipHeaders
      };

      this.$emit("dismount", payload);
    },

    cancelAddToCall() {
      // No need for payload, as 'CallBar' doesn't have to make a request to the backend to add a user to the call.
      this.$emit("dismount", null);
    },
  },
};
</script>

<template>
  <v-menu v-if="phoneNum" close-delay="160" offset-y open-on-click :disabled="noMenu" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <div v-bind="props">
        <v-tooltip :text="phoneFormat(phoneNum)" :disabled="!tooltip" location="top">
          <template v-slot:activator="{ props: tooltip_props }">
            <v-avatar
              v-bind="{...props, ...tooltip_props}"
              class="m-0"
              rounded="lg"
              :size="size"
              color="grey"
              style="margin-left: 4px;"
            >
              <span style="color: white" :style="dense ? 'font-size: .7rem' : 'font-size: .9rem'">
                ?
              </span>
            </v-avatar>
          </template>
        </v-tooltip>
      </div>
    </template>
    <v-card>
      <div class="justify-center mx-2 py-2">
        <div class="mx-auto text-center">
          <v-avatar rounded="lg" size="2.2rem" color="grey" style="margin-left: 4px;">
            <span class="white--text" style="font-size: .9rem">
              ?
            </span>
          </v-avatar>
          <p class="caption my-1">
            Unknown Phone number
          </p>
          <p class="caption mb-1">
            {{ phoneFormat(phoneNum) }}
          </p>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import { phoneFormat } from "@/util/util";

export default {
  name: "UnknownPopup",
  mixins: [colorMixin],
  props: {
    // true if the avatar should have a visible badge. (The badge slot can be used to set the badge)
    badge: { type: Boolean, default: false },
    // true if the avatar should be small
    dense: { type: Boolean, default: false },
    // true if the avatar should have a tooltip
    tooltip: { type: Boolean, default: true },
    // the following props are passed directly to UnknownPopup (see UnknownPopup for more information)
    phoneNum: { type: String, default: null },
    noMenu: { type: Boolean, default: false },
  },
  computed: {
    size: function() {
      return this.dense ? "1.5rem" : "1.8rem";
    },
  },
  methods: {
    phoneFormat,
  },
};
</script>

// This mixin helps with maintaining the state of the plivo vuex module and adds calls to the comms table
import EventBus from "@/eventBus";
//import PlivoService from "@/services/plivoService";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      inboundCallUuid: null, // This will store the call uuid of the most recent inbound call that was ringing.
      outboundCallUuid: null, // This will store the call uuid of the most recent outbound call that was remote ringing.
    };
  },
  methods: {
    ...mapMutations("plivo", ["addConference", "setCurrConferenceName", "setCurrCallUuid"]),
  },
  created() {
    EventBus.$on("on-incoming-call-event", (callInfo) => {
      this.inboundCallUuid = callInfo.callUUID;
    }),
      EventBus.$on("on-call-remote-ringing-event", (callInfo) => {
        this.outboundCallUuid = callInfo.callUUID;
      }),
      EventBus.$on("on-call-answered-event", (callInfo) => {

        console.log(callInfo);

        /*// If the call that was answered was an inbound call, we will follow the call flow logic for inbound calls,
        // which involves immediately transferring the call to a conference call.
        if (this.inboundCallUuid === callInfo.callUUID) {
          PlivoService.transferToConference({ uuid: callInfo.callUUID, roomName: "Room " + callInfo.callUUID })
            .then((response) => {
              console.log("Call has been transferred to a conference room called " + response.data.roomName);
              // Creating conference object where the name of the conference is the call_uuid that generated the
              // conference (i.e. the call_uuid that was transferred to a new conference room).
              let conferenceObject = { name: response.data.roomName };
              // Adding conference to vuex store.
              this.addConference(conferenceObject);
              this.setCurrConferenceName(response.data.roomName);
              this.setCurrCallUuid(callInfo.callUUID);
            })
            .catch((e) => {
              console.log(e);
            });
        }

        // Request to start recording call
        PlivoService.startCallRecording({ call_uuid: callInfo.callUUID })
          .then((response) => { 
            // Storing the recording id that we got back from the response.
            let recordingId = response.data;
            EventBus.$on("on-call-terminated-event", () => {
              PlivoService.addCall({ call_uuid: callInfo.callUUID, recording_id: recordingId })
                .then((response) => {
                  console.log(response);
                })
                .catch((e) => {
                  console.log(e);
                });
            });
          })
          .catch((e) => {
            console.log(e);
          });*/

      });
  },
};

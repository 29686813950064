import http from "../http-common";
import axios from "axios";

class plivoService {
  rejoinConference = (data) => http.post("/plivo/rejoinConference", data);

  leaveConference = (data) => http.delete("/plivo/leaveConference", data);

  addNewCall = (data) => http.post("/plivo/addNewCall", data);

  transferUser = () => http.post("/plivo/transferUser");
  
  sendMessage = (data) => axios.post(process.env.VUE_APP_API_URL + "/api/plivo/outbound-text", data);

  // retrieveMessageMedia = (message_id, media_id) => http.get(`/plivo/retrieveMessageMedia/${message_id}/${media_id}`);

  mergeCall = (data) => http.post("/plivo/mergeCall", data);
  resendMessage = (textMsgId)  => http.get(`/plivo/messages/text_message/${textMsgId}/resend`);
}

export default new plivoService();

<template>
    <v-card flat tile style="height: 100%; display: flex; justify-content: center; align-items: center">
        <p class="mb-15"> Currently Unavailable </p>
    </v-card>
</template>

<script>
export default {
    name: "Notifications",
}
</script>
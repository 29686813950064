import http from "../http-common";

class AuthService {
  baseUrl = process.env.VUE_APP_API_URL;

  login = (data) => http.post(this.baseUrl + "/login", data);

  check = () => http.get(this.baseUrl + "/check");

  logout = () => http.delete(this.baseUrl + "/logout");

  refreshToken = () => http.post(this.baseUrl + "/token");

  resetPasswordEmail = (email) => http.post(this.baseUrl + `/reset_password/user/${email}`);

  updatePassword = (token, data) => http.post(this.baseUrl + `/reset_password/receive_new_password/${token}`, data);

  addNewUserAccount = (token, data) => http.post(this.baseUrl + `/activate-account/create/${token}`, data);
}

export default new AuthService();

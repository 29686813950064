<template>
  <div class="osclaims" style="height: 100%;">
    <div class="left-panel" style="flex: 0.18 1 auto"><LeftPanel /></div>
    <div class="center-panel" style="flex: 0.5 1 auto"><CenterPanel /></div>
    <div class="right-panel" style="flex: 0.32 1 auto"><RightPanel /></div>
  </div>
</template>

<script>
import LeftPanel from "@/components/osClaims/LeftPanel";
import CenterPanel from "@/components/osClaims/CenterPanel";
import RightPanel from "@/components/osClaims/RightPanel";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "OSClaims",
  components: {
    LeftPanel,
    CenterPanel,
    RightPanel,
  },
  computed: {
    ...mapGetters("osclaims", ["loading"]),
  },
  methods: {
    ...mapMutations("osclaims", ["changeLoading"]),
    ...mapActions("osclaims", ["retrieveClaims"]),
  },
  created() {
    // retrieve all outstanding claims and the convos associated with them
    if (this.loading) {
      this.retrieveClaims()
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.changeLoading(false);
        });
    }
  },
};
</script>

<style scoped>
.osclaims {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.osclaims > * {
  margin: 0 1px;
}
</style>
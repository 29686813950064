<template>
  <v-app>
    <Navbar v-bind:items="navBarItems" />
    <v-main>
      <v-snackbar v-model="alertToggle" :timeout="readingTime(alertMessage)" rounded>
        {{ alertMessage }}
      </v-snackbar>
      <div style="display: flex; flex-direction: column; height: 100%;">
        <div style="flex: 1 1 1;">
          <PatientProfile v-if="false" />
        </div>
        <div style="flex: 1 1 1; height: 100%">
          <router-view />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import EventBus from "@/eventBus";
import momentMixin from "@/mixins/momentMixin";
import plivoMixin from "@/mixins/plivoMixin";
import Navbar from "@/components/Navbar";
import PatientProfile from "@/components/PatientProfile";

export default {
  name: "App",
  components: { Navbar, PatientProfile },
  mixins: [momentMixin, plivoMixin],
  data() {
    return {
      navBarItems: [
        //{title: "Client Onboarding", route: "/clientonboarding", icon: "mdi-account"},
        // {
        //   title: "Home",
        //   route: "/home",
        //   icon: "mdi-home",
        // },
        // {
        //   title: "Front Desk Workflow",
        //   route: "/frontdesk",
        //   icon: "mdi-monitor",
        // },
        // {
        //   title: "Outstanding Claims Workflow",
        //   route: "/osclaims",
        //   icon: "mdi-currency-usd",
        // },
        // {
        //   title: "General Conversation",
        //   route: "/conversation",
        //   icon: "mdi-chat",
        // },
        // {
        //   title: "Recall Workflow",
        //   route: "/recall",
        //   icon: "mdi-phone-forward",
        // },
        // {
        //   title: "VPI",
        //   route: "/vpi",
        //   icon: "mdi-view-dashboard",
        // },
      ],
      alertToggle: false,
      alertMessage: "",
    };
  },
  created() {
    EventBus.$on("alert", (message) => {
      this.alertMessage = message;
      this.alertToggle = true;
    });
    EventBus.$on("toggleDarkMode", (dark_mode) => {
      this.$vuetify.theme.dark = dark_mode;
    });
  }
};
</script>

<style>
/* global styles */
.v-application {
    color: #202020 !important;
}
.list {
  background: #ffffff;
}
.highlighted {
  background: #e4edf8;
}
.hovered {
  background: #f5f5f5;
}
.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.caption {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.displayCenter {
  display: flex;
  justify-content: center;
}

.alignCenter {
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.m10 {
  margin: 10px;
}

/* removes scroll-bar on side of application when it is not needed */
html {
  overflow-y: auto;
}
</style>

<template>
  <div class="alignCenter">
    <v-text-field
      :label="required ? 'Required' : ''"
      :ref="ref_name"
      :rules="rules"
      :hide-details="true"
      variant="underlined"
      density="compact"
      single-line
      :model-value="value"
      @update:modelValue="(val) => $emit('updated', val)"
      :style="'width: ' + width + 'px'"
      :autofocus="true"
    />
  </div>
</template>

<script>

export default {
  name: "TableEditField",
  props: {
    rules: { type: Array, default: null },
    ref_name: { type: String, default: null },
    value: { type: String, default: null },
    width: { type: String, default: '200' },
    required: { type: Boolean, default: true }
  },
};
</script>

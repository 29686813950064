<template>
  <div style="height: 100%; text-align: center;">
    <v-list-subheader v-if="!convo.convo_id" class="justify-center">Choose a conversation to get started</v-list-subheader>
    <Messenger v-else :convo="convo" :system_object="null" @update="retrieveConvos" />
  </div>
</template>

<script>
import Messenger from "@/components/Messenger/Messenger.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "CenterPanel",
  components: {
    Messenger,
  },
  computed: {
    ...mapGetters("home", ["convo"]),
  },
  methods: {
    ...mapActions("home", ["retrieveConvos"]),
  },
};
</script>

<template>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <v-switch
      style="flex: 1 1 1;"
      v-model="darkModeSwitch"
      label="Dark Mode"
      hide-details
      :disabled="disableDarkModeSwitch"
      @change="updateDarkMode"
    ></v-switch>
  </div>
</template>

<script>
import MiscService from "@/services/miscService";
import eventBus from "@/eventBus";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default {
  name: "Appearance",
  data() {
    return {
      darkModeSwitch: false,
      disableDarkModeSwitch: true,
    };
  },
  computed: {
    ...mapGetters(["darkMode"]),
  },
  created() {
    this.darkModeSwitch = this.darkMode;
    this.disableDarkModeSwitch = false;
  },
  methods: {
    ...mapMutations(["changeDarkMode"]),
    updateDarkMode() {
      // temporarily disable the switch while changing the dark mode preference
      this.disableDarkModeSwitch = true;
      MiscService.updateDarkMode(this.darkModeSwitch)
        .then(() => {
          this.changeDarkMode(this.darkModeSwitch);
          this.$vuetify.theme.dark = this.darkModeSwitch;
          if (this.darkMode) eventBus.$emit("alert", "Dark Mode Activated");
          else eventBus.$emit("alert", "Light Mode Activated");
        })
        .catch((e) => {
          console.error(e);
          eventBus.$emit("alert", "Error updating dark mode preference");
        })
        .finally(() => {
          this.disableDarkModeSwitch = false;
        });
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-card
    flat
    tile
    style="
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    "
  >
    <v-card
      flat
      tile
      style="
        height: 40px;
        width: 285px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e0e0e0;
      "
    >
      <h6 v-if="filteredUsers.length == 1" class="ml-2 mt-3" style="width: 130px">
        {{ filteredUsers.length }} account
      </h6>
      <h6 v-else class="ml-2 mt-3" style="width: 130px">{{ filteredUsers.length }} accounts</h6>
      <v-checkbox
        class="my-checkbox"
        style="width: 120px; margin-top: 20px"
        v-model="hideInactiveAccounts"
        label="Hide inactive"
        color="primary"
      ></v-checkbox>
    </v-card>

    <!-- Accounts Search Box -->
    <v-text-field
      v-model="userSearchInput"
      flat
      solo
      label="Search by name or email"
      clearable
      height="50"
      hide-details
      style="flex: none; width: 100%"
    ></v-text-field>

    <!-- Accounts List -->
    <v-card tile style="flex: auto; width: 100%">
      <v-virtual-scroll
        :items="filteredUsers"
        :item-height="55"
        :bench="20"
        style="
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          overflow-y: auto;
        "
      >
        <template v-slot="{ index, item }">
          <v-list-item
            v-bind:style="[index == 0 ? { 'border-top': '1px solid #E0E0E0' } : { border: 'none' }]"
            :key="index"
            style="height: 100%; outline: 1px solid #e0e0e0"
            class="pt-0 pb-0 pl-2 pr-2"
          >
            <div style="height: 54px; width: 450px; overflow: hidden" class="align-self-start pl-0 pr-0 ml-0 mr-0">
              <v-list-item-title class="mt-2">{{ item.first_name + " " + item.last_name }}</v-list-item-title>
              <v-list-item-subtitle style="color: gray; font-size: small">{{ item.email }}</v-list-item-subtitle>
            </div>
            <v-list-item-action
              style="
                width: 100px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
              "
            >
              <v-switch
                :input-value="item.isActive"
                :disabled="disableActivationSwitch"
                inset
                @change="updateAccountStatus(item.email)"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-card>
  </v-card>
</template>

<script>
import MiscService from "@/services/miscService";

export default {
  name: "ManageAccounts",
  data() {
    return {
      hideInactiveAccounts: false,
      disableActivationSwitch: false,
      users: [],
      userSearchInput: null,
    };
  },
  methods: {
    getUsers() {
      MiscService.getUsers()
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateAccountStatus(email) {
      this.disableActivationSwitch = true;
      const data = { email: email };
      MiscService.updateActiveStatus(data)
        .then((response) => {
          console.log(response.data);
          this.getUsers();
          this.disableActivationSwitch = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {
    // Filtering carriers based on carrier search input
    filteredUsers: function() {
      let filtered = [];
      if (this.hideInactiveAccounts) {
        filtered = this.activeUsers;
      } else {
        filtered = this.users;
      }
      return !this.userSearchInput
        ? filtered
        : filtered.filter((user) => {
            const fullName = user.first_name + " " + user.last_name;
            const email = user.email ? user.email : "";
            const matchesSearch =
              email.toLowerCase().startsWith(this.userSearchInput.toLowerCase()) ||
              fullName.toLowerCase().startsWith(this.userSearchInput.toLowerCase());
            return matchesSearch;
          });
    },
    activeUsers: function() {
      return this.users.filter((user) => {
        return user.isActive;
      });
    },
  },
  beforeMount() {
    this.getUsers();
  },
};
</script>

<style scoped>
.my-checkbox :deep(.v-label) {
  font-size: 13px;
  color: black;
  margin-top: 7px;
}
</style>

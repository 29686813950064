import http from "../http-common";

class VPIDataService {
    getAppts(startDate, endDate) {
        return http.get(`/appts?startdate=${startDate}&enddate=${endDate}`);
    }

    getBookingsAsOfDate(startDate, endDate, asOfDate) {
        return http.get(`/bookingsasof?startdate=${startDate}&enddate=${endDate}&asofdate=${asOfDate}`);
    }

    getDailyPayments(startDate, endDate) {
        return http.get(`/dailypayments?startdate=${startDate}&enddate=${endDate}`);
    }

    getDailyNewAppts(startDate, endDate) {
        return http.get(`/dailynewappts?startdate=${startDate}&enddate=${endDate}`);
    }

    getEmployeeOTP(startDate, endDate) {
        return http.get(`/employeeotp?startdate=${startDate}&enddate=${endDate}`);
    }
}

export default new VPIDataService();
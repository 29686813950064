<template>
  <div class="recall" style="height: 100%;">
    <div class="left-panel" style="flex: 0.18 1 auto"><LeftPanel /></div>
    <div class="center-panel" style="flex: 0.5 1 auto"><CenterPanel /></div>
    <div class="right-panel" style="flex: 0.32 1 auto"><RightPanel /></div>
  </div>
</template>

<script>
import LeftPanel from "@/components/Recall/LeftPanel";
import CenterPanel from "@/components/Recall/CenterPanel";
import RightPanel from "@/components/Recall/RightPanel";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "Recall",
  components: {
    LeftPanel,
    CenterPanel,
    RightPanel,
  },
  computed: {
    ...mapGetters("recall", ["loading"]),
  },
  methods: {
    ...mapMutations("recall", ["changeLoading"]),
    ...mapActions("recall", ["retrieveRecalls"]),
  },
  created() {
    // retrieve all outstanding claims and the convos associated with them
    if (this.loading) {
      this.retrieveRecalls()
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.changeLoading(false);
        });
    }
  },
};
</script>

<style scoped>
.recall {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.recall > * {
  margin: 0 1px;
}
</style>